import React, { useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Input, SelectInput } from "@wavesenterprise/uikit";
import { withApi } from "../../../../core/api/withApi";
import { Api } from "../../../../core/api";
import { ICompany } from "../../../../core/api/interfaces";
import styles from './SelectCompany.module.scss'

interface SelectUserCompanyProps extends WithTranslation {
  api: Api;
  existedCompanyIds?: string[];
  login: string;
  onCancel?: Function;
  onAppendToCompany: (company: ICompany) => void
}

function SelectUserCompany (props: SelectUserCompanyProps) {
  const { t, api, existedCompanyIds = [], login, onAppendToCompany } = props

  const [isSelectOpened, setSelectOpened] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState('')

  useEffect(() => {
    const loadCompaniesList = async () => {
      try {
        setIsLoading(true)
        const { companies: companiesList } = await api.getCompanies({ limit: 50, offset: 0 })
        const filteredList = companiesList.filter((company: ICompany) => !existedCompanyIds.includes(company.id))
        setCompanies(filteredList)
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }
    loadCompaniesList()
  }, [isSelectOpened])

  const onSaveClicked = async () => {
    if (selectedCompanyId) {
      try {
        await api.addCompanyUser(selectedCompanyId, { login })
        const targetCompany = companies.find(company => company.id === selectedCompanyId)
        if (targetCompany) {
          onAppendToCompany(targetCompany)
        }
        setSelectOpened(false)
      } catch (e) {
        console.log('Cannot add user to company:', e.message)
      }
    } else {
      setSelectOpened(false)
    }
  }

  const onSelectItemId = (id: string) => {
    setSelectedCompanyId(id)
  }

  let openButtonProps = {
    type: 'regular',
    icon: 'plus',
    onClick: () => setSelectOpened(true)
  }

  if (existedCompanyIds.length === 0) {
    openButtonProps = {
      ...openButtonProps,
      type: 'primary',
      icon: ''
    }
  }

  const linkedToAllCompanies = !isLoading && companies.length === 0 && existedCompanyIds.length > 0

  if (isLoading) {
    return <div className={styles.SelectCompanyContainer}>
      <Input disabled isLoading={true} />
    </div>
  }

  if (linkedToAllCompanies) {
    return <div>
      {t('users.linked_to_all_companies')}
    </div>
  }

  if (companies.length === 0) {
    const text = existedCompanyIds.length > 0 ? 'Linked to all' : t('users.no_companies_found')
    return <div className={styles.SelectCompanyContainer}>
      {text}
    </div>
  }

  return <div>
      {!isSelectOpened &&
        <Button {...openButtonProps} >{t('users.attach_to_company')}</Button>
      }
      {isSelectOpened &&
        <div className={styles.SelectCompanyContainer}>
            <div className={styles.ButtonsContainer}>
              <Button
                icon={'close'}
                className={styles.ButtonClose}
                onClick={() => setSelectOpened(false)}>
                {t('buttons.cancel')}
              </Button>
              <Button icon={'done'} onClick={onSaveClicked}>{t('buttons.save')}</Button>
            </div>
            <div className={styles.SuggestWrapper}>
                <SelectInput
                  isLoading={isLoading}
                  label={t('users.company')}
                  placeholder={t('users.select_company_placeholder')}
                  items={companies.map(({ id, shortName }) => {
                    return {
                      id,
                      value: shortName
                    }
                  })}
                  // selectedItemId={}
                  onChangeItemId={onSelectItemId}
                />
            </div>
        </div>
      }
  </div>
}

export default withApi(withTranslation()(SelectUserCompany))
