import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ContextMenuButtons, DataList, Icon, ListCol } from "@wavesenterprise/uikit";
import fileDownload from "js-file-download";
import { LicenseActivationStatus } from "../../../../constants";
import col from "../../../../../common/col";
import moment from "moment";
import i18next from "i18next";
import { IModalsStore, ModalNameChoices } from "../../../../stores/ModalStore";
import { withApi } from "../../../../../core/api/withApi";
import { inject, observer } from "mobx-react";
import { IStore } from "../../../../../../app/stores";
import styles from "./Licenses.module.scss";
import { IAuthStore } from "../../../../../core/authorization/stores/AuthStore";
import { IApi, ICompany, ICompanyUser, ILicense, LicenseStatus, UserRole } from "../../../../../core/api/interfaces";
import { getDatesDiff } from "../../../../../common/dateUtils";

enum ContextMenuActions {
  download = "download",
  activate = "activate",
  cancel = "cancel"
}

interface ILicenseListProps extends WithTranslation {
  items: any;
  isLoading: boolean;
  companyId: string;
  company?: ICompany;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  isFiltersApplied?: boolean;
}

interface IContextMenuProps {
  api?: IApi;
  id?: string;
  t: i18next.TFunction;
  status: LicenseActivationStatus;
  companyId: string;
  showModal: any;
  userRoles: UserRole[];
  actions: ContextMenuActions[];
}

const formatExpirationTime = (license: ILicense) => {
  const t = i18next.getFixedT(i18next.language, 'common');
  const { validTo, status } = license;
  let value = t("company_card.licenses.unlimited");
  let caption = t("expiration_date");

  if (validTo) {
    const diff = getDatesDiff(validTo);
    const dateString = moment(validTo).format(t('date_format'));
    if (status !== LicenseStatus.expired && diff < 30) {
      value = t("company_card.licenses.expire_days_left", { count: diff });
      caption = t("company_card.licenses.expire_date", { dateString });
    } else {
      value = t(`company_card.licenses.valid_until`, { dateString });
    }
  }

  return {
    value,
    caption
  }
};

const LicenseContextMenu = withApi(function (props: IContextMenuProps) {
    const {t, showModal, api, id: licenseId, companyId, actions = []} = props;
    const contextMenuOptions = [];
    if (actions.includes(ContextMenuActions.activate)) {
      contextMenuOptions.push({
        text: t("company_card.licenses.activate"),
        value: ContextMenuActions.activate
      });
    }
    if (actions.includes(ContextMenuActions.download)) {
      contextMenuOptions.push({
        text: t("company_card.licenses.download"),
        value: ContextMenuActions.download
      });
    }
    if (actions.includes(ContextMenuActions.cancel)) {
      contextMenuOptions.push({
        text: t("company_card.licenses.cancel"),
        value: ContextMenuActions.cancel
      });
    }
    const contextMenuProps = {
      data: contextMenuOptions,
      placement: "bottomLeft",
      onChange: async (action: ContextMenuActions) => {
        if (action === ContextMenuActions.activate) {
          showModal(ModalNameChoices.licenseActivate, {
            id: companyId,
            licenseActivateId: licenseId
          });
        } else if (action === ContextMenuActions.cancel) {
          showModal(ModalNameChoices.licenseCancel, { id: companyId, licenseId });
        } else if (action === ContextMenuActions.download) {
          if (licenseId === undefined) {
            return;
          }
          const file = await api!.downloadLicense({
            licenseId,
            companyId
          });
          fileDownload(JSON.stringify(file), 'node.license');
        }
      }
    };

    return (
      <ContextMenuButtons {...contextMenuProps}>
        <ListCol {...col(1)}>
          <div>
            <Icon type="details" />
          </div>
        </ListCol>
      </ContextMenuButtons>
    );
  }
);

const getLicenseActions = (isCompanyMember: boolean, userRoles: UserRole[], status: LicenseActivationStatus) => {
  const isAdmin = userRoles.includes(UserRole.licenseAdmin);
  const actions = [];
  if (status === LicenseActivationStatus.available) {
    if (isAdmin) {
      actions.push(ContextMenuActions.cancel)
    }
    if (isCompanyMember) {
      actions.push(ContextMenuActions.activate)
    }
  } else if (status === LicenseActivationStatus.activated) {
    if (isCompanyMember) {
      actions.push(ContextMenuActions.download)
    }
  }
  return actions
};

@withApi
@inject(({ modalsStore, authStore }: IStore, props: ILicenseListProps) => ({ modalsStore, authStore, ...props }))
@observer
class _LicensesList extends Component<ILicenseListProps> {
  getDataConfig = () => {
    const { t, companyId, company, modalsStore, authStore } = this.props;
    const userRoles = authStore!.getUserRoles();
    const isCompanyMember = !!company?.users.find((user: ICompanyUser) => user.login.toLowerCase() === authStore!.getUserName());
    const contextMenuProps = {
      t,
      companyId,
      company,
      userRoles,
      showModal: modalsStore!.showModal,
      isCompanyMember
    };
    const commonPart = {
      icon: {
        id: "",
        color: 'red'
      },
      columns: [{
          key: "1",
          dimensions: 6
        }, {
          key: "2",
          dimensions: 2
        }, {
          key: "3",
          dimensions: 2
        }]
    };
    const availableActions = getLicenseActions(isCompanyMember, userRoles, LicenseActivationStatus.available);
    const activatedActions = getLicenseActions(isCompanyMember, userRoles, LicenseActivationStatus.activated);
    return {
      [LicenseActivationStatus.available]: {
        ...commonPart,
        button: availableActions.length > 0 ? (
          <LicenseContextMenu
            {...contextMenuProps}
            status={LicenseActivationStatus.available}
            actions={availableActions}
          />
        ) : null
      },
      [LicenseActivationStatus.activated]: {
        ...commonPart,
        button: activatedActions.length > 0 ? (
          <LicenseContextMenu
            {...contextMenuProps}
            status={LicenseActivationStatus.activated}
            actions={activatedActions}
          />
        ) : null
      },
      [LicenseActivationStatus.expired]: {
        ...commonPart
      },
    };
  };

  render() {
    const { t, items, companyId, isFiltersApplied } = this.props;
    const data = items.map((license: any) => {
      const { id, type, status, tags } = license;
      const {
        value: expirationValue, caption: expirationCaption
      } = formatExpirationTime(license);
      return {
        key: id,
        type: status,
        icon: {
          id: status === LicenseStatus.activated ? 'power-enabled-32' : 'power-32',
          color: status === LicenseStatus.activated ? 'inherit' : '#8C8D8D8D'
        },
        columns: [
          {
            value: tags.length > 0 ? tags.join(', ') : t(`license`),
            caption: t(`license_types.${type}`)
          },
          {
            value: t(`license_status.${status}`),
            caption: t(`status`)
          },
          {
            value: expirationValue,
            caption: expirationCaption
          }
        ]
      };
    });
    const onItemClick = (licenseId: string) =>
      this.props.modalsStore!.showModal(ModalNameChoices.licenseDetails, {
        id: companyId,
        licenseId
      });
    const dataConfig = this.getDataConfig();
    const noItemsMessage = isFiltersApplied
      ? t('company_card.licenses.licenses_not_found')
      : t('company_card.licenses.no_licenses')
    return (
      <div className={styles.List}>
        {items.length === 0
          ? <span className={styles.Bold}>{noItemsMessage}</span>
          : (
          <DataList
            data={data}
            dataConfig={dataConfig}
            onItemClick={onItemClick}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(_LicensesList) as any;
