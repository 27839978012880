import React, { useEffect, useState } from "react";
import styles from './CountdownCircle.module.scss'

interface ICountdownProps {
  duration: number;
}

function CountdownCircle (props: ICountdownProps) {
  const { duration } = props;
  const [secondsLeft, setSecondsLeft] = useState(duration);

  useEffect(() => {
    if (secondsLeft > 0) {
      setTimeout(() => {
        const nextValue = secondsLeft > 1
          ? secondsLeft - 1
          : 0;
        setSecondsLeft(nextValue);
      }, 1000);
    }

    return () => {
      // console.log('unmount')
    }
  });

  if (!secondsLeft) {
    return null;
  }

  return <div className={styles.CountdownCircle}>
    <div className={styles.number}>{secondsLeft}</div>
    <svg className={styles.svg}>
      <circle r="18" cx="20" cy="20" style={{animationDuration: `${duration}s`}} />
    </svg>
  </div>
}

export default CountdownCircle