import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRoute } from 'react-router5';
import { Router, State } from 'router5';
import { IStore } from '../../app/stores';
import styles from './Modal.module.scss';
import { RouteNameChoices, RouteNames } from '../core/routing';
import { IModalsStore, ModalNameChoices } from './stores/ModalStore';
import { getModalNameByRoute } from './modals.utils';
import { CompanyDetails } from './list/companyDetails/CompanyDetails';
import { LicenseIssue } from './list/licenseIssue/LicenseIssue';
import { LicenseDetails } from './list/licenseDetails/LicenseDetails';
import { LicenseActivate } from './list/licenseActivate/LicenseActivate';
import { LicenseCancel } from './list/licenseCancel/LicenseCancel';
import { UserDetails } from './list/users/userDetails/UserDetails';
import { CompanyForm } from './list/CompanyForm/CompanyForm';
import { CreateNewUser } from "./list/users/createNewUser/CreateNewUser";

interface IProps {
  modalsStore?: IModalsStore;
  route: State;
  router: Router;
}

export interface IModalProps {
  visible: boolean;

  onCancel (): void;
}

@inject(({ modalsStore }: IStore) => ({ modalsStore }))
@observer
class _ModalContainer extends Component<IProps> {
  componentDidMount () {
    const { modalsStore, route: { name, params } } = this.props;
    let modalName = getModalNameByRoute(name);
    if (modalName) {
      modalsStore!.showModal(modalName, params, false);
    }
  }

  componentWillUnmount() {
    this.props.modalsStore!.dropState()
  }

  render () {
    const { modalsStore, router } = this.props;
    const { params } = router.getState();
    const currentModal = modalsStore!.getCurrentModal();

    const { routeParams: savedRouteParams } = modalsStore!.getSavedRoute();

    let modal = null;
    const visible = currentModal !== null;
    switch (currentModal) {
      case ModalNameChoices.createCompany:
        modal = (
          <CompanyForm
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames.companies)}
          />
        );
        break;
      case ModalNameChoices.updateCompany:
        modal = (
          <CompanyForm
            id={this.props.route.params.id}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames[RouteNameChoices.companyInfo], { id: params.id })}
          />
        );
        break;
      case ModalNameChoices.companyDetails:
        modal = (
          <CompanyDetails
            router={router}
            id={this.props.route.params.id}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames.companies)}
          />
        );
        break;
      case ModalNameChoices.licenseIssue:
        modal = (
          <LicenseIssue
            router={router}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames[RouteNameChoices.companyDetails], { id: params.id, ...savedRouteParams })}
          />
        );
        break;
      case ModalNameChoices.licenseDetails:
        modal = (
          <LicenseDetails
            router={router}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames[RouteNameChoices.companyDetails], { id: params.id, ...savedRouteParams  })}
          />
        );
        break;
      case ModalNameChoices.licenseActivate:
        modal = (
          <LicenseActivate
            router={router}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames[RouteNameChoices.companyDetails], { id: params.id, ...savedRouteParams  })}
          />
        );
        break;
      case ModalNameChoices.licenseCancel:
        modal = (
          <LicenseCancel
            router={router}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames[RouteNameChoices.companyDetails], { id: params.id, ...savedRouteParams  })}
          />
        );
        break;
      case ModalNameChoices.userDetails:
        const { userId } = this.props.route.params;
        modal = (
          <UserDetails
            id={userId}
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames.users)}
          />
        );
        break;
      case ModalNameChoices.createNewUser:
        modal = (
          <CreateNewUser
            visible={visible}
            onCancel={modalsStore!.onCancel(RouteNames.users)}
          />
        );
        break;

    }

    return <div className={styles.modalContent}>{modal}</div>;
  }
}

export const ModalContainer = withRoute(({ route, router }) => <_ModalContainer route={route} router={router}/>);
