import { ILicense } from "../../../core/api/interfaces";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SidePanelDetailsList } from '@wavesenterprise/uikit';
import InfoMessage from "../../../common/info-message";
import React from "react";
import styles from './LicenseCancel.module.scss'
import { inject } from "mobx-react";
import { IAuthStore } from "../../../core/authorization/stores/AuthStore";
import moment from "moment";

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  license: ILicense;
  company: any;
  onCloseClicked: () => void
}


function _ResultStep (props: IProps) {
  const {t, onCloseClicked, authStore} = props;
  const items = [{
    key: t('license_cancel.cancel_date'),
    value: moment().format(t('date_format'))
  }, {
    key: t('license_cancel.user'),
    value: authStore!.getUserName()
  }];
  return <div>
    <div className={styles.Buttons}>
      <Button type={'primary'} onClick={onCloseClicked}>{t('buttons.close')}</Button>
    </div>
    <div className={styles.InfoMessage}>
      <InfoMessage iconType={'done'} text={t('license_cancel.cancel_success_message')} />
    </div>
    <div className={styles.CompanyInfo}>
      <SidePanelDetailsList items={items} />
    </div>
  </div>
}

export const ResultStep = inject('authStore')(withTranslation()(_ResultStep));
