import React from 'react';
import styles from './ContentWrapper.module.scss';

interface IProps {
  children: React.ReactChild | React.ReactChildren;
}

export const ContentWrapper = (props: IProps) => {
  return <div className={styles.ContentWrapper}>{props.children}</div>;
};
