import { DataList, SpinContainer } from '@wavesenterprise/uikit';
import { inject, observer } from 'mobx-react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withRoute } from 'react-router5';
import { State } from 'router5';
import { IStore } from '../../app/stores';
import { IUsersStore } from './stores/UsersStore';
import styles from './UsersList.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IModalsStore, ModalNameChoices } from '../modals/stores/ModalStore';
import moment from "moment";

const dataConfig = {
  users: {
    columns: [
      {
        key: '1',
        dimensions: 4,
      },
      {
        key: '2',
        dimensions: 5,
      },
      {
        key: '3',
        dimensions: 3,
      }
    ],
  },
};

interface IProps extends WithTranslation {
  usersStore?: IUsersStore;
  modalsStore?: IModalsStore;
  route: State;
}

const _UsersList = inject(({ usersStore, modalsStore }: IStore) => ({
  usersStore,
  modalsStore,
}))(
  observer((props: IProps) => {
    const { t, modalsStore } = props;
    const usersStore = props.usersStore!;
    const emptyCaption = '–';
    const companiesData = usersStore.getUsers().map(user => {
      const { id, login, firstName, lastName, createdAt, companies = [] } = user;
      const userNameCaption = (firstName || lastName) ? [firstName, lastName].join(' ') : emptyCaption
      const companiesCaption = companies.length > 0
        ? companies.map(company => company.shortName).join(', ')
        : emptyCaption
      return {
        key: id,
        type: 'users',
        columns: [
          {
            value: login,
            caption: userNameCaption,
          },
          {
            value: companiesCaption,
            caption: t('users.companies'),
          },
          {
            value: moment(createdAt).format(t('DD.MM.YYYY')),
            caption: t('users.created_at'),
          }
        ],
      };
    });

    const onItemClick = (userId: string) => {
      if (modalsStore) {
        modalsStore.showModal(ModalNameChoices.userDetails, { userId });
      }
    };
    return (
      <div className={styles.UsersList}>
        <InfiniteScroll
          pageStart={0}
          useWindow={true}
          hasMore={usersStore.hasMoreToLoad()}
          loadMore={() => {
            if (!usersStore.isLoading()) {
              usersStore.loadMoreUsers();
            }
          }}
          initialLoad={false}
          loader={<div key={'stub'}/>}
        >
          <div>
            <DataList
              className={styles.DataList}
              data={companiesData}
              dataConfig={dataConfig}
              onItemClick={onItemClick}
            />
            {usersStore.isLoading() && (
              <SpinContainer key="spinner" size="large"/>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }),
);

const TranslatedCompaniesList = withTranslation()(_UsersList);
export const UsersList = withRoute(({ route }) => (
  <TranslatedCompaniesList route={route}/>
));
