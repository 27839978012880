import { init } from "@wavesenterprise/api-token-refresher/axios";
import axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Router } from "router5";
import {
  AUTH_API_VERSION_PREFIX,
  AUTH_BASE_URL,
  LICENSE_API_VERSION_PREFIX,
  LICENSE_BASE_URL
} from "./constants";
import {
  IActivateUserParams,
  IApi,
  ICancelLicenseParams,
  IChangePasswordParams,
  ICompanyUser,
  ICreateAccountParams,
  ICreateCompany,
  IDownloadLicenseParams,
  IGetCompaniesParams,
  ILicense,
  IGetConfigResponse,
  IGetProductsResponse,
  ILicenseIssueParams,
  ILicenseTypeResponse,
  ILoginParams,
  IRestorePasswordParams,
  IUpdateCompany,
  IActivateLicenseParams,
  IActivateLicenseResponse,
  IUpdateCompanyUser,
  IGetCompanyLicensesParams,
  IGetAuthUsersParams,
  IResetPasswordParams,
  IGetTagsParams,
  IGetSignerInfoResponse,
  IUpdateLicenseParams,
  IGetLicenseUsersParams, ICompany, IAddCompanyUser
} from './interfaces';

interface ICancelTokenEntry {
  url: string;
  cancelToken: CancelTokenSource;
}

export class Api implements IApi {
  constructor (data: { router: Router }) {
    const { router } = data;
    this._router = router;
  }

  private _cancelTokens: ICancelTokenEntry[] = [];

  private _addCancelToken (url: string, cancelToken: CancelTokenSource) {
    this._cancelTokens.push({
      url,
      cancelToken
    });
  }

  private _getCancelToken (url: string) {
    return this._cancelTokens.find(item => item.url === url);
  }

  private _deleteCancelToken (url: string) {
    this._cancelTokens = this._cancelTokens.filter(item => item.url !== url);
  }

  private _authClient!: AxiosInstance;
  private _licenseClient!: AxiosInstance;

  private _unauthorizedClient: AxiosInstance = axios.create({
    baseURL: AUTH_BASE_URL + AUTH_API_VERSION_PREFIX
  });

  private _router!: Router;

  public createAccount = async (params: ICreateAccountParams) => {
    const payload = {
      ...params,
      source: "license"
    };
    try {
      const { data } = await this._unauthorizedClient.post(`/user`, payload);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public activateUser = async (params: IActivateUserParams) => {
    const { code } = params;
    try {
      const { data } = await this._unauthorizedClient.get(
        `/user/confirm/${code}`
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public restorePassword = async (params: IRestorePasswordParams) => {
    const { email } = params;
    try {
      const { data } = await this._unauthorizedClient.post(
        `/user/password/restore`,
        {
          email,
          source: 'license'
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public resetPassword = async (params: IResetPasswordParams) => {
    try {
      const { data } = await this._unauthorizedClient.post(
        `/user/password/reset`,
        params
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public changePassword = async (params: IChangePasswordParams) => {
    try {
      const { data } = await this._authClient.post(
        `/user/password/change`,
        params,
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public login = async (params: ILoginParams, onTokenExpired: () => void) => {
    const { login, password } = params;
    try {
      const { data: authorization } = await this._unauthorizedClient.post(
        "/auth/login",
        {
          username: login,
          password
        }
      );

      const refreshCallback = async (refreshToken: string, clear: Function) => {
        try {
          const { data } = await this._unauthorizedClient.post(
            "/auth/refresh",
            {
              token: refreshToken
            }
          );
          return data;
        } catch (e) {
          console.log("Refresh token error:", e);
          onTokenExpired();
          clear();
        }
      };

      const { axios: authClient, clear: clearAuthClient } = init(
        {
          authorization,
          refreshCallback: (token: string) => refreshCallback(token, clearAuthClient)
        },
        {
          baseURL: AUTH_BASE_URL + AUTH_API_VERSION_PREFIX
        }
      );

      this._authClient = authClient;

      const { axios: licenseClient, clear: clearLicenseClient } = init(
        {
          authorization,
          refreshCallback: (token: string) => refreshCallback(token, clearLicenseClient)
        },
        {
          baseURL: LICENSE_BASE_URL + LICENSE_API_VERSION_PREFIX
        }
      );

      this._licenseClient = licenseClient;

      return authorization;
    } catch (e) {
      throw e;
    }
  };

  public getCompanies = async (params: IGetCompaniesParams) => {
    try {
      const { data } = await this._licenseClient.get("/companies/", { params });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getCompanyUsers = async (companyId: string) => {
    try {
      const { data } = await this._licenseClient.get(`/companies/${companyId}/users`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getCompanyById = async (id: string): Promise<any> => {
    try {
      const { data } = await this._licenseClient.get(`/companies/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getTags = async (params?: IGetTagsParams): Promise<string[]> => {
    try {
      const { data } = await this._licenseClient.get(`/tags`, { params });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public setLicenseTags = async (licenseId: string, tags: string[]): Promise<ILicense> => {
    try {
      const { data } = await this._licenseClient.post(`/tags`, {
        licenseId,
        tags
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getCompanyLicenses = async (companyId: string, params?: IGetCompanyLicensesParams): Promise<any> => {
    try {
      const { data } = await this._licenseClient.get(
        `/companies/${companyId}/licenses`, { params },
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getLicenseById = async (companyId: string, licenseId: string): Promise<ILicense> => {
    try {
      const { data } = await this._licenseClient.get(`/companies/${companyId}/license/${licenseId}`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public createCompany = async (company: ICreateCompany) => {
    try {
      const { data } = await this._licenseClient.post(`/companies`, company);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public updateCompany = async (company: IUpdateCompany) => {
    try {
      const { data } = await this._licenseClient.post(`/companies/${company.id}`, company);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public addCompanyUser = async (companyId: string, user: IAddCompanyUser) => {
    try {
      const { data } = await this._licenseClient.post(`/companies/${companyId}/addUser`, user);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public removeCompanyUser = async (companyId: string, login: string) => {
    try {
      const { data } = await this._licenseClient.post(`/companies/${companyId}/removeUser`, {
        login
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getUsers = async (params: IGetLicenseUsersParams) => {
    try {
      const { data } = await this._licenseClient.get(`/users`, { params });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getUserById = async (userId: string): Promise<ICompanyUser> => {
    try {
      const { data } = await this._licenseClient.get(`/users/byId/${userId}`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getUserCompanies = async (userId: string): Promise<ICompany[]> => {
    try {
      const { data } = await this._licenseClient.get(`/users/byId/${userId}/companies`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getUserByEmail = async (email: string): Promise<any> => {
    try {
      const { data } = await this._licenseClient.get(`/users/byEmail/${email}`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public updateCompanyUser = async (userId: string, params: IUpdateCompanyUser) => {
    try {
      const { data } = await this._licenseClient.post(`/users/${userId}`, params);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public licenseIssue = async (
    companyId: number,
    params: ILicenseIssueParams
  ): Promise<void> => {
    const { data } = await this._licenseClient.post(
      `/companies/${companyId}/license`,
      params
    );
    return data;
  };

  public getLicenseTypes = async (): Promise<ILicenseTypeResponse> => {
    const { data } = await this._licenseClient.get("/directory/licenseTypes");
    return data;
  };

  public getProducts = async (): Promise<IGetProductsResponse> => {
    const { data } = await this._licenseClient.get("/directory/products");
    return data;
  };

  public getConfig = async (): Promise<IGetConfigResponse> => {
    const { data } = await axios.get(LICENSE_BASE_URL + '/config/');
    return data;
  };

  public getSignerInfo = async (): Promise<IGetSignerInfoResponse> => {
    const { data } = await axios.get(LICENSE_BASE_URL + '/signerInfo/');
    return data;
  };

  public downloadLicense = async (params: IDownloadLicenseParams) => {
    try {
      const { companyId, licenseId } = params;
      const { data } = await this._licenseClient.get(`/companies/${companyId}/license/${licenseId}/download`);
      return data;
    } catch (e) {
      throw e;
    }
  };

  public cancelLicense = async (params: ICancelLicenseParams) => {
    try {
      const { companyId, licenseId } = params;
      const { data } = await this._licenseClient.post(`/companies/${companyId}/license/cancel`, {
        id: licenseId,
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public activateLicense = async (companyId: string, params: IActivateLicenseParams): Promise<IActivateLicenseResponse> => {
    const { data } = await this._licenseClient.post(`/companies/${companyId}/license/activate`, params);
    return data;
  };

  // available only for license-admin role
  public getAuthUsers = async (params: IGetAuthUsersParams) => {
    try {
      const { data } = await this._authClient.get(`/admin/users`, { params });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public updateLicense = async (companyId: string, licenseId: string, params: IUpdateLicenseParams): Promise<ILicense> => {
    const { data } = await this._licenseClient.post(`/companies/${companyId}/license/${licenseId}/update`, params);
    return data;
  };
}
