import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Button, ContextMenuRadio, Icon, Input } from '@wavesenterprise/uikit';
import {
  AllLicenseActivationStatuses,
  AllLicensePeriod,
  AllLicenseTypes,
  FilterType,
  LicenseActivationStatus,
  LicensePeriod, LicenseTagsAll,
  LicenseType,
} from '../../../../constants';
import { withApi } from '../../../../../core/api/withApi';
import { IStore } from '../../../../../../app/stores';
import { IModalsStore, ModalNameChoices } from '../../../../stores/ModalStore';
import styles from './Licenses.module.scss';
import { IAuthStore } from "../../../../../core/authorization/stores/AuthStore";
import { observable, runInAction } from "mobx";
import { IApi } from "../../../../../core/api/interfaces";

interface ILicensesFiltersProps extends WithTranslation {
  companyId: string,
  authStore?: IAuthStore,
  modalsStore?: IModalsStore,
  api?: IApi,
  filters: any,
  onFilterChanged: (type: FilterType, value: LicenseType | LicenseActivationStatus | LicensePeriod | string | undefined) => void
  onSearchChanged: (value: string) => Promise<void>,
}

@withApi
@inject(({ authStore, modalsStore }: IStore) => ({ authStore, modalsStore }))
@observer
class _LicensesFilters extends Component<ILicensesFiltersProps> {
  @observable tags: string[] = []

  filtersContainerRef = React.createRef<HTMLDivElement>()
  tagsContainerRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {
    const { api, companyId } = this.props;
    try {
      const tags = await api!.getTags({ companyId })
      runInAction(() => {
        this.tags = tags
      })
    } catch (e) {

    }
  }

  render () {
    const { t, authStore, companyId, filters, onFilterChanged, onSearchChanged } = this.props;
    const typeData = {
      value: filters[FilterType.licenseType],
      lists: [{
        options: AllLicenseTypes.map(licenseType => {
          return {
            value: licenseType,
            text: t(`company_license_filter_type.${licenseType}`),
          };
        }),
      }],
    };
    const statusData = {
      value: filters[FilterType.activationStatus],
      lists: [{
        options: AllLicenseActivationStatuses.map(status => {
          return {
            value: status,
            text: t(`company_license_activation_status.${status}`),
          };
        }),
      }],
    };
    const tagsData = {
      value: filters[FilterType.tag] || LicenseTagsAll,
      lists: [{
        options: [{
          value: LicenseTagsAll,
          text: t('tags_all')
        }, ...this.tags.map(tag => {
          return {
            value: tag,
            text: tag,
          };
        })],
      }],
    };
    const expirationData = {
      value: filters[FilterType.period],
      lists: [{
        options: AllLicensePeriod.map(exp => {
          return {
            value: exp,
            text: t(`company_license_expiration.${exp}`),
          };
        }),
      }],
    };
    const onIssueLicense = () => this.props.modalsStore!.showModal(ModalNameChoices.licenseIssue, { id: companyId });
    return <React.Fragment>
      <div className={styles.FiltersBlock} ref={this.filtersContainerRef}>
        {authStore!.isLicenseAdmin() &&
          <div className={styles.Filter}>
              <Button onClick={onIssueLicense}>
                {t('company_card.licenses.issue_license')}
              </Button>
          </div>
        }
        <div className={styles.Filter}>
          <ContextMenuRadio
            getPopupContainer={() => this.filtersContainerRef.current}
            placement='bottomLeft'
            data={typeData}
            onChange={(value: LicenseType) => onFilterChanged(FilterType.licenseType, value)}>
            <Button>
              <Icon type={'filter'}/>
              {t(`company_license_filter_type.${filters[FilterType.licenseType]}`)}
            </Button>
          </ContextMenuRadio>
        </div>
        {filters[FilterType.licenseType] === LicenseType.commercial &&
        <div className={styles.Filter}>
          <ContextMenuRadio
            getPopupContainer={() => this.filtersContainerRef.current}
            placement='bottomLeft'
            data={expirationData}
            onChange={(value: LicensePeriod) => onFilterChanged(FilterType.period, value)}>
            <Button>
              <Icon type={'filter'}/>
              {t(`company_license_expiration.${filters[FilterType.period]}`)}
            </Button>
          </ContextMenuRadio>
        </div>
        }
        <div className={styles.Filter}>
          <div>
            <ContextMenuRadio
              getPopupContainer={() => this.filtersContainerRef.current}
              placement='bottomLeft'
              data={statusData}
              onChange={(value: LicenseActivationStatus) => onFilterChanged(FilterType.activationStatus, value)}>
              <Button>
                <Icon type={'filter'}/>
                {t(`company_license_activation_status.${filters[FilterType.activationStatus]}`)}
              </Button>
            </ContextMenuRadio>
          </div>
        </div>
        <div className={styles.Filter}>
          <div className={'tags-filter-container'} ref={this.tagsContainerRef}>
            <ContextMenuRadio
              getPopupContainer={() => this.tagsContainerRef.current}
              placement='bottomLeft'
              data={tagsData}
              onChange={(value: string) => onFilterChanged(FilterType.tag, value !== LicenseTagsAll ? value : undefined)}>
              <Button>
                <Icon type={'filter'}/>
                {filters.tag || t('tags_all')}
              </Button>
            </ContextMenuRadio>
          </div>
        </div>
      </div>
      <Input
        placeholder={t('company_license_search_node_owner')}
        addonBefore={<Icon type="search"/>}
        onChange={onSearchChanged}
      />
    </React.Fragment>;
  }
}

export const LicenseFilters = withTranslation()(_LicensesFilters);
