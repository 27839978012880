import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router5';
import {
  Button
} from '@wavesenterprise/uikit';
import { AboutLink, DocsLink, SupportEmail, SupportLink } from '../../../common/constants';
import { RouteNameChoices, RouteNames } from "../../routing";
import commonStyles from "../Authorization.module.scss";
import customStyles from "./WelcomeScreen.module.scss";

interface IProps extends WithTranslation{
}

const _WelcomeScreen = (props: IProps) => {
  const {t} = props;
  return <div>
    <div className={commonStyles.title}>{t('auth.title')}</div>
    <div className={customStyles.WelcomeScreen}>
      <div className={customStyles.content}>
        <span>{t('auth.welcome.description')}</span>
        <span> </span>
        <span>
      <a className={commonStyles.link} href={`mailto:${SupportEmail}`}>{SupportEmail}</a>
    </span>.
        <div className={commonStyles.block32}>
          <Link routeName={RouteNames[RouteNameChoices.register]}>
            <Button type={'primary'}>
              {t('auth.create_account')}
            </Button>
          </Link>
        </div>
        <div className={commonStyles.block32}>
          <Link routeName={RouteNames[RouteNameChoices.login]}>
            <span className={commonStyles.link}>{t('auth.welcome.account_existed')}</span>
          </Link>
        </div>
        <div className={commonStyles.block32}>
          <Link routeName={RouteNames[RouteNameChoices.restore]}>
            <span className={commonStyles.link}>{t('auth.restore_account')}</span>
          </Link>
        </div>
      </div>
      <div className={customStyles.info}>
        <div>
          <a className={commonStyles.link} target='_blank' rel='noopener noreferrer' href={DocsLink}>{t('auth.welcome.documentation')}</a>
        </div>
        <div className={commonStyles.block32}>
          <a className={commonStyles.link} target='_blank' rel='noopener noreferrer' href={AboutLink}>{t('auth.welcome.about_we')}</a>
        </div>
        <div className={commonStyles.block32}>
          <a className={commonStyles.link} target='_blank' rel='noopener noreferrer' href={SupportLink}>{t('auth.welcome.support')}</a>
        </div>
      </div>
    </div>
  </div>
};

export const WelcomeScreen = withTranslation()(_WelcomeScreen);
