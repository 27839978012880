import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { action, observable } from "mobx";
import { Button, Form, Input, NotificationAPI } from '@wavesenterprise/uikit';
import { WithTranslation, withTranslation } from "react-i18next";
import { IStore } from "../../../app/stores";
import { IAuthStore } from "../../core/authorization/stores/AuthStore";
import { withApi } from "../../core/api/withApi";
import { IApi } from "../../core/api/interfaces";
import { Steps } from './constants'
import styles from "./ChangePassword.module.scss";

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  api?: IApi;
  form: any;
  setCurrentStep: (step: Steps) => void
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _EnterCurrentPassword extends Component<IProps> {
  @observable inProgress = false;

  @action
  setInProgress = (inProgress: boolean) => {
    this.inProgress = inProgress
  };

  onSubmit = async () => {
    const {t, form: {validateFields}, authStore, api, setCurrentStep} = this.props;
    try {
      this.setInProgress(true);
      const {password} = await validateFields({force: true});
      const login = authStore!.getUserName();
      await api!.login({login, password}, () => {});
      setCurrentStep(Steps.EnterNewPassword)
    } catch (e) {
      if (e.response) {
        NotificationAPI.error({
          message: t('change_password.incorrect_password')
        })
      }
    } finally {
      this.setInProgress(false);
    }
  };

  render() {
    const {t, form: {getFieldDecorator}} = this.props;
    return <Form>
      <Form.Item>
        {getFieldDecorator('password', {
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [{
            required: true,
            message: t('change_password.required_field')
          }]
        })(
          <Input
            placeholder={t('change_password.current_password')}
            type={'password'}
            autoComplete='new-password'
            onPressEnter={this.onSubmit}
          />
        )}
      </Form.Item>
      <Form.Item>
        <div className={styles.inline}>
          <Button type="primary" onClick={this.onSubmit}>
            {t('buttons.continue')}
          </Button>
        </div>
        <div className={styles.inline}>
          <Button onClick={() => this.props.setCurrentStep(Steps.Initial)}>
            {t('buttons.cancel')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  }
}

export const EnterCurrentPassword = withTranslation()(Form.create()(_EnterCurrentPassword));
