import { Col, Layout } from '@wavesenterprise/uikit';
import { inject, Observer } from 'mobx-react';
import React from 'react';
import { Route } from 'react-router5';
import { IStore } from '../../../app/stores';
import { Authorization } from '../../core/authorization/Authorization';
import { IAuthStore } from '../../core/authorization/stores/AuthStore';
import { RouteNames } from '../../core/routing';
import { CompaniesPage } from '../../companies/CompaniesPage';
import { SettingsPage } from '../../settings/SettingsPage';
import { UsersPage } from '../../users/UsersPage'
import styles from './Content.module.scss';
import { ContentWrapper } from './ContentWrapper';

interface IProps {
  authStore?: IAuthStore;
}

export const Content = inject(({ authStore }: IStore) => ({ authStore }))(
  (props: IProps) => {
    const authStore = props.authStore!;
    console.log('RouteNames.companies', RouteNames.companies, 'RouteNames.users', RouteNames.users)
    return (
      <Col
        xxl={10}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.Content}
      >
        <Layout>
          <Layout.Content>
            <Route>
              {({ route }) => (
                <Observer>
                  {() => {
                    if (!authStore.isLoggedIn()) {
                      return (
                        <ContentWrapper>
                          <Authorization />
                        </ContentWrapper>
                      );
                    }
                    if (route.name.startsWith(RouteNames.companies)) {
                      return <CompaniesPage />;
                    } else if (route.name.startsWith(RouteNames.users)) {
                      return <UsersPage />
                    } else if (route.name.startsWith(RouteNames.settings)) {
                      return <SettingsPage />;
                    } else return null;
                  }}
                </Observer>
              )}
            </Route>
          </Layout.Content>
        </Layout>
      </Col>
    );
  }
);
