import React, { Component } from 'react';
import { Router, State } from 'router5';
import { withRoute } from 'react-router5';
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import LanguageSelect from '../common/language-select/LanguageSelect';
import { IStore } from '../../app/stores';
import { IAuthStore } from '../core/authorization/stores/AuthStore';
import ChangePassword from './change-password'
import styles from './SettingsPage.module.scss';

interface IProps extends WithTranslation {
  route: State;
  router: Router;
  authStore?: IAuthStore;
}

@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _SettingsPage extends Component<IProps> {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.Block32}>
        <span className={styles.Bold}>{t('settings.interface_language')}</span>
        <LanguageSelect />
        <div className={styles.Block32}>
          <div className={styles.SeparatorLine} />
        </div>
        <div className={styles.Block32}>
          <ChangePassword />
        </div>
      </div>
    );
  }
}

const TranslatedPage = withTranslation()(_SettingsPage);
export default withRoute(({ router, route }) => (
  <TranslatedPage router={router} route={route} />
));
