import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { action, observable } from "mobx";
import {
  Form,
  Input,
  NotificationAPI,
  Button
} from '@wavesenterprise/uikit';
import { WithTranslation, withTranslation } from "react-i18next";
import { IStore } from "../../../app/stores";
import { IAuthStore } from "../../core/authorization/stores/AuthStore";
import { withApi } from "../../core/api/withApi";
import { IApi } from "../../core/api/interfaces";
import { Steps } from './constants'
import { validatePasswordByRules } from '../../core/authorization/utils/password-validator'
import styles from "./ChangePassword.module.scss";

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  api?: IApi;
  form: any;
  setCurrentStep: (step: Steps) => void;
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _EnterNewPassword extends Component<IProps> {
  @observable inProgress = false;

  @action
  setInProgress = (inProgress: boolean) => {
    this.inProgress = inProgress
  };

  validatePassword = (rule: any, value: string, cb: Function) => {
    const errorMessage = validatePasswordByRules(value);
    if (errorMessage) {
      cb(errorMessage)
    } else {
      cb()
    }
  };

  validateToNextPassword = (rule: any, value: string, cb: Function) => {
    const { form } = this.props;
    form.validateFields(['confirm'], { force: true });
    cb()
  };

  compareToFirstPassword = (rule: any, value: string, cb: Function) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      cb(true)
    } else {
      cb()
    }
  };

  onSubmit = async () => {
    const {t, authStore, form: {validateFields}, api, setCurrentStep} = this.props;
    try {
      this.setInProgress(true);
      const {password, confirm} = await validateFields({force: true});
      await api!.changePassword({password, passwordRepeat: confirm});
      authStore!.login({login: authStore!.getUserName(), password}, false);
      NotificationAPI.success({
        message: t('change_password.success_message')
      });
      setCurrentStep(Steps.Initial);
    } catch (e) {
    } finally {
      this.setInProgress(false);
    }
  };

  render() {
    const {t, form: {getFieldDecorator}} = this.props;
    return <Form>
      <Form.Item>
        {getFieldDecorator('password', {
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [{
            required: true,
            message: t('change_password.required_field')
          }, {
            validator: this.validatePassword
          }, {
            message: t('change_password.passwords_dont_match'),
            validator: this.validateToNextPassword
          }]
        })(
          <Input
            placeholder={t('change_password.new_password')}
            type={'password'}
            autoComplete='new-password'
            onPressEnter={this.onSubmit}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('confirm', {
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [{
            required: true,
            message: t('change_password.required_field')
          }, {
            validator: this.validatePassword
          }, {
            message: t('change_password.passwords_dont_match'),
            validator: this.compareToFirstPassword
          }]
        })(
          <Input
            placeholder={t('change_password.new_password_confirm')}
            type={'password'}
            autoComplete='new-password'
            onPressEnter={this.onSubmit}
          />
        )}
      </Form.Item>
      <Form.Item>
        <div className={styles.inline}>
          <Button type="primary" onClick={this.onSubmit}>
            {t('buttons.continue')}
          </Button>
        </div>
        <div className={styles.inline}>
          <Button onClick={() => this.props.setCurrentStep(Steps.Initial)}>
            {t('buttons.cancel')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  }
}

export const EnterNewPassword = withTranslation()(Form.create()(_EnterNewPassword));
