export interface ISearchInputProps {
  initialValue?: string;
  items: IListItem[];
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  notFoundText?: string;
  onChange?: (value: string) => void;
  onSelectItem?: Function;
}

export interface IListItem {
  id: string;
  value: string;
}

export interface IOptionsListProps {
  items: IListItem[];
  activeItemId?: string | null;
  onItemClick?: (item: IListItem) => void;
  setActiveItemId?: Function
}

export enum ArrowKeyCodes {
  up    = 38,
  down  = 40
}
