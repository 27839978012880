import React, { Component } from 'react';
import { action, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Form, Input, NotificationAPI, SidePanel, SpinContainer } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IAuthStore } from '../../../core/authorization/stores/AuthStore';
import { IModalsStore } from '../../stores/ModalStore';
import { IApi, ICompanyBasic } from '../../../core/api/interfaces';
import { withApi } from '../../../core/api/withApi';
import CountriesList, { getCountryCodeByName, getNameByCountryCode } from '../../../common/countriesList';
import styles from './CompanyForm.module.scss';
import { SearchInput } from '../../../common/search-input-refactor/SearchInput';
import FixedButtonsContainer from "../../../common/fixed-buttons-container/FixedButtonsContainer";

interface ICompanyFormProps extends WithTranslation {
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  api?: IApi;
  id?: string;
  visible: boolean;
  onCancel: () => void;
  form: any;
}

@withApi
@inject(({ modalsStore, authStore, companiesStore }: IStore) => ({ modalsStore, authStore, companiesStore }))
@observer
class _CompanyForm extends Component<ICompanyFormProps> {
  @observable country = '';
  @observable company: ICompanyBasic | null = null;
  @observable originalCompany: ICompanyBasic | null = null;
  @observable isLoading: boolean = !!this.props.id;
  @observable isSending: boolean = false;
  @observable inputValue = '';

  commonSidePanelProps = {
    visible: true,
    theme: 'black',
    icon: !this.props.id ? 'plus' : 'network',
    onCancel: this.props.onCancel,
  };

  componentDidMount () {
    this.getInitialData();
  }

  onSubmitCompanyClicked = async () => {
    const { t, api, i18n: { language }, id } = this.props;
    try {
      let companyData = await this.props.form.validateFields({ force: true });
      companyData = {
        ...companyData,
        countryCode: getCountryCodeByName(language, companyData.countryCode)
      };
      if (!id) {
        await api!.createCompany(companyData);
      } else {
        await api!.updateCompany({ id, ...companyData });
      }
      NotificationAPI.success({
        message: t(id ? 'update_company.successfully_updated' : 'create_company.successfully_created', { name: companyData.shortName }),
      });
      this.props.onCancel();
    } catch (e) {
      if (e.response) {
        const message = e.response!.data!.message;
        if (message === 'Company already exists') {
          NotificationAPI.warning({
            message: t('create_company.company_already_exists')
          })
        }
      } else {
        console.log('Validate errors: ', e);
      }
    }
  };

  @action
  setIsLoading (isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setIsSending (isSending: boolean) {
    this.isSending = isSending;
  }

  @action
  async getInitialData () {
    const { id, api } = this.props;
    if (!id) return;
    this.setIsLoading(true);
    try {
      const company = await api!.getCompanyById(id);
      runInAction(() => {
        this.originalCompany = company;
        this.company = company;
        this.inputValue = getNameByCountryCode(this.props.i18n.language, company.countryCode)
      });
    } catch (e) {
    } finally {
      this.setIsLoading(false);
    }
  }

  renderLoading () {
    return (
      <div className={styles.SpinContainer}>
        <SpinContainer size={'medium'}/>
      </div>
    );
  }

  renderError () {
    const { t } = this.props;
    return <div>{t('company_card.cannot_load_company_data')}</div>;
  }

  validatorCountry = (rule: any, value: string, cb: Function) => {
    const { i18n: { language }} = this.props;
    const countryCode = getCountryCodeByName(language, value);
    if (!countryCode) {
      cb(true)
    } else {
      cb()
    }
  };

  renderForm () {
    const { t, i18n: { language }, onCancel, form: { getFieldDecorator } } = this.props;

    const countriesList = CountriesList(language)
      .map((item: any) => {
        return {
          id: item.value,
          value: item.text
        }
      });
    const searchInputProps = {
      initialValue: this.company ? getNameByCountryCode(language, this.company.countryCode) : '',
      placeholder: t('select_country'),
      items: this.inputValue
        ? countriesList.filter(item => item.value.toLowerCase().includes(this.inputValue.toLowerCase()))
        : countriesList,
      notFoundText: t('country_not_found'),
      onChange: (value: string) => {
        runInAction(() => {
          this.inputValue = value
        })
      }
    };
    return (
      <React.Fragment>
        <FixedButtonsContainer>
          <Button wrapperClassName={styles.Button} type={'primary'} onClick={this.onSubmitCompanyClicked}>
            {t('buttons.save')}
          </Button>
          <Button wrapperClassName={styles.Button} onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
        </FixedButtonsContainer>
        <Form>
          <div className={styles.Item}>
            <Form.Item>
                {getFieldDecorator('inn', {
                  rules: [{ required: true, message: t('required_field') }],
                  validateTrigger: 'onBlur',
                  initialValue: this.company ? this.company.inn : '',
                })(<Input label={t('company.inn_short')} maxLength={50} />)}
            </Form.Item>
          </div>
          <div className={styles.Item}>
            <Form.Item>
                {getFieldDecorator('shortName', {
                  rules: [{ required: true, message: t('required_field') }],
                  validateTrigger: 'onBlur',
                  initialValue: this.company ? this.company.shortName : '',
                })(<Input label={t('company.short_name')} maxLength={200} />)}
            </Form.Item>
          </div>
          <div className={styles.Item}>
            <Form.Item>
                {getFieldDecorator('fullName', {
                  validateFirst: true,
                  rules: [{
                    required: true,
                    message: t('required_field')
                  }],
                  validateTrigger: 'onBlur',
                  initialValue: this.company ? this.company.fullName : '',
                })(<Input.TextArea
                  className="v-input"
                  placeholder={t('company.full_name')}
                  maxLength={500}
                  autosize={{ minRows: 6 }}
                />)}
            </Form.Item>
          </div>
          <div className={styles.Item}>
            <Form.Item>
                {getFieldDecorator('countryCode', {
                  validateTrigger: 'onBlur',
                  validateFirst: true,
                  initialValue: this.company ? getNameByCountryCode(language, this.company.countryCode) : '',
                  rules: [{
                    required: true,
                    message: t('required_field')
                  }, {
                    validator: this.validatorCountry,
                    message: t('country_not_found')
                  }]
                })(<SearchInput label={t('company.country')} {...searchInputProps} />)}
            </Form.Item>
          </div>
        </Form>
      </React.Fragment>
    );
  }

  render () {
    const { id } = this.props;
    const sidePanelProps = {
      ...this.commonSidePanelProps,
      mainTitle: this.props.t(id ? 'update_company.title' : 'create_company.title'),
      subTitle: this.props.t(id ? 'update_company.sub_title' : 'create_company.sub_title'),
    };
    return (
      <SidePanel {...sidePanelProps}>
        <div className={styles.Content}>
          {!id ? this.renderForm() :
            this.isLoading
              ? this.renderLoading()
              : this.company !== null
              ? this.renderForm()
              : this.renderError()}
        </div>
      </SidePanel>
    );
  }
}

const TranslatedForm = withTranslation()(_CompanyForm);
export const CompanyForm = Form.create()(TranslatedForm);
