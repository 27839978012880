import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable } from "mobx";
import { withRoute } from "react-router5";
import { Button, Form, Input, NotificationAPI } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { IAuthStore } from '../stores/AuthStore';
import { validatePasswordByRules } from '../utils/password-validator'
import commonStyles from "../Authorization.module.scss";
import styles from './SetPassword.module.scss';
import { AuthError } from "../../api/error-codes";
import { Router, State } from "router5";
import { SupportLink } from "../../../common/constants";

const FormItem = Form.Item;

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  route: State;
  router: Router;
  api?: IApi;
  form: any; // todo we does not have type yet
}

enum FormFields {
  password = 'password',
  confirmPassword = 'confirmPassword'
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _SetPassword extends Component<IProps> {
  @observable inProgress = false;

  @action
  setInProgress = (inProgress: boolean) => {
    this.inProgress = inProgress
  };

  validatorPassword = async (rule: any, value: string, cb: Function) => {
    const errorMessage = validatePasswordByRules(value);
    if (errorMessage) {
      cb(errorMessage)
    } else {
      cb()
    }
  };

  validateToNextPassword = (rule: any, value: string, cb: Function) => {
    this.props.form.validateFields([FormFields.confirmPassword], { force: true });
    cb()
  };

  compareToFirstPassword = (rule: any, value: string, cb: Function) => {
    const {form: {getFieldValue}} = this.props;
    if (value && value !== getFieldValue(FormFields.password)) {
      cb(true)
    } else {
      cb()
    }
  };

  onSubmit = async () => {
    const {api, t, form: {validateFields}, route: {
      params: { token, email }
    }, authStore} = this.props;
    try {
      this.setInProgress(true);
      const {password} = await validateFields({force: true});
      await api!.resetPassword({token, password});
      await authStore!.login({
        login: email,
        password
      }, true);
      NotificationAPI.success({
        message: t('auth.set_password.success_message')
      });
    } catch (e) {
      const {response} = e;
      if (response) {
        const key = 'set-password';
        const {data: {errors}} = response;
        if(errors && errors.includes(AuthError.TokenAlreadyUsed)) {
          NotificationAPI.error({
            key,
            message: t('auth.set_password.token_already_used')
          })
        } else {
          NotificationAPI.error({
            key,
            message: t('auth.set_password.common_error')
          })
        }
      }
    } finally {
      this.setInProgress(false)
    }
  };

  render() {
    const { t, form: { getFieldDecorator }, route: {
      params: { email, token }
    }} = this.props;
    const onSupportClick = () => window.open(SupportLink, '_blank');
    return (
      <div className={styles.SetPassword}>
        <div className={commonStyles.title}>{t('auth.set_password.title')}</div>
        {email &&
          <div className={styles.login}>
            {t('auth.set_password.your_login')}{' '}{email}
          </div>
        }
        {token &&
          <span>{t('auth.set_password.description')}</span>
        }
        <div className={commonStyles.block32}>
          {!token &&
            <div>
                <span>{t('auth.set_password.no_token')}</span>
                <div className={commonStyles.block32}>
                  <span className={commonStyles.link} onClick={onSupportClick}>
                    {t('auth.set_password.support_link')}
                  </span>
                </div>
            </div>
          }
          {token &&
            <Form>
                <FormItem>
                  {getFieldDecorator(FormFields.password, {
                    // initialValue: '',
                    validateFirst: true,
                    validateTrigger: 'onBlur',
                    rules: [{
                      required: true,
                      message: t('required_field')
                    }, {
                      validator: this.validatorPassword
                    }, {
                      validator: this.validateToNextPassword
                    }]
                  })(
                    <Input
                      placeholder={t('auth.password')}
                      type="password"
                      onPressEnter={this.onSubmit}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator(FormFields.confirmPassword, {
                    // initialValue: '',
                    validateFirst: true,
                    validateTrigger: 'onBlur',
                    rules: [{
                      required: true,
                      message: t('required_field')
                    }, {
                      validator: this.validatorPassword
                    }, {
                      validator: this.compareToFirstPassword,
                      message: t('auth.password_confirmation_error')
                    }]
                  })(
                    <Input
                      placeholder={t('auth.password_confirmation')}
                      type="password"
                      onPressEnter={this.onSubmit}
                    />
                  )}
                </FormItem>
                <FormItem>
                    <Button type="primary" onClick={this.onSubmit} disabled={this.inProgress}>
                      {t('buttons.continue')}
                    </Button>
                </FormItem>
            </Form>
          }
        </div>
      </div>
    );
  }
}

const Translated = withTranslation()(Form.create()(_SetPassword));
// @ts-ignore
export const SetPassword = withRoute(({ route, router }) => <Translated route={route} router={router}/>);
