import { action, observable } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import { Router } from 'router5';
import { IApi, UserRole } from '../../api/interfaces';
import { RouteNameChoices, RouteNames } from '../../routing';
import i18next from 'i18next';
import { NotificationAPI } from '@wavesenterprise/uikit';
import decode from 'jwt-decode';

interface ILoginData {
  login: string;
  password: string;
}

interface ICreateAccountData {
  login: string;
  password: string;
}

interface IUserJwtData {
  id: string;
  roles: UserRole[];
  name: string;
}

export interface IAuthStore {
  isLoggedIn(): boolean;
  isLoading(): boolean;
  login(data: ILoginData, redirectOnSuccess: boolean): Promise<void>;
  createAccount(data: ICreateAccountData): Promise<void>;
  logout(): void;
  getUserRoles(): UserRole[];
  isLicenseAdmin(): boolean;
  getUserName(): string;
  getUserId(): string;
  isCompanyMember(): boolean;
  setIsCompanyMember(isMember: boolean): void
}

export class AuthStore implements IAuthStore {
  constructor(deps: { router: Router; api: IApi }) {
    const { api, router } = deps;
    this.router = router;
    this.api = api;
    const routeState = router.getState();
    if (!routeState.name.startsWith(RouteNames.auth)) {
      this._redirectTo = routeState.name;
      this._redirectParams = routeState.params;
    }
  }

  private readonly router: Router;

  private readonly api: IApi;

  @observable
  private _loggedIn: boolean = false;

  @observable
  private _loading = false;

  @observable
  private _userJwtData: IUserJwtData | null = null;

  @observable
  private _isCompanyMember = false;

  private _redirectTo = RouteNames[RouteNameChoices.companies].toString();
  private _redirectParams = {};

  @actionAsync
  public createAccount = async (data: ICreateAccountData) => {
    this._loading = true;
    try {

    } catch (e) {

    }
  };

  @actionAsync
  public login = async (data: ILoginData, redirectOnSuccess = true) => {
    this._loading = true;
    try {
      const { access_token } = await task(
        this.api.login(data, this.onTokenExpired)
      );
      this._userJwtData = decode(access_token);
      const userRoles = this.getUserRoles();
      if (userRoles.length === 1 && userRoles.includes(UserRole.user)) {
        const {count} = await this.api.getCompanies({});
        this._isCompanyMember = count > 0
      } else if (userRoles.includes(UserRole.licenseAdmin)) {
        this._isCompanyMember = true
      }
      if (userRoles.length === 0) {
        const t = i18next.getFixedT(i18next.language, 'common');
        NotificationAPI.error({
          key: 'access_denied',
          duration: 0,
          message: t('access_denied.message'),
          description: t('access_denied.description')
        });
      } else {
        this._loggedIn = true;
        if (redirectOnSuccess) {
          this.router.navigate(this._redirectTo, this._redirectParams);
        }
        NotificationAPI.destroy();
      }
    } catch (e) {
      this._loggedIn = false;
      throw e;
    }
    this._loading = false;
  };

  public onTokenExpired = () => {
    this.logout();
    const t = i18next.getFixedT(i18next.language, 'common');
    NotificationAPI.warning({
      key: 'logout',
      duration: 0,
      message: t('logout_notification.message'),
      description: t('logout_notification.description')
    });
  };

  @action
  public logout = () => {
    this._loggedIn = false;
    this._redirectTo = RouteNames[RouteNameChoices.companies].toString();
    this._redirectParams = {};
    this.router.navigate(RouteNames.auth);
  };

  public isLoggedIn = () => {
    return this._loggedIn;
  };

  public isLoading(): boolean {
    return this._loading;
  }

  public getUserRoles(): UserRole[] {
    return this._userJwtData
      ? this._userJwtData.roles
      : []
  }

  public isLicenseAdmin(): boolean {
    return this._userJwtData
      ? this._userJwtData.roles.includes(UserRole.licenseAdmin)
      : false
  }

  public getUserId(): string {
    return this._userJwtData
      ? this._userJwtData.id
      : ''
  }

  public getUserName(): string {
    return this._userJwtData
      ? this._userJwtData.name.toLowerCase()
      : ''
  }

  public isCompanyMember(): boolean {
    return this._isCompanyMember
  }

  @action
  public setIsCompanyMember(isMember: boolean): void {
    this._isCompanyMember = isMember
  }
}
