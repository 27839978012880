import { Col } from '@wavesenterprise/uikit';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRoute } from 'react-router5';
import { State } from 'router5';
import { IStore } from '../../../app/stores';
import { IAuthStore } from '../../core/authorization/stores/AuthStore';
import { NavigationMenu } from '../navigation-menu/NavigationMenu';
import styles from './Sidebar.module.scss';
import Logo from '../../../app/resources/we-logo.svg' // eslint-disable-line

interface IProps {
  route: State;
  authStore?: IAuthStore;
}

const _Sidebar = inject(({ authStore }: IStore) => ({ authStore }))(
  observer((props: IProps) => {
    const { authStore } = props;
    return (
      <Col
        xxl={4}
        xl={4}
        lg={4}
        md={4}
        sm={4}
        xs={4}
        className={styles.Sidebar}
      >
        <div className={styles.logoWrapper}>
          <div className={styles.logo}>
            <img src={Logo} alt={'Logo'} />
          </div>
        </div>
        {authStore!.isLoggedIn() && <NavigationMenu />}
      </Col>
    );
  })
);

export const Sidebar = withRoute(({ route }) => <_Sidebar route={route} />);
