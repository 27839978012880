import { IRouteParams as ICompaniesStoreRouteParams } from "../../companies/stores/CompaniesStore";
import { LicenseType } from '../../modals/constants';

export enum ClientLocale {
  ru = "ru",
  en = "en"
}

export interface ICreateAccountParams {
  username: string;
  password: string;
  locale: ClientLocale;
}

export interface ICreateAccountResponse {}

export interface ILoginParams {
  login: string;
  password: string;
}

export interface IActivateUserParams {
  code: string;
}

export interface IActivateUserResponse {
  userId: string;
}

export interface IRestorePasswordParams {
  email: string;
}

export interface IResetPasswordParams {
  token: string;
  password: string;
}

export interface IResetPasswordResponse {
  userId: string;
  errors?: string[];
}

export interface IRestorePasswordResponse {
  email: string;
}

export interface ILicenseIssueParams {
  type: string;
  productId: number;
  quantity: number;
  validFrom: string;
  validTo?: string;
  comment: string;
  tags?: string[];
  projectName?: string;
  envType: string;
}

export interface IChangePasswordParams {
  password: string;
  passwordRepeat: string;
}

export interface IDownloadLicenseParams {
  companyId: string;
  licenseId: string
}

export interface ICancelLicenseParams {
  companyId: string;
  licenseId: string
}

export interface IGetCompaniesParams extends ICompaniesStoreRouteParams {}

export interface IGetCompanyLicensesParams {
  nodeOwnerAddress?: string;
  type?: string;
  status?: string;
  period?: string;
}

export enum Locale {
  ru = "ru",
  en = "en"
}

export enum UserRole {
  user = 'user',
  licenseAdmin = 'license_admin'
}

export enum UserStatus {
  active = "active",
  unconfirmed = "unconfirmed"
}

export enum LicenseStatus {
  available = 'available',
  activated = 'activated',
  expiring = 'expiring',
  expired = 'expired',
  cancelled = 'cancelled'
}

export enum LicenseProduct {
  we_node = "we_node"
}

interface ILicensesSummary {
  activated: number;
  expiring: number;
  available: number;
}

export interface ICompanyBasic {
  fullName: string,
  shortName: string,
  inn: string,
  countryCode: string,
}

export interface ICreateCompany extends ICompanyBasic {}

export interface IUpdateCompany extends ICompanyBasic {
  id: string
}

export interface ICompany extends ICompanyBasic {
  id: string;
  licenses: ILicensesSummary;
  users: ICompanyUser[];
}

export interface ICompaniesList {
  companies: ICompany[];
  count: number;
  hasMore: boolean;
}

export interface ILicense {
  id: string;
  type: LicenseType;
  status: LicenseStatus;
  product: LicenseProduct;
  nodeOwnerAddress: string | null;
  created: string;
  activated: string | null;
  validFrom: string;
  validTo: string | null;
  comment: string | null;
  activatedBy: string | null;
  tags: string[];
  projectName: string;
  envType: string;
}

export interface IUser {
  id: string;
  name: string;
  locale: Locale;
  addresses: string[];
  roles: UserRole[];
  status: UserStatus;
  createdAt: Date;
}

export interface ICompanyUser {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  position: string;
  phone: string;
  comment: string;
  createdAt: string;
}

export interface IAddCompanyUser {
  login: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  phone?: string;
  comment?: string;
}

export interface ICompanyUserExtended extends ICompanyUser {
  companies: ICompany[];
}

export interface IUpdateCompanyUser {
  firstName?: string;
  lastName?: string;
  position?: string;
  phone?: string;
  comment?: string;
}

export interface ICreateServiceUser {
  username: string;
  locale: Locale;
  roles: UserRole[];
}

export interface IUpdateUserRolesResponse {
  user: IUser;
  message?: string;
}

export interface IProduct {
  id: number;
  name: string
}

export type IGetProductsResponse = IProduct[];

export interface ILoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface ILicenseType {
  type: string;
  period: number | null;
}

export interface IGetConfigResponse {
  maxLicenseCount: number;
  expiringThreshold: number;
  trialPeriod: number;
}

export interface IGetSignerInfoResponse {
  name: string;
  version: string;
}

export type ILicenseTypeResponse = ILicenseType[];

export interface IActivateLicenseParams {
  id: string;
  nodeOwnerAddress: string;
  tags?: string[];
}

export interface IActivateLicenseResponse {
  id: string;
  type: string;
  product: string;
  status: string;
  activatedBy: string;
  nodeOwnerAddress: string;
  created: string;
  activated: string;
  validFrom: string;
  validTo: string;
  comment: string;
}

export interface IGetLicenseUsersParams {
  search?: string;
  limit?: number;
  offset?: number;
}

export interface IGetAuthUsersParams {
  roles: UserRole[];
  status: UserStatus[];
  search: string;
  limit: number;
  offset: number;
}

export interface IGetAuthUsersResponse {
  users: IUser[];
  count: number;
  hasMore: boolean;
}

export interface IGetUsersResponse {
  users: ICompanyUserExtended[];
  count: number;
  hasMore: boolean;
}

export interface IGetTagsParams {
  companyId?: string
}

export interface IUpdateLicenseParams {
  companyId?: string;
  projectName?: string;
  envType?: string;
}

export interface IApi {
  login (data: ILoginParams, onTokenExpired: () => void): Promise<ILoginResponse>;

  createCompany (company: ICompanyBasic): Promise<any>;

  createAccount (data: ICreateAccountParams): Promise<ICreateAccountResponse>;

  activateUser (data: IActivateUserParams): Promise<IActivateUserResponse>;

  restorePassword (data: IRestorePasswordParams): Promise<IRestorePasswordResponse>;

  resetPassword (data: IResetPasswordParams): Promise<IResetPasswordResponse>;

  createAccount (data: ICreateAccountParams): Promise<ICreateAccountResponse>;

  activateUser (data: IActivateUserParams): Promise<IActivateUserResponse>;

  changePassword (params: IChangePasswordParams): Promise<void>;

  getCompanies (data: IGetCompaniesParams): Promise<ICompaniesList>;

  createCompany (company: ICreateCompany): Promise<any>;

  updateCompany (company: IUpdateCompany): Promise<any>;

  addCompanyUser (companyId: string, user: ICompanyUser): Promise<any>;

  removeCompanyUser (companyId: string, login: string): Promise<any>;

  updateCompanyUser (userId: string, params: IUpdateCompanyUser): Promise<any>;

  getCompanyById (id: string): Promise<any>;

  getCompanyUsers (companyId: string): Promise<any>;

  getCompanyLicenses (companyId: string, params: IGetCompanyLicensesParams): Promise<any>;

  getLicenseById (companyId: string, licenseId: string): Promise<ILicense>;

  getTags (params: IGetTagsParams): Promise<string[]>;

  setLicenseTags (licenseId: string, tags: string[]): Promise<ILicense>;

  licenseIssue (companyId: number, params: ILicenseIssueParams): Promise<void>;

  getLicenseTypes (): Promise<ILicenseTypeResponse>;

  getProducts (): Promise<IGetProductsResponse>;

  getConfig (): Promise<IGetConfigResponse>;

  getUserByEmail (email: string): Promise<IUser>;

  getUserById (id: string): Promise<ICompanyUser>;

  downloadLicense (data: IDownloadLicenseParams): Promise<any>;

  cancelLicense (data: ICancelLicenseParams): Promise<ILicense>;

  activateLicense (companyId: string, data: IActivateLicenseParams): Promise<IActivateLicenseResponse>;

  updateLicense (companyId: string, licenseId: string, data: IUpdateLicenseParams): Promise<ILicense>;

  getAuthUsers (params: IGetAuthUsersParams): Promise<IGetAuthUsersResponse>;

  getSignerInfo(): Promise<IGetSignerInfoResponse>;

  getUsers(params: IGetLicenseUsersParams): Promise<IGetUsersResponse>;

  getUserCompanies(id: string): Promise<ICompany[]>
}
