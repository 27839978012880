import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withApi } from '../../../core/api/withApi';
import { action, computed, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { SidePanel, SpinContainer, Tabs } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IModalsStore } from '../../stores/ModalStore';
import { IAuthStore } from '../../../core/authorization/stores/AuthStore';
import { IApi, ICompany } from '../../../core/api/interfaces';
import styles from './CompanyDetails.module.scss';
import { ICompaniesStore } from '../../../companies/stores/CompaniesStore';
import LicensesTab from './tabs/licenses'
import UsersTab from './tabs/users'
import InfoTab from './tabs/info'
import { Router } from "router5";
import { RouteNameChoices, RouteNames } from "../../../core/routing";

enum CompanyDetailsTabs {
  licenses = 'licenses',
  users = 'users',
  info = 'info'
}

const RoutesByTabs = {
  [CompanyDetailsTabs.licenses]: RouteNames[RouteNameChoices.companyLicenses],
  [CompanyDetailsTabs.users]: RouteNames[RouteNameChoices.companyUsers],
  [CompanyDetailsTabs.info]: RouteNames[RouteNameChoices.companyInfo],
};

interface ICompanyDetailsProps extends WithTranslation {
  router: Router;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  companiesStore?: ICompaniesStore;
  api?: IApi;
  visible: boolean;
  id: string;
  onCancel: () => void;
}

@withApi
@inject(({ modalsStore, authStore, companiesStore }: IStore) => ({
  modalsStore,
  authStore,
  companiesStore
}))
@observer
class _CompanyDetails extends Component<ICompanyDetailsProps> {
  @observable company: ICompany | null = null;
  @observable isLoading: boolean = false;
  @observable isSending: boolean = false;
  @observable currentTab = CompanyDetailsTabs.licenses;

  commonSidePanelProps = {
    visible: true,
    view: 'sticky',
    theme: 'black',
    icon: 'network',
    onCancel: this.props.onCancel,
    mainTitle: this.props.t('company_card.title'),
    subTitle: this.props.t('company_card.sub_title')
  };

  @computed get sidePanelProps () {
    const {t} = this.props;
    let props = {
      ...this.commonSidePanelProps
    };
    if (this.company) {
      props = {
        ...props,
        mainTitle: this.company.shortName,
        subTitle: t('company.inn', {inn: this.company.inn})
      }
    }
    return props
  }

  componentDidMount() {
    const initialTab = this.getTabByRoute();
      if (initialTab) {
      runInAction(() => {
        this.currentTab = initialTab;
      });
    }
    this.getInitialData();
  }

  getTabByRoute = () => {
    const {router} = this.props;
    const {name} = router.getState();
    if (name.startsWith(RouteNames[RouteNameChoices.companyUsers])) {
      return CompanyDetailsTabs.users
    } else if (name.startsWith(RouteNames[RouteNameChoices.companyInfo])) {
      return CompanyDetailsTabs.info
    }
    return CompanyDetailsTabs.licenses
  };

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  getInitialData = async () => {
    const { id, api } = this.props;
    if (api) {
      this.setIsLoading(true);
      try {
        const company = await api.getCompanyById(id);
        runInAction(() => {
          this.company = company
        });
      } catch (e) {
        runInAction(() => {
          this.company = null;
        })
      } finally {
        this.setIsLoading(false);
      }
    }
  };

  @action
  onTabChange = (tabKey: CompanyDetailsTabs) => {
    const {id, router} = this.props;
    this.currentTab = tabKey;
    const route = RoutesByTabs[tabKey];
    router.navigate(route, {id})
  };

  getContentByScreen = () => {
    const { t } = this.props;
    const tabProps = {
      companyId: this.props.id,
      company: this.company
    };

    if (this.isLoading) {
      return <div className={styles.SpinContainer}>
        <SpinContainer size={'medium'} />
      </div>
    }

    return <React.Fragment>
      <Tabs type={'card'} tabBarGutter={40}
            activeKey={this.currentTab}
            onChange={this.onTabChange}>
        <Tabs.Pane tab={t('company_card.tab_licenses')} key={CompanyDetailsTabs.licenses}>
          {this.currentTab === CompanyDetailsTabs.licenses &&
            <LicensesTab {...tabProps} router={this.props.router}/>
          }
        </Tabs.Pane>
        <Tabs.Pane tab={t('company_card.tab_users')} key={CompanyDetailsTabs.users}>
          {this.currentTab === CompanyDetailsTabs.users &&
            <UsersTab {...tabProps} />
          }
        </Tabs.Pane>
        <Tabs.Pane tab={t('company_card.tab_info')} key={CompanyDetailsTabs.info}>
          {this.currentTab === CompanyDetailsTabs.info &&
            <InfoTab {...tabProps} />
          }
        </Tabs.Pane>
      </Tabs>
    </React.Fragment>
  };

  render() {
    return (
      <SidePanel {...this.sidePanelProps}>
        {this.getContentByScreen()}
      </SidePanel>
    );
  }
}

export const CompanyDetails = withTranslation()(_CompanyDetails);

