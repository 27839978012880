import React from 'react';
import { withRoute } from "react-router5";
import { Router, State } from "router5";
import { RouteNameChoices, RouteNames } from "../../routing";
import { TypeNewPassword } from './TypeNewPassword'
import { TypeRestoreEmail } from './TypeRestoreEmail'

interface IProps {
  router: Router;
  route: State;
}

function _RestoreScreen (props: IProps) {
  const { route } = props;
  if (route.name.startsWith(RouteNames[RouteNameChoices.resetPassword])) {
    return <TypeNewPassword />
  } else {
    return <TypeRestoreEmail />
  }
}

export default withRoute(({ router, route }) => <_RestoreScreen route={route} router={router} />);
