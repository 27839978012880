import { DataList } from '@wavesenterprise/uikit';
import React from 'react';
import { ContextMenuButtons, ListCol, Icon } from "@wavesenterprise/uikit";
import { WithTranslation, withTranslation } from 'react-i18next';
import { ICompany } from "../../../../core/api/interfaces";
import { withApi } from "../../../../core/api/withApi";
import { Api } from "../../../../core/api";

interface ContextButtonProps extends WithTranslation {
  api: Api;
  id: string; // list item id property is passed from ui-kit component
  login: string;
  onRemoveFromCompany: (companyId: string) => void
}

function _ContextButton(props: ContextButtonProps) {
  const { t, api, id: companyId, login, onRemoveFromCompany } = props

  const removeUserFromCompany = async () => {
    try {
      await api.removeCompanyUser(companyId, login)
      onRemoveFromCompany(companyId)
    } catch (e) {
      console.log('Cannot remove user from company:', e.message)
    }
  }

  const contextMenuOptions = {
    remove: 'remove'
  }

  const contextMenuProps = {
    data: [{
      text: t('users.remove_from_company'),
      value: contextMenuOptions.remove
    }],
    placement: 'bottomRight',
    onChange: (value: string) => {
      if (value === contextMenuOptions.remove) {
        removeUserFromCompany()
      }
    }
  }
  return (
    <ListCol>
      <ContextMenuButtons {...contextMenuProps}>
        <div>
          <Icon type='details' />
        </div>
      </ContextMenuButtons>
    </ListCol>
  );
}

const ContextButton = withApi(withTranslation()(_ContextButton))

interface UserCompaniesListProps extends WithTranslation {
  companies: ICompany[];
  login: string;
  onRemoveFromCompany: (companyId: string) => void;
}

function UserCompaniesList (props: UserCompaniesListProps) {
  const { companies, login } = props

  const data = companies.map(({ id,inn, shortName }) => {
    return {
      key: id,
      type: 'users',
      columns: [{
          value: shortName,
          caption: inn,
        }]
    }
  })

  const dataConfig = {
    users: {
      columns: [{
          key: '1',
          dimensions: 11,
        }],
      button: <ContextButton login={login} onRemoveFromCompany={props.onRemoveFromCompany} />
    },
  }

  return <div>
    <DataList
      data={data}
      dataConfig={dataConfig}
    />
  </div>
}

export default withTranslation()(UserCompaniesList)
