import { NotificationAPI } from '@wavesenterprise/uikit';
import axios from 'axios';
import { action, observable } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import { Router } from 'router5';
import { IApi, ICompany, LicenseStatus } from '../../core/api/interfaces';
import { RouteNames } from '../../core/routing';
import i18next from 'i18next';

const DEFAULT_LIMIT = 20;

export type LicenseStatusChoice = LicenseStatus | 'all';

export interface IRouteParams {
  search?: string;
  status?: LicenseStatusChoice;
  tag?: string;
  limit?: number;
  offset?: number;
}

export interface ICompaniesStore {
  syncWithRouteParams (routeParams: IRouteParams): Promise<void>;

  loadMoreCompanies (): void;

  isLoading (): boolean;

  getSearchFilter (): string;

  setSearchFilter (value: string): void;

  getStatusesFilter (): LicenseStatusChoice;

  setStatusesFilter (value: LicenseStatusChoice): void;

  getTagFilter (): string;

  setTagFilter (value: string): void;

  getCompanies (): ICompany[];

  getCompaniesCount (): number;

  hasMoreToLoad (): boolean;

  resetCompaniesList (): void;

  updateTags (): void;

  getTags (): string[];
}

export class CompaniesStore implements ICompaniesStore {
  constructor (deps: { api: IApi; router: Router }) {
    const { api, router } = deps;
    this._api = api;
    this._router = router;
    this._companies = [];
    this._companiesCount = 0;
    this._hasMore = true;
    this._loading = false;
    this._limit = DEFAULT_LIMIT;
    this._offset = 0;
    this._tags = []

    this._searchFilter = '';
    this._statusesFilter = 'all';
    this._tagFilter = 'all';
  }

  private readonly _api: IApi;

  private readonly _router: Router;

  private _typingTimerId: number | null = null;

  private readonly _limit: number;

  private _offset: number;

  @observable
  private _loading: boolean;

  @observable
  private _tags: string[];

  @observable
  private _searchFilter: string;

  @observable
  private _statusesFilter: LicenseStatusChoice;

  @observable
  private _tagFilter: string;

  @observable
  private _companies: ICompany[];

  @observable
  private _companiesCount: number;

  @observable
  private _hasMore: boolean;

  @action
  public resetCompaniesList = () => {
    this._offset = 0;
    this._companies = [];
    this._companiesCount = 0;
  };


  private _mapClassPropertiesToRouteParams = (): IRouteParams => {
    return {
      status: this._statusesFilter === 'all' ? undefined : this._statusesFilter,
      search: this._searchFilter === '' ? undefined : this._searchFilter,
      tag: this._tagFilter === 'all' ? undefined : this._tagFilter,
      limit: this._limit,
      offset: this._offset,
    };
  };

  private _showLoadingErrorNotification () {
    const t = i18next.getFixedT(i18next.language, 'common');
    NotificationAPI.error({
      key: 'companies_loading_error',
      message: t('loading_errors.companies'),
    });
  }

  @actionAsync
  private _fetchCompanies = async () => {
    this._loading = true;
    try {
      const { companies, count, hasMore } = await task(
        this._api.getCompanies({
          ...this._mapClassPropertiesToRouteParams(),
        }),
      );
      this._loading = false;
      this._companiesCount = count;
      this._companies.push(...companies);
      this._hasMore = hasMore;
      // this._offset = this._offset + this._limit;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled');
      } else {
        this._loading = false;
        this._companies = [];
        this._showLoadingErrorNotification();
      }
    }
  };

  @actionAsync
  public updateTags = async () => {
    try {
      const tags = await task(
        this._api.getTags({}),
      );
      this._tags = tags
    } catch (e) {

    }
  }

  @actionAsync
  public syncWithRouteParams = async (routeParams: IRouteParams) => {
    const {
      status = 'all',
      search = '',
      tag = 'all'
    } = routeParams;
    this._statusesFilter = status;
    this._searchFilter = search;
    this._tagFilter = tag
    this._companies = [];
    await task(this._fetchCompanies());
  };

  public loadMoreCompanies(): void {
    this._offset = this._offset + this._limit;
    this._fetchCompanies()
  }

  public isLoading = () => {
    return this._loading;
  };

  public getSearchFilter = (): string => {
    return this._searchFilter;
  };

  public getTagFilter = (): string => {
    return this._tagFilter;
  };

  public getTags = (): string[] => {
    return this._tags
  };

  @action
  public setSearchFilter = async (value: string): Promise<void> => {
    this._searchFilter = value;
    const timeout = value ? 800 : 0;
    if (this._typingTimerId !== null) {
      clearTimeout(this._typingTimerId);
    }
    this._typingTimerId = window.setTimeout(() => {
      this.resetCompaniesList();
      this._router.navigate(RouteNames.companies, {
        ...this._mapClassPropertiesToRouteParams(),
        // search: value,
      });
    }, timeout);
  };

  public getStatusesFilter = (): LicenseStatusChoice => {
    return this._statusesFilter;
  };

  @action
  public setStatusesFilter = async (
    value: LicenseStatusChoice,
  ): Promise<void> => {
    this._statusesFilter = value;
    this.resetCompaniesList();
    this._router.navigate(RouteNames.companies, {
      ...this._mapClassPropertiesToRouteParams(),
      // status: value,
    });
  };

  @action
  public setTagFilter = async (
    value: string,
  ): Promise<void> => {
    this._tagFilter = value;
    this.resetCompaniesList();
    this._router.navigate(RouteNames.companies, {
      ...this._mapClassPropertiesToRouteParams(),
      // tag: value,
    });
  };

  public getCompanies = (): ICompany[] => {
    return this._companies;
  };

  public getCompaniesCount = (): number => {
    return this._companiesCount;
  };

  public hasMoreToLoad = (): boolean => {
    return this._hasMore;
  };
}
