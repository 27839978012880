import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IAuthStore } from "../../core/authorization/stores/AuthStore";
import styles from "./ChangePassword.module.scss";
import { Steps } from "./constants";
import { EnterCurrentPassword } from "./EnterCurrentPassword";
import { EnterNewPassword } from "./EnterNewPassword";

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
}

function _ChangePassword(props: IProps) {
  const {t} = props;
  const [currentStep, setCurrentStep] = useState(Steps.Initial);
  const commonProps = {
    setCurrentStep
  };

  let content = null;
  switch (currentStep) {
    case Steps.Initial:
      content = <span
        className={styles.link}
        onClick={() => setCurrentStep(Steps.EnterCurrentPassword)}>
          {t('settings.change_password')}
      </span>;
      break;
    case Steps.EnterCurrentPassword:
      content = <EnterCurrentPassword {...commonProps} />;
      break;
    case Steps.EnterNewPassword:
      content = <EnterNewPassword {...commonProps} />;
      break;
  }

  return <div className={styles.ChangePassword}>
    {content}
  </div>
}

export const ChangePassword = withTranslation()(_ChangePassword);