import i18next from "i18next";
import moment from "moment";
import { ILicense, LicenseStatus } from "../../../core/api/interfaces";
import { getDatesDiff } from "../../../common/dateUtils";

export const getLicenseDetailsList = (t: i18next.TFunction, license: ILicense) => {
  const {type, status, validTo, activatedBy, nodeOwnerAddress, comment} = license;
  let expirationTime = t('license_expiration.unlimited');
  if (validTo) {
    expirationTime = moment(validTo).format(t('date_format'));
    const diff = getDatesDiff(validTo);
    if (diff < 30 && status !== LicenseStatus.expired) {
      expirationTime += '. '
        + t("company_card.licenses.expire_days_left", { count: diff })
        + '.'
    }
  }
  return [
    {
      key: t('license_details.license_type'),
      value: t(`license_types.${type}`)
    }, {
      key: t('license_details.license_status'),
      value: t(`license_status.${status}`)
    }, {
      key: t('license_details.expiration'),
      value: expirationTime,
      viewType: 'multiple'
    }, {
      key: t('license_details.activated_by'),
      value: activatedBy,
      viewType: 'multiple'
    }, {
      key: t('license_details.node_owner_address'),
      value: nodeOwnerAddress,
      viewType: 'multiple'
    }, {
      key: t('license_details.comment'),
      value: comment,
      viewType: 'multiple'
    }
  ];
}
