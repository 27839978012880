import React, { useState } from "react";
import { IUser, UserRole, UserStatus } from "../../../../../core/api/interfaces";
import { Form, Input } from "@wavesenterprise/uikit";
import { ICreateLicenseUser } from "./interfaces";
import styles from "./Users.module.scss";
import { SearchInput } from "../../../../../common/search-input-refactor/SearchInput";
import { withTranslation, WithTranslation } from "react-i18next";
import { Api } from "../../../../../core/api";
import { withApi } from "../../../../../core/api/withApi";
import SelectCompany from "../../../users/createNewUser/SelectCompany";

const debounceTimeout = 350;

enum EditMode {
  create = 'create',
  update = 'update'
}

export interface IUserEditProps extends WithTranslation {
  api: Api;
  form: any;
  companyId?: string;
  users?: ICreateLicenseUser[];
  data: {
    user: any;
  };
  isUpdating?: boolean;
  inputDisabled?: boolean;
  onSelectCompany: Function;
}

const defaultUser = {login: '', firstName: '', lastName: '', position: '', phone: '', comment: ''}

function UserEditing(props: IUserEditProps) {
  const {
    t, api, form: { getFieldDecorator },
    users = [],
    companyId,
    // data: {
    //   user: {login, firstName, lastName, position, phone, comment}
    // },
    inputDisabled = false,
    isUpdating = false,
  } = props;

  const data = props.data || { user: defaultUser }
  const {login, firstName, lastName, position, phone, comment} = data.user

  const editMode = isUpdating ? EditMode.update : EditMode.create

  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loadedUsers, setLoadedUsers] = useState<IUser[]>([]);
  const [timerId, setTimerId] = useState();
  const [initialValues, setInitialValues] = useState({ firstName, lastName, position, phone, comment });

  const validateLogin = (rule: any, value: string, cb: Function) => {
    const isUserExisted = users.find((data: ICreateLicenseUser) => data.user.login === value);
    if (isUserExisted) {
      cb(t('company_card.users.user_existed'))
    }
    cb()
  };

  const commonProps = {
    disabled: inputDisabled
  };

  const searchUsers = async (search: string) => {
    try {
      setSearchInProgress(true);
      const status = Object.values(UserStatus);
      const roles = Object.values(UserRole);
      const params = { status, roles, limit: 30, search, offset: 0 };
      const { users } = await api!.getAuthUsers(params);
      setLoadedUsers(users);
    } catch (e) {
      console.error('Load users error', e)
    } finally {
      setSearchInProgress(false);
    }
  };

  const onChangeSearchInput = async (value: string) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (value === searchValue) {
      return
    }
    setSearchValue(value);
    if (!value) {
      setLoadedUsers([]);
      return
    }

    const id = setTimeout(() => searchUsers(value), debounceTimeout);
    setTimerId(id);
    setLoadedUsers([]);
  };

  const onSelectItem = async (item: {id: string, value: string}) => {
    const {id} = item;
    try {
      const user = await api!.getUserById(id);
      const { firstName, lastName, position, phone, comment } = user;
      setInitialValues({firstName, lastName, position, phone, comment})
    } catch (e) {
      console.log('cannot get user', e)
    }
  };

  const items = loadedUsers.map((user: IUser) => {
    const {id, name} = user;
    return {
      id,
      value: name
    }
  });

  const searchProps = {
    placeholder: t('user_form.placeholder_login'),
    items,
    isLoading: searchInProgress,
    onChange: onChangeSearchInput,
    onSelectItem
  };

  const onSelectCompanyId = (id: string) => {
    if (props.onSelectCompany) {
      props.onSelectCompany(id)
    }
  }

  return <Form>
      {editMode === EditMode.create &&
        <Form.Item>
            <div>
                <span className={styles.Bold}>{}</span>
              {getFieldDecorator('login', {
                validateFirst: true,
                initialValue: login || '',
                validateTrigger: 'onBlur',
                rules: [{
                  required: true,
                  message: t('required_field')
                }, {
                  type: 'email',
                  message: t('user_form.error_email')
                }, {
                  validator: validateLogin
                }]
              })(<SearchInput {...searchProps} />)}
            </div>
        </Form.Item>
      }
      {!companyId &&
        <Form.Item>
            <SelectCompany onChangeItemId={onSelectCompanyId} />
        </Form.Item>
      }
      <Form.Item>
        <div>
          {getFieldDecorator('firstName', {
            validateFirst: true,
            initialValue: initialValues.firstName || '',
            rules: [
              {
                required: true,
                message: t('required_field')
              }
            ],
            validateTrigger: 'onBlur'
          })(<Input {...commonProps} label={t('user_form.first_name')} />)}
        </div>
      </Form.Item>
      <Form.Item>
        <div>
          {getFieldDecorator('lastName', {
            validateFirst: true,
            initialValue: initialValues.lastName || '',
            rules: [
              {
                required: true,
                message: t('required_field')
              }
            ],
            validateTrigger: 'onBlur'
          })(<Input {...commonProps} label={t('user_form.last_name')} />)}
        </div>
      </Form.Item>
      <Form.Item>
        <div>
          {getFieldDecorator('position', {
            validateFirst: true,
            initialValue: initialValues.position || '',
            rules: [
              {
                required: true,
                message: t('required_field')
              }
            ],
            validateTrigger: 'onBlur'
          })(<Input {...commonProps} label={t('user_form.job_title')} />)}
        </div>
      </Form.Item>
      <Form.Item>
        <div>
          {getFieldDecorator('phone', {
            validateFirst: true,
            initialValue: initialValues.phone || '',
            rules: [{
              required: true,
              message: t('required_field')
            }],
            validateTrigger: 'onBlur'
          })(<Input {...commonProps} label={t('user_form.phone_number')} />)}
        </div>
      </Form.Item>
      <Form.Item>
        <div>
          {getFieldDecorator('comment', {
            initialValue: initialValues.comment || ''
          })(<Input.TextArea
            {...commonProps}
            className="v-input"
            placeholder={t('user_form.comment')}
            autosize={{ minRows: 4 }}
          />)}
        </div>
      </Form.Item>
    </Form>
}

export default withApi(withTranslation()(UserEditing))
