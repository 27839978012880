import { Icon, Menu } from '@wavesenterprise/uikit';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, withRoute } from 'react-router5';
import { Router, State } from 'router5';
import { IStore } from '../../../app/stores';
import { IAuthStore } from '../../core/authorization/stores/AuthStore';
import { RouteNames } from '../../core/routing';
import styles from './NavigationMenu.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps extends WithTranslation {
  route: State;
  router: Router;
  authStore?: IAuthStore;
}

@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _NavigationMenu extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const routeName = props.route.name;
    if (routeName.startsWith(RouteNames.companies)
      || routeName.startsWith(RouteNames.users)
      || routeName.startsWith(RouteNames.settings))
    {
      this.selectedMenuItemKey = routeName;
      return;
    }
  }

  @observable
  selectedMenuItemKey: string = RouteNames.companies;

  selectMenuItem = (menuItemKey: string) =>
    action(() => {
      this.selectedMenuItemKey = menuItemKey;
    });

  logout = () => {
    this.props.router.navigate(RouteNames.auth);
  };

  render() {
    const { t, authStore } = this.props;
    const isAdmin = authStore?.isLicenseAdmin()
    const menuItems = [
      <Menu.Item key={RouteNames.companies}>
        <Icon type={'server'} />
        <Link
          routeName={RouteNames.companies}
          onClick={this.selectMenuItem(RouteNames.companies)}
        >
          <span>{t('menu.companies')}</span>
        </Link>
      </Menu.Item>,
      <Menu.Item key={RouteNames.settings}>
        <Icon type={'settings'} />
        <Link
          routeName={RouteNames.settings}
          onClick={this.selectMenuItem(RouteNames.settings)}
        >
          <span>{t('menu.settings')}</span>
        </Link>
      </Menu.Item>,
      <Menu.Item key={'empty-menu-item'} className={styles.EmptyMenuItem}>
      </Menu.Item>,
      <Menu.Item key={RouteNames.auth}>
        <Icon type={'exit'} />
        <Link
          routeName={RouteNames.auth}
          onClick={this.props.authStore!.logout}
        >
          <span>{t('menu.logout')}</span>
        </Link>
      </Menu.Item>
    ]
    if (isAdmin) {
      const itemUsers = <Menu.Item key={RouteNames.users}>
        <Icon type={'user'} />
        <Link
          routeName={RouteNames.users}
          onClick={this.selectMenuItem(RouteNames.users)}
        >
          <span>{t('menu.users')}</span>
        </Link>
      </Menu.Item>
      menuItems.splice(1, 0, itemUsers)
    }
    return (
      <div className={styles.NavigationMenu}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[this.selectedMenuItemKey]}
          selectedKeys={[this.selectedMenuItemKey]}
          className={styles.menu}
        >
          {menuItems}
        </Menu>
      </div>
    );
  }
}

const TranslatedMenu = withTranslation()(_NavigationMenu);
export const NavigationMenu = withRoute(({ route, router }) => (
  <TranslatedMenu route={route} router={router} />
));
