import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/ru';
import common_en from '../../../translations/en/common.json';
import common_ru from '../../../translations/ru/common.json';

export const DefaultLocale = 'ru';
export const DefaultNamespace = 'common';
export const getAvailableLocales = () => {
  return [
    { lang: 'ru', icon: 'rus', alias: 'Русский' },
    { lang: 'en', icon: 'eng', alias: 'English' }
  ];
};

export const set3rdPartyLocale = (lang: string) => {
  if (lang.includes('-')) {
    lang = lang.split('-')[0];
  }
  moment.locale(lang);
};

export default () => {
  i18n.use(LanguageDetector).init(
    {
      interpolation: { escapeValue: false },
      lng: DefaultLocale,
      fallbackLng: DefaultLocale,
      defaultNS: DefaultNamespace,
      resources: {
        en: { common: common_en },
        ru: { common: common_ru }
      }
    },
    err => {
      if (err === null) {
        set3rdPartyLocale(i18n.language);
      }
    }
  );
};
