import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Collapse, NotificationAPI, SpinContainer } from '@wavesenterprise/uikit';
import { IApi, ICompanyUser } from "../../../../../core/api/interfaces";
import { withApi } from "../../../../../core/api/withApi";
import { action, computed, observable } from "mobx";
import styles from './Users.module.scss'
import { ICreateLicenseUser } from './interfaces'
import { User } from './User'
import { IStore } from "../../../../../../app/stores";
import { IModalsStore, ModalNameChoices } from "../../../../stores/ModalStore";
import { IAuthStore } from "../../../../../core/authorization/stores/AuthStore";

interface ICompanyUsersProps extends WithTranslation {
  authStore?: IAuthStore;
  api?: IApi;
  modalsStore?: IModalsStore;
  companyId: string;
}

interface IUsersListProps {
  users: ICreateLicenseUser[]
}

function UsersLoading () {
  return <div>
    <SpinContainer size={'medium'} />
  </div>
}

function UsersList (props: IUsersListProps) {
  const { users } = props;
  return <div className={styles.usersList}>
    {users.map((userData: ICreateLicenseUser) => {
      const {user: { login }} = userData;
      return <div className={styles.usersListRow} key={login}>
        <div className={styles.usersListUsername}>{login}</div>
      </div>
    })}
  </div>
}

@withApi
@inject(({ authStore, modalsStore }: IStore) => ({ authStore, modalsStore }))
@observer
class _CompanyUsers extends Component<ICompanyUsersProps> {
  @observable users: ICreateLicenseUser[] = [];
  @observable collapseActiveKeys: string[] = [];
  @observable isLoading = false;

  componentWillUnmount() {
    NotificationAPI.destroy()
  }

  @computed get isAnyUserEditing () {
    return !!this.users.find(user => user.isEditing)
  }

  @computed get isUsersEmpty () {
    return this.users.length === 0
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = async () => {
    const { t, api, companyId } = this.props;
    try {
      this.setLoading(true);
      const users = await api!.getCompanyUsers(companyId);
      const formattedUsers = users.map((user: ICompanyUser) => {
        return {
          alias: user.login,
          isEditing: false,
          user: {
            ...user
          }
        }
      });
      this.addUsers(formattedUsers);
    } catch (e) {
      NotificationAPI.error({
        message: t('company_card.users.loading_error')
      })
    } finally {
      this.setLoading(false)
    }
  };

  @action
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  addUsers = (users: ICreateLicenseUser[]) => {
    this.users.push(...users);
  };

  @action
  removeUser = (id: string) => {
    const targetUser = this.users.find((data: ICreateLicenseUser) => data.user.id === id);
    if (targetUser) {
      this.users = this.users.filter((data: ICreateLicenseUser) => data.user.id !== id);
    } else {
      console.error(`Cannot remove user with id: ${id}, users list: `, this.users)
    }
  };

  @action
  addUser = (user: ICompanyUser, index?: number) => {
    const userListObject = {
      alias: user.login,
      isEditing: false,
      user
    };

    if (index !== undefined) {
      this.users.splice(index, 0, userListObject);
    } else {
      this.users.push(userListObject);
    }

    this.cancelEdit()
  };

  @action
  updateUser = (user: ICompanyUser) => {
    this.users = this.users.map(userItem => {
      if (userItem.user.id !== user.id) {
        return userItem
      }
      return {
        ...userItem,
        user: {
          ...userItem.user,
          ...user
        }
      }
    })
  }

  @action
  cancelEdit = () => {
    this.users = this.users.filter(user => !user.isEditing)
  };

  @action
  showUserDetails = (userId: string) => {
    const {companyId: id} = this.props;
    this.props.modalsStore!.showModal(ModalNameChoices.userDetails, {id, userId})
  };

  getDefaultUser = () => {
    const {t} = this.props;
    return {
      isEditing: true,
      alias: t('company_card.users.new_user_title'),
      user: {
        id: '',
        login: '',
        firstName: '',
        lastName: '',
        position: '',
        phone: '',
        comment: '',
        createdAt: ''
      }
    }
  };

  @action
  onAddUserClicked = () => {
    this.users.push(this.getDefaultUser());
    this.addCollapseKey((this.users.length - 1).toString());
  };

  @action
  handleDeleteUser(userIndex: number) {
    this.users = this.users.filter((_, index) => index !== userIndex);
    this.removeCollapseKey(userIndex.toString());
  }

  @action
  addCollapseKey = (collapseKey: string) => {
    if (!this.collapseActiveKeys.includes(collapseKey)) {
      this.collapseActiveKeys.push(collapseKey);
    }
  };

  @action
  removeCollapseKey = (keyToRemove: string) => {
    this.collapseActiveKeys = this.collapseActiveKeys
      .filter(key => key !== keyToRemove)
      .map(key => (key < keyToRemove ? key : (parseInt(key) - 1).toString()));
  };

  @action
  onCollapseChange = (activeKeys: string[]) => {
    this.collapseActiveKeys = activeKeys;
  };

  render() {
    const {t, authStore, companyId} = this.props;
    if (this.isLoading) {
      return <UsersLoading />
    }
    const isLicenseAdmin = authStore!.isLicenseAdmin();
    return <div className={styles.Users}>
      <div>
        {this.users.length === 0
          ? <div className={styles.Bold}>
            {t('company_card.users.no_users')}
          </div>
          : isLicenseAdmin ? (<Collapse
              className={styles.Collapse}
              activeKey={this.collapseActiveKeys}
              onChange={this.onCollapseChange}>
              {this.users.map((user: ICreateLicenseUser, userIndex: number) => {
                const userProps = {
                  t,
                  companyId,
                  data: user,
                  users: this.users,
                  addUser: this.addUser,
                  updateUser: this.updateUser,
                  removeUser: this.removeUser,
                  cancelEdit: this.cancelEdit,
                  showUserDetails: this.showUserDetails
                };
                return (
                  <Collapse.Panel key={userIndex} header={user.alias}>
                    {authStore!.isLicenseAdmin() &&
                      <User {...userProps} />
                    }
                  </Collapse.Panel>
                );
              })}
            </Collapse>)
            : <UsersList users={this.users} />
        }
      </div>
      {authStore!.isLicenseAdmin() &&
        <Button
            disabled={this.isAnyUserEditing}
            className={this.isUsersEmpty ? styles.ButtonGreen : ''}
            onClick={this.onAddUserClicked}>
          {t('company_card.users.add_user')}
        </Button>
      }
    </div>
  }
}

export const CompanyUsers = withTranslation()(_CompanyUsers);
