import React, { useState } from "react";
import {
  Form, NotificationAPI, SpinContainer
} from '@wavesenterprise/uikit';
import styles from './Users.module.scss'
import { IUserProps} from './interfaces'
import { withApi } from "../../../../../core/api/withApi";
import UserEditing from './UserEditing'
import UserInfo from './UserInfo'
import { withTranslation } from "react-i18next";

function _User(props: IUserProps) {
  const {
    t, api, data: { isEditing, user: { id: userId } },
    form: { validateFields },
    companyId,
    cancelEdit,
    updateUser,
    addUser
  } = props
  const [isUpdating, setIsUpdating] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const onCancelClicked = () => {
    if (isUpdating) {
      setIsUpdating(false)
    } else {
      cancelEdit()
    }
  }

  const onSaveClicked = async () => {
    try {
      setInProgress(true)
      const userFields = await validateFields({force: true})
      if (isUpdating) {
        const updatedUser = await api!.updateCompanyUser(userId, userFields);
        updateUser(updatedUser)
        setIsUpdating(false)
        NotificationAPI.success({
          message: t('user_details.update_success')
        })
      } else {
        const companyUser = await api!.addCompanyUser(companyId, userFields);
        addUser(companyUser);
      }
    } catch (e) {
      if (!e.errors) { // Ignore error from form validator
        console.log('User save error:', e);
        NotificationAPI.error({
          message: t('company_card.users.error_add_user')
        })
      }
    } finally {
      setInProgress(false)
    }
  };

  const contentProps = {
    ...props,
    inputDisabled: inProgress,
    isUpdating,
    setIsUpdating,
    users: props.users
  }

  const content = (isEditing || isUpdating)
    ? <div>
      <UserEditing {...contentProps} />
      <div className={styles.Controls}>
        {inProgress
          ? (<div>
            <div className={styles.ControlDisabled}>{t('company_card.users.user_save_in_progress')}</div>
            <div className={styles.ControlDisabled}><SpinContainer size={'small'} /></div>
          </div>)
          : (<div>
            <div className={styles.Control} onClick={onCancelClicked}>{t('buttons.cancel')}</div>
            <div className={styles.Control} onClick={onSaveClicked}>{t('buttons.save')}</div>
          </div>)
        }
      </div>
    </div>
    : <UserInfo {...contentProps} />;
  return <div className={styles.User}>
    {content}
  </div>
}

export const User = withApi(withTranslation()(Form.create()(_User)));
