import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Form, Input, Button, NotificationAPI } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { IAuthStore } from '../stores/AuthStore';
import { RouteNameChoices, RouteNames } from "../../routing";
import { Link } from "react-router5";
import commonStyles from "../Authorization.module.scss";
import styles from './RestoreScreen.module.scss';
import { AuthError } from "../../api/error-codes";

interface IProps {
  t: i18n.TFunction;
  authStore?: IAuthStore;
  api?: IApi;
  form: any; // todo we does not have type yet
}

const FormItem = Form.Item;

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
class _TypeRestoreEmail extends Component<IProps> {
  onSubmit = async () => {
    const {t, api} = this.props;
    try {
      const {email} = await this.props.form.validateFields({force: true});
      await api!.restorePassword({email});
      NotificationAPI.success({
        message: t('auth.restore.send_success.message'),
        description: t('auth.restore.send_success.description', {email})
      })
    } catch (e) {
      const {response} = e;
      if (response) {
        const {data} = response;
        if (data.errors === AuthError.UserNotFound) {
          NotificationAPI.error({
            message: t('auth.restore.error_email_not_found')
          })
        } else {
          NotificationAPI.error({
            message: t('auth.restore.common_error')
          })
        }
      }
    }
  };

  render() {
    const {
      t,
      form: { getFieldDecorator }
    } = this.props;
    return (
      <div className={styles.RestoreScreen}>
        <div className={commonStyles.title}>{t('auth.title')}</div>
        <span>{t('auth.restore.description')}</span>
        <div className={commonStyles.block32}>
          <Form>
            <FormItem>
              {getFieldDecorator('email', {
                validateFirst: true,
                // initialValue: '',
                rules: [
                  {
                    required: true,
                    message: t('auth.email_required')
                  },
                  {
                    type: 'email',
                    message: t('auth.email_incorrect')
                  }
                ],
                validateTrigger: 'onBlur'
              })(<Input placeholder={t('auth.email_placeholder')} onPressEnter={this.onSubmit} />)}
            </FormItem>
            <FormItem>
              <Button onClick={this.onSubmit} type="primary">
                {t('auth.restore.send')}
              </Button>
            </FormItem>
            <Form.Item>
              <Link routeName={RouteNames[RouteNameChoices.login]}>
                <span className={commonStyles.link}>{t('auth.return')}</span>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const TypeRestoreEmail = withTranslation()(Form.create()(_TypeRestoreEmail));
