import React from "react";
import { IListItem, IOptionsListProps } from "./interfaces";
import styles from "./SearchInput.module.scss";

export const OptionsList = (props: IOptionsListProps) => {
  const {items, activeItemId, onItemClick, setActiveItemId} = props;
  return <div className={styles.OptionsList}>
    {items.map((item: IListItem) => {
      const { id, value } = item;
      const isActiveItem = activeItemId === id;
      const className = isActiveItem ? styles.ListItemActive : styles.ListItem;
      const itemProps = {
        className,
        key: id,
        onClick: onItemClick ? () => onItemClick(item) : undefined,
        onMouseEnter: setActiveItemId ? () => setActiveItemId(id): undefined,
        onMouseLeave: setActiveItemId ? () => setActiveItemId(null): undefined,
      };
      return <div {...itemProps}>
        {<span className={styles.ListItemValue}>
          {value}
        </span>
      }</div>
    })}</div>
};
