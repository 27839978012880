import React, { useState } from "react";
import { Button, Form, NotificationAPI } from "@wavesenterprise/uikit";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from './CreateUserForm.module.scss'
import { withApi } from "../../../../core/api/withApi";
import { Api } from "../../../../core/api";
import FixedButtonsContainer from "../../../../common/fixed-buttons-container/FixedButtonsContainer";
import UserEditing from "../../companyDetails/tabs/users/UserEditing";
import { inject } from "mobx-react";
import { IModalsStore, ModalNameChoices } from "../../../stores/ModalStore";

interface CreateUserFormProps extends WithTranslation {
  api: Api;
  id: string;
  form: any;
  modalsStore: IModalsStore;
}

function CreateUserForm (props: CreateUserFormProps) {
  const { t, api, form, modalsStore } = props

  const [companyId, setCompanyId] = useState('')

  const onSaveClicked = async () => {
    const profile = await form.validateFields({force: true})
    if (companyId) {
      try {
        const createdProfile = await api!.addCompanyUser(companyId, profile);
        const key = 'profile_created_success'
        NotificationAPI.success({
          key,
          message: t('users.create_user.success_message'),
          btn: <div>
            <Button onClick={() => {
              NotificationAPI.close(key)
              modalsStore.showModal(ModalNameChoices.userDetails, { userId: createdProfile.id })
            }}>{t('users.create_user.open_profile')}</Button>
          </div>
        })
      } catch (e) {
        console.error('Cannot add user:', e.message)
        let description = ''
        if (e.response.data.message.includes('user is linked')) {
          description = t('users.create_user.user_is_already_linked')
        }
        NotificationAPI.error({
          message: t('users.create_user.fail_message'),
          description
        })
      }
    } else {
      NotificationAPI.warning({
        message: t('users.create_user.select_company')
      })
    }
  }

  const onSelectCompany = (id: string) => {
    setCompanyId(id)
  }

  const userEditProps = {
    isUpdating: false,
    form: props.form,
    companyId: null,
    onSelectCompany
  }

  return <div className={styles.Container}>
    <FixedButtonsContainer>
      <Button type={'primary'} onClick={onSaveClicked}>
        {t('users.create_user.add_user_button')}
      </Button>
    </FixedButtonsContainer>
    <div>
      <UserEditing {...userEditProps} />
    </div>
  </div>
}

export default inject('modalsStore')(withApi(withTranslation()(Form.create()(CreateUserForm))))

