import React, { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { SidePanel, SpinContainer, Tabs } from "@wavesenterprise/uikit";
import { withApi } from "../../../../core/api/withApi";
import { IApi, ICompanyUser } from "../../../../core/api/interfaces";
import UserEdit from "./UserEdit";
import styles from './UserDetails.module.scss'
import UserCompanies from "./UserCompanies";

interface UserDetailsProps extends WithTranslation {
  id: string;
  onCancel: Function;
  api: IApi
}

function _UserDetails (props: UserDetailsProps) {
  const { t, api, id, onCancel } = props

  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState<ICompanyUser | null>(null)

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        setLoading(true)
        const data = await api.getUserById(id)
        setUser(data)
      } catch (e) {

      } finally {
        setLoading(false)
      }
    }
    loadUserDetails()
  }, [])

  const sidePanelProps = {
    view: 'sticky',
    visible: true,
    icon: 't-user',
    mainTitle: t('users.details_title', { email: user ? user.login : '' }),
    subTitle: t('users.details_subtitle'),
    onCancel
  };

  return <SidePanel {...sidePanelProps}>
    {isLoading &&
      <SpinContainer size={'large'} />
    }
    <Tabs type={'card'}>
      <Tabs.Pane tab={t('users.tab_info')} key="1">
        <div className={styles.TabInfoWrapper}>
          {!isLoading && user &&
            <UserEdit id={id} user={user} />
          }
        </div>
      </Tabs.Pane>
      <Tabs.Pane tab={t('users.tab_companies')} key="2">
        {!isLoading && user &&
          <UserCompanies user={user} />
        }
      </Tabs.Pane>
    </Tabs>
  </SidePanel>
}

export const UserDetails = withApi(withTranslation()(_UserDetails))
