import { RouteNameChoices, RouteNames } from "../core/routing";
import { ModalNameChoices } from "./stores/ModalStore";

export const getModalNameByRoute = (routeName: string) => {
  let modalName = null;
  if (routeName.startsWith(RouteNames[RouteNameChoices.createCompany])) {
    modalName = ModalNameChoices.createCompany;
  } else if (routeName === RouteNames[RouteNameChoices.licenseDetails]) {
    modalName = ModalNameChoices.licenseDetails;
  } else if (routeName.startsWith(RouteNames[RouteNameChoices.licenseActivate])) {
    modalName = ModalNameChoices.licenseActivate;
  } else if (routeName === RouteNames[RouteNameChoices.userDetails]) {
    modalName = ModalNameChoices.userDetails;
  } else if (routeName.startsWith(RouteNames[RouteNameChoices.companyDetails])) {
    modalName = ModalNameChoices.companyDetails;
  }
  return modalName;
};

export const getRouteByModalName = (route: string) => {

};