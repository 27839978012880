import { IApi, ILicense } from "../../../core/api/interfaces";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, SidePanelDetailsList, NotificationAPI } from '@wavesenterprise/uikit';
import InfoMessage from "../../../common/info-message";
import React from "react";
import styles from './LicenseCancel.module.scss'
import { withApi } from "../../../core/api/withApi";

interface IProps extends WithTranslation {
  api?: IApi;
  license: ILicense;
  company: any;
  onCloseClicked: () => void
  onContinueClicked: (license: ILicense) => void
}

function _CheckStep (props: IProps) {
  const {t, api, license: {
    id: licenseId, type, product
  }, company: {
    id: companyId, shortName
  }, onCloseClicked, onContinueClicked} = props;
  const items = [{
    key: t('license_cancel.company'),
    value: shortName
  }, {
    key: t('license_cancel.product'),
    value: t(`products.${product}`)
  }, {
    key: t('license_cancel.type'),
    value: t(`license_types.${type}`),
    viewType: 'multiple'
  }];

  const cancelLicense = async () => {
    try {
      const cancelledLicense = await api!.cancelLicense({licenseId, companyId});
      onContinueClicked(cancelledLicense)
    } catch (e) {
      NotificationAPI.error({
        message: t('license_cancel.common_error')
      })
    }
  };

  return <div>
    <div className={styles.Buttons}>
      <Button type={'primary'} onClick={cancelLicense}>{t('buttons.continue')}</Button>
      <Button onClick={onCloseClicked}>{t('buttons.cancel')}</Button>
    </div>
    <div className={styles.InfoMessage}>
      <InfoMessage iconType={'warning'} text={t('license_cancel.warning_message')} />
    </div>
    <div className={styles.CompanyInfo}>
      <SidePanelDetailsList items={items} />
    </div>
  </div>
}

export const CheckStep = withApi(withTranslation()(_CheckStep));
