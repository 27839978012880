import { Icon } from '@wavesenterprise/uikit';
import React from 'react';
import styles from './info-message.module.scss';

interface IInfoMessageProps {
  iconType: 'done' | 'info' | 'warning';
  text: string;
}

export default function InfoMessage(props: IInfoMessageProps) {
  const { iconType, text } = props;
  return (
    <div className={styles.InfoMessage}>
      <Icon type={iconType} className={styles[iconType]} />
      <div className={styles.content}>{text}</div>
    </div>
  );
}
