import React, { Component } from 'react';
import { action, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  SidePanel,
  SpinContainer,
  Steps
} from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IAuthStore } from '../../../core/authorization/stores/AuthStore';
import { IModalsStore } from '../../stores/ModalStore';
import {
  IApi, ICompanyBasic, ILicense
} from '../../../core/api/interfaces';
import { withApi } from '../../../core/api/withApi';
import { Router } from "router5";
import styles from './LicenseCancel.module.scss'
import { CheckStep } from "./CheckStep";
import { ResultStep } from "./ResultStep";

interface IProps extends WithTranslation {
  router: Router;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  api?: IApi;
  visible: boolean;
  onCancel: () => void;
}

enum StepChoices {
  check = 0,
  result = 1
}


@withApi
@inject(({ modalsStore, authStore }: IStore) => ({ modalsStore, authStore }))
@observer
class _LicenseCancel extends Component<IProps> {
  @observable isLoading = false;
  @observable license: ILicense | null = null;
  @observable company: ICompanyBasic | null = null;
  @observable currentStep = StepChoices.check;
  @observable nodeOwnerAddress = null;

  commonSidePanelProps = {
    visible: true,
    theme: 'black',
    icon: 'node-remove-32',
    onCancel: this.props.onCancel
  };

  @computed get sidePanelProps () {
    const {t} = this.props;
    const mainTitle = t(`license_cancel.title`);
    const subTitle = this.company ? this.company.shortName : t('license_cancel.sub_title');
    return {
      ...this.commonSidePanelProps,
      mainTitle,
      subTitle
    };
  }

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {
    this.setIsLoading(true);
    const {api, router} = this.props;
    const {params: {id: companyId, licenseId}} = router.getState();
    try {
      const license = await api!.getLicenseById(companyId, licenseId);
      const company = await api!.getCompanyById(companyId);
      this.setLicense(license);
      this.setCompany(company);
    } catch (e) {

    } finally {
      this.setIsLoading(false);
    }
  };

  @action
  setLicense = (license: any) => {
    this.license = license;
  };

  @action
  setCompany = (company: any) => {
    this.company = company;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setCurrentStep = (step: StepChoices) => {
    this.currentStep = step;
  };

  onContinueClicked = (cancelledLicense: ILicense) => {
    this.setLicense(cancelledLicense);
    this.setCurrentStep(StepChoices.result)
  };

  onCloseClicked = () => this.props.onCancel();

  getStepsConfig = () => {
    const {t} = this.props;
    return [{
      key: StepChoices.check,
      title: t('steps.check'),
      icon: 'eye'
    }, {
      key: StepChoices.result,
      title: t('steps.cancel'),
      icon: 'closelease'
    }]
  };

  getContent = () => {
    const {t} = this.props;
    if (this.company && this.license) {
      const stepProps = {
        license: this.license,
        company: this.company,
        onCloseClicked: this.onCloseClicked,
        onContinueClicked: this.onContinueClicked
      };
      if (this.currentStep === StepChoices.check) {
        return <CheckStep {...stepProps} />
      } else if (this.currentStep === StepChoices.result) {
        return <ResultStep {...stepProps} />
      }
      return null;
    } else if (this.isLoading) {
      return <SpinContainer size={'medium'} />
    } else {
      return <div className={styles.Bold}>{t('license_details.error_on_loading')}</div>
    }
  };

  render() {
    return (
      <SidePanel {...this.sidePanelProps}>
        <div className={styles.Content}>
          <div className={styles.Info}>
            {this.getContent()}
          </div>
          <div className={styles.Steps}>
            <Steps current={this.currentStep}>
              {this.getStepsConfig()}
            </Steps>
          </div>
        </div>
      </SidePanel>
    );
  }
}

export const LicenseCancel = withTranslation()(_LicenseCancel);
