import { NotificationAPI } from "@wavesenterprise/uikit";
import i18next from "i18next";
import { observable } from "mobx";
import { actionAsync, task } from "mobx-utils";
import { IApi, IGetConfigResponse } from "../../api/interfaces";

export interface IConfigStore {
  getConfig(): IGetConfigResponse | null;
  fetchConfig(): void;
  isLoading(): boolean;
}

export class ConfigStore implements IConfigStore {
  private readonly api: IApi;

  @observable.ref
  private config: IGetConfigResponse | null;

  @observable
  private loading: boolean = false;

  constructor(deps: { api: IApi }) {
    const { api } = deps;
    this.api = api;
    this.config = null;
  }

  public getConfig = () => {
    return this.config;
  };

  public isLoading = () => {
    return this.loading;
  };

  @actionAsync
  public fetchConfig = async () => {
    const t = i18next.getFixedT(i18next.language, 'common');
    this.loading = true;
    try {
      this.config = await task(this.api.getConfig());
    } catch (e) {
      this.config = null;
      NotificationAPI.error({
        message: t('config_loading_error')
      });
      throw e;
    }
    this.loading = false;
  };
}
