import { NotificationAPI, SpinContainer } from '@wavesenterprise/uikit';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IApi, ICompany, ILicense, LicenseStatus } from '../../../../../core/api/interfaces';
import { withApi } from '../../../../../core/api/withApi';
import { action, computed, observable, runInAction } from 'mobx';
import { LicenseFilters } from './LicensesFilters';
import LicensesList from './LicensesList';
import { FilterType, LicenseActivationStatus, LicensePeriod, LicenseType } from '../../../../constants';
import styles from './Licenses.module.scss';
import i18next from 'i18next';
import { actionAsync, task } from 'mobx-utils';
import { RouteNameChoices, RouteNames } from '../../../../../core/routing';
import { Router, Subscription } from 'router5';
import { Unsubscribe } from 'router5/types/types/base';

interface IProps extends WithTranslation {
  api?: IApi;
  companyId: string;
  router: Router,
}

export type LicenseStatusChoice = LicenseActivationStatus | 'all';
export type LicenseTypeChoice = LicenseType | 'all';
export type LicensePeriodChoice = LicensePeriod | 'all';

export interface IRouteParams {
  status?: LicenseStatusChoice;
  type?: LicenseTypeChoice;
  period?: LicensePeriodChoice;
  search?: string;
  tag?: string;
}

@withApi
@observer
class _CompanyLicenses extends Component<IProps> {
  private _typingTimerId: number | null = null;
  unsubscribe: Unsubscribe | Subscription | null = null;

  @observable isLoading = false;
  @observable licenses = [];
  @observable filterType: any = LicenseType.all;
  @observable filterActivationStatus: any = LicenseActivationStatus.all;
  @observable filterPeriod: any = LicensePeriod.all;
  @observable filterSearch: any = undefined;
  @observable filterTag: any = undefined;
  @observable company: ICompany | null = null;

  constructor (props: IProps) {
    super(props);
    this.unsubscribe = this.props.router.subscribe(state => {
      const { name: prevName } = state.previousRoute;
      if (
        state.route.name !== RouteNames[RouteNameChoices.companyLicenses] ||
        !prevName.startsWith(RouteNames[RouteNameChoices.companyLicenses])
      ) {
        return;
      }
      this.syncWithRouteParams(state.route.params);
    });

    const params = this.props.router.getState().params;
    this.syncWithRouteParams(params);
  }

  componentWillUnmount (): void {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
  }

  @actionAsync
  public syncWithRouteParams = async (routeParams: IRouteParams) => {
    const {
      type = LicenseType.all,
      status = LicenseActivationStatus.all,
      period = LicensePeriod.all,
      search = undefined,
      tag = undefined
    } = routeParams;
    this.filterActivationStatus = status;
    this.filterType = type;
    this.filterSearch = search;
    this.filterPeriod = period;
    this.filterTag = tag;
    this.licenses = [];
    await task(this._fetchLicenses());
  };

  private _fetchInitialData = async () => {
    await this._fetchCompanyData();
  };

  @actionAsync
  private _fetchCompanyData = async () => {
    const { api, companyId } = this.props;
    this.setIsLoading(true);
    try {
      const company = await api!.getCompanyById(companyId);
      runInAction(() => {
        this.company = company
      });
    } catch (e) {}
    finally {
      this.setIsLoading(false);
    }
  };

  @actionAsync
  private _fetchLicenses = async () => {
    const { api, companyId } = this.props;
    this.setIsLoading(true);
    try {
      const licenses = await api!.getCompanyLicenses(companyId, {
        ...this._mapClassPropertiesToRouteParams(),
      });
      runInAction(() => {
        this.licenses = licenses.map((license: ILicense) => {
          const { status, activatedBy } = license;
          if (status !== LicenseStatus.expiring) {
            return license;
          }
          return {
            ...license,
            status: activatedBy
              ? LicenseStatus.activated
              : LicenseStatus.available,
          };
        });
      });
    } catch (e) {
      this._showLoadingErrorNotification();
    } finally {
      this.setIsLoading(false);
    }
  };

  private _mapClassPropertiesToRouteParams = (): IRouteParams => {
    return {
      type: this.filters[FilterType.licenseType] === LicenseType.all ? undefined : this.filters[FilterType.licenseType],
      period: this.filters[FilterType.period] === LicensePeriod.all ? undefined : this.filters[FilterType.period],
      status: this.filters[FilterType.activationStatus] === LicenseActivationStatus.all ? undefined : this.filters[FilterType.activationStatus],
      search: this.filters[FilterType.search] || undefined,
      tag: this.filters[FilterType.tag] || undefined,
    };
  };

  private _showLoadingErrorNotification () {
    const t = i18next.getFixedT(i18next.language, 'common');
    // @ts-ignore
    NotificationAPI.error({
      key: 'licenses_loading_error',
      message: t('loading_errors.licenses'),
    });
  }

  @computed get isFiltersApplied () {
    const params = this._mapClassPropertiesToRouteParams()
    return Object.values(params).filter(value => typeof value !== 'undefined').length !== 0
  }

  componentDidMount () {
    this._fetchInitialData();
  }

  @computed get filters () {
    return {
      [FilterType.licenseType]: this.filterType,
      [FilterType.activationStatus]: this.filterActivationStatus,
      [FilterType.period]: this.filterPeriod,
      [FilterType.search]: this.filterSearch,
      [FilterType.tag]: this.filterTag
    };
  }

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  onFilterChanged = (filterType: FilterType, value: any) => {
    switch (filterType) {
      case FilterType.licenseType:
        this.filterType = value;
        break;
      case FilterType.activationStatus:
        this.filterActivationStatus = value;
        break;
      case FilterType.period:
        this.filterPeriod = value;
        break;
      case FilterType.tag:
        this.filterTag = value;
        break;
    }
    this.props.router.navigate(RouteNames[RouteNameChoices.companyLicenses], {
      id: this.props.companyId,
      ...this._mapClassPropertiesToRouteParams(),
    });
  };

  @action
  onSearchChanged = async (value: any) => {
    this.filterSearch = value;
    const timeout = value ? 800 : 0;
    if (this._typingTimerId !== null) {
      clearTimeout(this._typingTimerId);
    }
    this._typingTimerId = window.setTimeout(() => {
      this.props.router.navigate(RouteNames[RouteNameChoices.companyLicenses], {
        id: this.props.companyId,
        ...this._mapClassPropertiesToRouteParams(),
      });
    }, timeout);
  };

  render () {
    const { companyId } = this.props;
    const commonProps = {
      companyId,
      company: this.company,
      isFiltersApplied: this.isFiltersApplied,
      items: this.licenses
    };
    return (
      <div>
        <LicenseFilters
          {...commonProps}
          filters={this.filters}
          onFilterChanged={this.onFilterChanged}
          onSearchChanged={this.onSearchChanged}
        />
        {this.isLoading ? (
          <SpinContainer key="spinner" size="large" className={styles.LicenseListSpinner}/>
        ) : (
          <LicensesList {...commonProps}/>
        )}
      </div>
    );
  }

}

export const CompanyLicenses = withTranslation()(_CompanyLicenses);
