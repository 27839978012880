import React, { useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from "react-i18next";
import { SelectInput, Input } from "@wavesenterprise/uikit";
import { withApi } from "../../../../core/api/withApi";
import { Api } from "../../../../core/api";
import { ICompany } from "../../../../core/api/interfaces";

export interface ISuggestItem {
  id: string;
  value: string;
}

interface SelectUserCompanyProps extends WithTranslation {
  api: Api;
  existedCompanyIds?: string[];
  selectedItemId?: string;
  onAppendToCompany: (company: ICompany) => void
  onChangeItemId: (id: string) => void
}

function SelectUserCompany (props: SelectUserCompanyProps) {
  const { t, api, existedCompanyIds = [] } = props

  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])

  useEffect(() => {
    const loadCompaniesList = async () => {
      try {
        setIsLoading(true)
        const { companies: companiesList } = await api.getCompanies({ limit: 50, offset: 0 })
        const filteredList = companiesList.filter((company: ICompany) => !existedCompanyIds.includes(company.id))
        setCompanies(filteredList)
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }
    loadCompaniesList()
  }, [])

  const onChangeItemId = (id: string) => {
    if (props.onChangeItemId) {
      props.onChangeItemId(id)
    }
  }

  const linkedToAllCompanies = !isLoading && companies.length === 0 && existedCompanyIds.length > 0

  if (isLoading) {
    return <Input disabled isLoading={true} />
  }

  if (linkedToAllCompanies) {
    return <div>
      {t('users.linked_to_all_companies')}
    </div>
  }

  if (companies.length === 0) {
    return <Input disabled isLoading={true} value={t('users.no_companies_found')} />
  }

  return <SelectInput
    label={t('users.company')}
    placeholder={t('users.select_company_placeholder')}
    items={companies.map(({ id, shortName }) => {
      return {
        id,
        value: shortName
      }
    })}
    onChangeItemId={onChangeItemId}
    selectedItemId={props.selectedItemId}
  />
}

export default withApi(withTranslation()(SelectUserCompany))
