import { IListItem } from "./interfaces";

const getPrevItemId = (items: IListItem[], activeItemId: string | null) => {
  const activeItemIndex = items.findIndex((item: IListItem) => item.id === activeItemId);
  if (activeItemIndex === -1) {
    return items[items.length - 1].id;
  }
  if (activeItemIndex === 0) {
    return null;
  }
  const prevItem = items.find((item: IListItem, index: number, array: IListItem[]) => {
    if (array[index + 1] && array[index + 1].id === activeItemId) {
      return item.id
    }
  });
  return prevItem ? prevItem.id : null;
};

const getNextItemId = (items: IListItem[], activeItemId: string | null) => {
  const activeItemIndex = items.findIndex((item: IListItem) => item.id === activeItemId);
  if (activeItemIndex === -1) {
    return items[0].id
  }
  if (activeItemIndex === items.length - 1) {
    return null
  }
  const nextItem = items.find((item: IListItem, index: number) => {
    return index > activeItemIndex
  });
  return nextItem ? nextItem.id : activeItemId
};

export default {
  getPrevItemId,
  getNextItemId
}