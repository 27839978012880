import {
  Button,
  SidePanel,
  SidePanelDetailsList,
  SpinContainer,
  SelectTags,
  SelectInput,
  NotificationAPI,
  Input
} from '@wavesenterprise/uikit';
import fileDownload from "js-file-download";
import { action, computed, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Router } from "router5";
import { IStore } from '../../../../app/stores';
import {
  IApi,
  ICompany,
  ICompanyUser,
  ILicense,
  IUpdateLicenseParams,
  LicenseStatus
} from '../../../core/api/interfaces';
import { withApi } from '../../../core/api/withApi';
import { IAuthStore } from '../../../core/authorization/stores/AuthStore';
import { IModalsStore, ModalNameChoices } from '../../stores/ModalStore';
import styles from './LicenseDetails.module.scss'
import { getLicenseDetailsList } from './license.details.utils';
import InfoMessage from "../../../common/info-message";
import EnvSelect from "../../../common/env-select/EnvSelect";

interface IActionButtonProps {
  license: ILicense | null;
  companyUsers: ICompanyUser[];
  companyId: string;
  t: any;
  api?: IApi;
  authStore: IAuthStore;
  modalsStore?: IModalsStore;
}

const ActionButton = inject(({ authStore }: IStore) => ({
  authStore
}))(withApi(function ActionButton(props: IActionButtonProps) {
  const { t, api, modalsStore, authStore, license, companyUsers, companyId } = props;
  if (license === null) {
    return null;
  }
  const isCompanyMember = !!companyUsers.find((user: ICompanyUser) => user.login.toLowerCase() === authStore!.getUserName());

  const onActivateLicense = () => {
    modalsStore!.showModal(ModalNameChoices.licenseActivate, {
      id: companyId,
      licenseActivateId: license.id
    })
  };

  const onDownloadLicense = async () => {
    const file = await api!.downloadLicense({
      licenseId: license.id,
      companyId
    });
    fileDownload(JSON.stringify(file), 'node.license');
  };

  return (<div className={styles.ContentButtonsContainer}>
      {(license.status === LicenseStatus.available && isCompanyMember) &&
        <Button type="primary" className={styles.ContentButton} onClick={onActivateLicense}>
          {t('license_details.activate_license')}
        </Button>
      }
      {(license.status === LicenseStatus.activated && isCompanyMember) &&
        <Button type="primary" className={styles.ContentButton} onClick={onDownloadLicense}>
          {t('license_details.download_license')}
        </Button>
      }
    </div>)
}));

interface IProps extends WithTranslation {
  router: Router;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  api?: IApi;
  visible: boolean;
  onCancel: () => void;
}

interface ISuggestListItem {
  id: string;
  value: string;
}

@withApi
@inject(({ modalsStore, authStore }: IStore) => ({ modalsStore, authStore }))
@observer
class _LicenseDetails extends Component<IProps> {
  @observable isLoading = false;
  @observable license: ILicense | null = null;
  @observable company: ICompany | null = null;
  @observable companyUsers: ICompanyUser[] = [];
  @observable companyTags: string[] = [];
  @observable licenseTags: string[] = [];
  @observable isCompaniesListLoading: boolean = false
  @observable companiesList: ISuggestListItem[] = []
  @observable selectedCompanyId: string = ''
  @observable projectName: string = ''

  commonSidePanelProps = {
    visible: true,
    theme: 'black',
    icon: 'node-add-32',
    onCancel: this.props.onCancel
  };

  @computed get sidePanelProps () {
    const {t} = this.props;
    const license = this.license;
    const mainTitle = license ? t(`products.${license.product}`) : t('license_details.title');
    const subTitle = license ? t(`license_types.${license.type}`) : t('license_details.sub_title');
    return {
      ...this.commonSidePanelProps,
      mainTitle,
      subTitle
    };
  }

  componentDidMount() {
    this.getInitialData()
    this.getCompaniesList()
  }

  getCompaniesList = async () => {
    const { api } = this.props;
    try {
      runInAction(() => {
        this.isCompaniesListLoading = true
      })
      const { companies } = await api!.getCompanies({})
      runInAction(() => {
        this.companiesList = companies.map(company => {
          return {
            id: company.id,
            value: company.shortName
          }
        })
      })
    } catch (e) {
      runInAction(() => {
        this.companiesList = []
      })
      console.error('Cannot load companies list:', e.message)
    } finally {
      runInAction(() => {
        this.isCompaniesListLoading = false
      })
    }
  }

  getInitialData = async () => {
    this.setIsLoading(true);
    const {api, router} = this.props;
    const {params: {
      id: companyId,
      licenseId
    }} = router.getState();
    try {
      const company = await api!.getCompanyById(companyId);
      const license = await api!.getLicenseById(companyId, licenseId);
      const companyTags = await api!.getTags({companyId})
      if (license.status === LicenseStatus.expiring) {
        license.status = license.activatedBy
          ? LicenseStatus.activated
          : LicenseStatus.available
      }
      this.setLicense(license);
      this.setCompany(company);
      runInAction(() => {
        this.companyTags = companyTags
        this.companyUsers = company.users
      })
    } catch (e) {
      console.error('Cannot get initial license data', e)
    } finally {
      this.setIsLoading(false);
    }
  };

  @action
  setLicense = (license: ILicense) => {
    this.license = license;
    this.projectName = license.projectName;
  };

  @action
  setCompany = (company: ICompany) => {
    this.company = company;
    this.selectedCompanyId = company.id
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  updateLicenseTags = async (tags: string[]) => {
    const {api, router} = this.props;
    const {params: {licenseId}} = router.getState();
    try {
      await api!.setLicenseTags(licenseId, tags)
    } catch (e) {

    }
  }

  updateLicenseParams = (params: IUpdateLicenseParams) => {
    const { api } = this.props;
    return api?.updateLicense(this.company!.id, this.license!.id, params)
  }

  licenseUpdatedNotification = () => {
    const { t } = this.props
    NotificationAPI.success({
      description: t('license_details.details_updated_description')
    })
  }

  @action
  onChangeTags = (tags: string[]) => {
    this.licenseTags = tags
    this.updateLicenseTags(tags)
  }

  onSelectCompany = (id: string) => {
    const { t } = this.props;
    if (id !== this.selectedCompanyId) {
      runInAction(() => {
        this.selectedCompanyId = id
      })
      const key = 'company_transfer' + id
      const closeNotification = () => NotificationAPI.close(key)
      const cancelTransferLicense = () => {
        closeNotification()
        runInAction(() => {
          this.selectedCompanyId = this.company!.id
        })
      }
      const transferLicense = async () => {
        try {
          closeNotification();
          await this.updateLicenseParams({
            companyId: id
          })
          const targetCompany = this.companiesList.find(company => company.id === id)
          const companyName = targetCompany ? targetCompany.value : ''
          NotificationAPI.success({
            description: t('license_details.company_transfer.success_description', {
              companyName
            })
          })
        } catch (e) {
          console.error('Cannot change organization', e.message)
          NotificationAPI.error({
            message: t('license_details.company_transfer.error_message'),
            description: t('license_details.company_transfer.error_description')
          })
        } finally {
        }
      }
      NotificationAPI.warning({
        duration: 0,
        key,
        message: t('license_details.company_transfer.message'),
        description: t('license_details.company_transfer.description'),
        btn: <div>
          <Button type={'primary'} onClick={transferLicense}>
            {t('buttons.confirm')}
          </Button>
          <Button onClick={cancelTransferLicense}>{t('buttons.cancel')}</Button>
        </div>
      })
    }
  }

  @action
  onChangeProjectName = (value: string) => {
    this.projectName = value
  }

  onBlurProjectName = async () => {
    try {
      await this.updateLicenseParams({
        projectName: this.projectName
      })
      this.licenseUpdatedNotification()
    } catch (e) {
      console.error('Cannot update project name', e.message)
    }
  }

  onSelectEnvType = async (item: ISuggestListItem) => {
    try {
      await this.updateLicenseParams({
        envType: item.id
      })
      this.licenseUpdatedNotification()
    } catch (e) {
      console.error('Cannot update env type', e.message)
    }
  }

  getContent = () => {
    const { t, router, modalsStore, authStore } = this.props;
    const isAdmin = authStore?.isLicenseAdmin()
    const { params: { id: companyId } } = router.getState();
    if (this.license && this.company && this.selectedCompanyId && !this.isCompaniesListLoading) {
      const { tags } = this.license
      const detailsList = getLicenseDetailsList(t, this.license)
      const maxTagCount = 4
      return <div>
        <div>
          <ActionButton
            t={t}
            companyId={companyId}
            modalsStore={modalsStore}
            license={this.license}
            company={this.company}
            companyUsers={this.companyUsers}
          />
        </div>
        <div className={styles.Container}>
          <div className={styles.Content}>
            <div className={styles.TagsSelectorContainer} id={'details-tags-container'}>
              <div className={styles.FormItem}>
                {(this.selectedCompanyId && this.companiesList.length > 0) &&
                  <SelectInput
                      disabled={!isAdmin}
                      isLoading={this.isCompaniesListLoading}
                      label={t('license_details.company')}
                      items={this.companiesList}
                      selectedItemId={this.selectedCompanyId}
                      onChangeItemId={this.onSelectCompany}
                  />
                }
              </div>
              <div className={styles.FormItem}>
                <Input
                  disabled={!isAdmin}
                  label={t('project')}
                  value={this.projectName}
                  onChange={this.onChangeProjectName}
                  onBlur={this.onBlurProjectName}
                />
              </div>
              <div className={styles.FormItem}>
                <EnvSelect
                  disabled={!isAdmin}
                  selectedItemId={this.license.envType
                    ? this.license.envType
                    : ''}
                  onChangeItemId={this.onSelectEnvType}
                />
              </div>
              <div className={styles.FormItem}>
                <span className={styles.Bold}>
                  {t('tags_label', { count: maxTagCount })}
                </span>
                <SelectTags
                  disabled={!isAdmin}
                  getPopupContainer={() => document.getElementById('details-tags-container')}
                  placeholder={t('tags_placeholder')}
                  defaultValue={tags}
                  defaultOptions={this.companyTags}
                  maxTagCount={maxTagCount}
                  onChange={this.onChangeTags}
                />
              </div>
            </div>
            <div className={styles.DetailsContainer}>
              <SidePanelDetailsList items={detailsList} />
            </div>
          </div>
          <div className={styles.InfoContainer}>
            <InfoMessage iconType={'info'} text={t('license_details.company_transfer_info')} />
          </div>
        </div>
      </div>
    } else if (this.isLoading || this.isCompaniesListLoading) {
      return <SpinContainer size={'medium'} />
    } else {
      return <div className={styles.Bold}>
        {t('license_details.error_on_loading')}
      </div>
    }
  };

  render() {
    return (
      <SidePanel {...this.sidePanelProps}>
        <div>
          {this.getContent()}
        </div>
      </SidePanel>
    );
  }
}

export const LicenseDetails = withTranslation()(_LicenseDetails);
