import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  SpinContainer
} from '@wavesenterprise/uikit';
import { observer } from 'mobx-react';
import { withApi } from '../core/api/withApi';
import { IApi } from "../core/api/interfaces";
import { action, observable, runInAction } from "mobx";
import { DocsLink, SupportLink } from '../common/constants';
import styles from './SettingsPage.module.scss';

interface IProps extends WithTranslation {
  api?: IApi;
}

@withApi
@observer
class _Information extends Component<IProps> {
  @observable isLoading = false
  @observable version = ''

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  componentDidMount() {
    this.loadInfo()
  }

  loadInfo = async () => {
    const { api } = this.props
    try {
      this.setIsLoading(true)
      const { version } = await api!.getSignerInfo()
      runInAction(() => {
        this.version = version
      })
    } catch (e) {
    } finally {
      this.setIsLoading(false)
    }
  }

  render() {
    const { t } = this.props
    const year = new Date().getFullYear()
    const version = this.version
    return (<div className={styles.Block32}>
      <div className={styles.Bold}>{t('settings.service_version')}</div>
      {this.isLoading ? <SpinContainer size={'medium'} /> : <div>
        <div className={styles.Block16}>
          {
            version
              ? t('settings.license_service', { version })
              : t('settings.version_load_error')
          }
        </div>
      </div>
      }
      <div className={styles.Block32} />
      <div className={styles.SeparatorLine} />
      <div className={styles.Block16}>
        <div className={styles.Bold}>
          {t('settings.additional_links')}
        </div>
        <div className={styles.Block16}>
          <a href={DocsLink} className={styles.Link} target='_blank' rel='noopener noreferrer'>{t('settings.docs')}</a>
          <div className={styles.Block16} />
          <a href={SupportLink} className={styles.Link} target='_blank' rel='noopener noreferrer'>{t('settings.support')}</a>
          <div className={styles.Block16} />
          <span>
            © {year}, Waves Enterprise
          </span>
        </div>
      </div>
    </div>);
  }
}

export default withTranslation()(_Information);
