import i18next from 'i18next'

export const PasswordMinLength = 8;

export const PasswordErrors = {
  MinLength: 'passwordMinLength',
  Seq: 'passwordSeq',
  RequiredLowerCase: 'passwordLowerCase',
  RequiredUpperCase: 'passwordUpperCase',
  RequiredNumber: 'passwordNumber',
  RequiredSpecialChar: 'passwordSpecialChar'
};

export const getPasswordStrength = (value: string) => {
  const failed = [];
  if (value.length < PasswordMinLength) {
    failed.push(PasswordErrors.MinLength)
  }
  if (/(.)\1{2,}/.test(value)) {
    failed.push(PasswordErrors.Seq)
  }
  if (!/[a-z]/.test(value)) {
    failed.push(PasswordErrors.RequiredLowerCase)
  }
  if (!/[A-Z]/.test(value)) {
    failed.push(PasswordErrors.RequiredUpperCase)
  }
  if (!/[0-9]/.test(value)) {
    failed.push(PasswordErrors.RequiredNumber)
  }
  if (!/[^A-Za-z0-9]/.test(value)) {
    failed.push(PasswordErrors.RequiredSpecialChar)
  }
  return failed
};

export const validatePasswordByRules = (value: string) => {
  const t = i18next.getFixedT(i18next.language, 'common');
  const [error] = getPasswordStrength(value);
  let errorMessage = '';
  if (!error) {
    return errorMessage
  } else {
    switch (error) {
      case PasswordErrors.MinLength:
        errorMessage = t('auth.password_validation.min_length', {
          minLength: PasswordMinLength
        });
        break;
      case PasswordErrors.Seq:
        errorMessage = t('auth.password_validation.not_allow_sequence');
        break;
      case PasswordErrors.RequiredLowerCase:
        errorMessage = t('auth.password_validation.must_include_lowercase');
        break;
      case PasswordErrors.RequiredUpperCase:
        errorMessage = t('auth.password_validation.must_include_uppercase');
        break;
      case PasswordErrors.RequiredNumber:
        errorMessage = t('auth.password_validation.must_include_digit');
        break;
      case PasswordErrors.RequiredSpecialChar:
        errorMessage = t('auth.password_validation.must_include_special_char');
        break
    }
    return errorMessage
  }
};
