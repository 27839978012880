import React, { Component } from 'react';
import { Router, State } from 'router5';
import { withRoute } from 'react-router5';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tabs } from '@wavesenterprise/uikit';
import { inject, observer } from 'mobx-react';
import { IStore } from '../../app/stores';
import { IAuthStore } from '../core/authorization/stores/AuthStore';
import { action, observable } from "mobx";
import Main from './Main'
import Information from './Information'
import styles from './SettingsPage.module.scss';
import { RouteNameChoices, RouteNames } from "../core/routing";

interface IProps extends WithTranslation {
  route: State;
  router: Router;
  authStore?: IAuthStore;
}

enum Tab {
  main = 'main',
  info = 'info'
}

@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _SettingsPage extends Component<IProps> {
  @observable currentTab = Tab.main

  @action
  onTabChange = (tab: Tab) => {
    const { router } = this.props
    this.currentTab = tab
    const routeKey = tab === Tab.main
      ? RouteNameChoices.settingsMain
      : RouteNameChoices.settingsInfo
    router.navigate(RouteNames[routeKey])
  }

  render() {
    const { t } = this.props;
    return (<div className={styles.SettingsPage}>
        <Tabs tabBarGutter={40} activeKey={this.currentTab} onChange={this.onTabChange}>
          <Tabs.Pane tab={t('settings.tab_main')} key={Tab.main}>
            <Main />
          </Tabs.Pane>
          <Tabs.Pane tab={t('settings.tab_info')} key={Tab.info}>
            {this.currentTab === Tab.info
              ? <Information />
              : null}
          </Tabs.Pane>
        </Tabs>
    </div>
    );
  }
}

const TranslatedPage = withTranslation()(_SettingsPage);
export const SettingsPage = withRoute(({ router, route }) => (
  <TranslatedPage router={router} route={route} />
));
