import { Button, ContextMenuRadio, Icon, Input } from '@wavesenterprise/uikit';
import { action, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRoute } from 'react-router5';
import { Router, State, Subscription } from 'router5';
import { Unsubscribe } from 'router5/types/types/base';
import { IStore } from '../../app/stores';
import { RouteNameChoices, RouteNames } from '../core/routing';
import { IUsersStore } from './stores/UsersStore';
import { UsersList } from './UsersList';
import styles from './UsersPage.module.scss';
import { IModalsStore, ModalNameChoices } from '../modals/stores/ModalStore';
import { IAuthStore } from "../core/authorization/stores/AuthStore";
import { Api } from "../core/api";
import { withApi } from "../core/api/withApi";
import { ICompany } from "../core/api/interfaces";

interface IProps extends WithTranslation {
  api: Api;
  authStore?: IAuthStore;
  usersStore?: IUsersStore;
  modalsStore?: IModalsStore;
  route: State;
  router: Router;
}

@inject(({ usersStore, modalsStore, authStore }: IStore) => ({ usersStore, modalsStore, authStore }))
@observer
class _UsersPage extends Component<IProps> {
  @observable scrollShadow = false;
  @observable isCompaniesLoading = false;
  @observable companiesList: ICompany[] = [];
  unsubscribe: Unsubscribe | Subscription | null = null;
  filtersRef = React.createRef<HTMLDivElement>()

  constructor (props: IProps) {
    super(props);
    this.props.usersStore!.syncWithRouteParams(this.props.route.params)
    this.unsubscribe = this.props.router.subscribe(state => {
      const { name: prevName } = state.previousRoute;

      if (
        state.route.name !== RouteNames.users
        || prevName.startsWith(RouteNames[RouteNameChoices.userDetails])
      ) {
        return;
      }
      this.props.usersStore!.resetUsersList();
      this.props.usersStore!.syncWithRouteParams(state.route.params);
    });
  }

  componentDidMount (): void {
    this.loadCompaniesList()
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount (): void {
    window.removeEventListener('scroll', this.scrollListener);
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
    this.props.usersStore!.resetUsersList();
  }

  loadCompaniesList = async () => {
    try {
      const { companies: companiesList } = await this.props.api.getCompanies({ limit: 50, offset: 0 })
      runInAction(() => {
        this.companiesList = companiesList
      })
    } catch (e) {

    }
  }

  @action
  scrollListener = () => {
    if (window.scrollY > 0 && !this.scrollShadow) {
      this.scrollShadow = true;
    } else if (window.scrollY === 0 && this.scrollShadow) {
      this.scrollShadow = false;
    }
  };

  onAddUserClicked = () => {
    const { modalsStore } = this.props;
    if (modalsStore) {
      modalsStore.showModal(ModalNameChoices.createNewUser);
    }
  };

  getContextMenuConfig = () => {
    const companyFilter = this.props.usersStore!.getCompanyFilter();
    const companiesOptions = this.companiesList.map(company => {
      return {
        value: company.id,
        text: company.shortName
      }
    })
    return {
      value: companyFilter,
      lists: [{
        options: [{
          value: '',
          text: this.props.t('users.filter_any_company')
        }, ...companiesOptions],
      }],
    };
  }

  getCompanyFilterButtonText = () => {
    const { t } = this.props
    const companyFilter = this.props.usersStore!.getCompanyFilter();
    const defaultFilterText = t('users.filter_by_companies')
    if (companyFilter) {
       const company = this.companiesList.find(c => c.id === companyFilter)
      return company ? company.shortName : defaultFilterText
    }
    return defaultFilterText
  }

  render () {
    const { t } = this.props;
    const usersStore = this.props.usersStore!;
    const searchFilter = usersStore.getSearchFilter();
    const count = usersStore.getUsersCount();
    const isLoading = usersStore.isLoading();
    return (
      <div className={styles.UsersPage}>
        <div className={styles.top}>
          <div className={styles.filtersContainer} ref={this.filtersRef}>
            <Button icon={'plus'} onClick={this.onAddUserClicked}>{t('users.add_user')}</Button>
            <ContextMenuRadio
              getPopupContainer={() => this.filtersRef.current}
              placement="bottomLeft"
              data={this.getContextMenuConfig()}
              onChange={(companyId: string) => usersStore.setCompanyFilter(companyId)}>
              <Button icon="filter">
                {this.getCompanyFilterButtonText()}
              </Button>
            </ContextMenuRadio>
          </div>
          <div className={styles.SearchContainer}>
            <Input
              placeholder={t('users.search_placeholder')}
              addonBefore={<Icon type="search"/>}
              value={searchFilter}
              onChange={usersStore.setSearchFilter}
            />
            <div className={styles.countText}>
              {!isLoading &&
                <span>{count > 0
                  ? t('users.count', { count })
                  : t('users.not_found')}
                </span>
              }
            </div>
          </div>
        </div>
        <div className={styles.UsersListContainer}>
          <UsersList />
        </div>
      </div>
    );
  }
}

const TranslatedPage = withApi(withTranslation()(_UsersPage));
export const UsersPage = withRoute(({ router, route }) => (
  <TranslatedPage router={router} route={route}/>
));
