import React from 'react'
import { SelectInput } from "@wavesenterprise/uikit";
import { WithTranslation, withTranslation } from "react-i18next";

export enum EnvType {
  dev = 'dev',
  test = 'test',
  prod = 'prod'
}

export const DefaultEnvType = EnvType.prod

interface ListItem {
  id: string;
  value: string;
}

interface IEnvSelectProps extends WithTranslation {
  disabled?: boolean;
  selectedItemId?: string;
  onChangeItemId?: Function
}

const EnvSelect = (props: IEnvSelectProps) => {
  const { t, disabled = false, onChangeItemId } = props

  const items = Object.keys(EnvType).map(type => {
    return {
      id: type,
      value: t(`env_types_list.${type}`)
    }
  })

  const suggestProps = {
    disabled,
    label: t('env_type'),
    items,
    selectedItemId: props.selectedItemId || DefaultEnvType,
    onChangeItemId
  }

  return <SelectInput {...suggestProps} />
}

export default withTranslation()(EnvSelect)
