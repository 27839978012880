import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { observable, runInAction } from 'mobx';
import { Link, withRoute } from 'react-router5';
import { Button, Form, Input, NotificationAPI, Spin } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { AuthError } from '../../api/error-codes';
import { IConfigStore } from "../../config/stores/ConfigStore";
import { IAuthStore } from '../stores/AuthStore';
import { RouteNameChoices, RouteNames } from '../../routing';
import commonStyles from '../Authorization.module.scss';
import styles from './LoginScreen.module.scss';
import { State } from 'router5';

interface IProps {
  t: i18n.TFunction;
  authStore?: IAuthStore;
  api?: IApi;
  configStore?: IConfigStore;
  form: any; // todo we does not have type yet
  route: State;
}

const FormItem = Form.Item;

@withApi
@inject(({ authStore, configStore }: IStore) => ({ authStore, configStore }))
@observer
class _LoginScreen extends Component<IProps> {
  @observable inProgress = false;

  closeNotification = () => NotificationAPI.close();
  showCommonErrorNotification = () => NotificationAPI.error({
    message: this.props.t('auth.login.common_error'),
  });

  onSubmit = async () => {
    const { t, authStore, form: { validateFields }, configStore } = this.props;
    try {
      await configStore!.fetchConfig();
    } catch (e) {
      return;
    }
    try {
      runInAction(() => {
        this.inProgress = true;
      });
      const { email, password } = await validateFields({ force: true });
      await authStore!.login({
        login: email,
        password
      }, true);
    } catch (e) {
      const { response, errors } = e;
      if (response) {
        const { data: { errors } } = response;
        if (errors === AuthError.UserShouldBeConfirmed) {
          const resendEmail = () => {
            this.closeNotification();
          };
          NotificationAPI.error({
            message: t('auth.login.user_should_be_confirmed.message'),
            description: t('auth.login.user_should_be_confirmed.description'),
            btn: <div>
              <Button type={'primary'} onClick={resendEmail}>{t('auth.login.user_should_be_confirmed.send')}</Button>
              <Button onClick={this.closeNotification}>{t('auth.login.user_should_be_confirmed.ignore')}</Button>
            </div>,
          });
        } else if (errors === AuthError.UserNotFound) {
          NotificationAPI.error({
            message: t('auth.login.user_not_found'),
          });
        } else {
          this.showCommonErrorNotification();
        }
      } else if (!errors) { // not a form validation error
        this.showCommonErrorNotification();
      }
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  };

  render () {
    const {
      t,
      form: { getFieldDecorator },
      configStore,
      route: {
        params: { email }
      }
    } = this.props;
    return (
      <div className={styles.LoginScreen}>
        <div className={commonStyles.title}>{t('auth.title')}</div>
        <span>{t('auth.login.description')}</span>
        <div className={commonStyles.block32}>
          <Form>
            <FormItem>
              {getFieldDecorator('email', {
                validateFirst: true,
                initialValue: email,
                rules: [{
                  required: true,
                  message: t('auth.email_required'),
                }, {
                  type: 'email',
                  message: t('auth.email_incorrect'),
                }],
                validateTrigger: 'onBlur',
              })(<Input placeholder={t('auth.email_placeholder')} onPressEnter={this.onSubmit}/>)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                // initialValue: '',
                validateFirst: true,
                validateTrigger: null,
                rules: [{
                  required: true,
                  message: t('auth.password_required'),
                }],
              })(
                <Input
                  placeholder={t('auth.password')}
                  type="password"
                  onPressEnter={this.onSubmit}
                />,
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                className={commonStyles.inline}
                disabled={this.inProgress}
                onClick={this.onSubmit}>
                {t('auth.login.proceed')}
              </Button>
              {(this.inProgress || configStore!.isLoading()) &&
              <Spin size={'medium'} className={commonStyles.inline}/>
              }
            </FormItem>
          </Form>
          <div className={commonStyles.block32}>
            <Link routeName={RouteNames[RouteNameChoices.register]}>
              <span className={commonStyles.link}>{t('auth.create_account')}</span>
            </Link>
          </div>
          <div className={commonStyles.block32}>
            <Link routeName={RouteNames[RouteNameChoices.restore]}>
              <span className={commonStyles.link}>{t('auth.restore_account')}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const Translated = withTranslation()(Form.create()(_LoginScreen));
// @ts-ignore
export const LoginScreen = withRoute(({ route, router }) => <Translated route={route} router={router}/>);
