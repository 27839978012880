import React, { useState } from 'react';
import { Input } from "@wavesenterprise/uikit";
import { ISearchInputProps, IListItem, ArrowKeyCodes } from './interfaces'
import { OptionsList } from './OptionsList'
import SearchListUtils from './search-input.utils'
import styles from './SearchInput.module.scss'

export const SearchInput = (props: ISearchInputProps) => {
  const {
    initialValue = '',
    label = '',
    items,
    placeholder = '',
    isLoading,
    notFoundText,
    onChange,
    onSelectItem
  } = props;

  const [value, setValue] = useState(initialValue);
  const [isListVisible, setListVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  const onInputChange = (value: string) => {
    setValue(value);
    setListVisible(true);
    setActiveItemId(null);
    if (onChange) {
      onChange(value)
    }
  };

  const onInputFocus = () => {
    setListVisible(true)
  };

  const onInputPressEnter = () => {
    if (activeItemId) {
      const item = items.find((item: IListItem) => item.id === activeItemId);
      if (item) {
        onListItemClick(item)
      }
    } else {
      setListVisible(false);
    }
  };

  const onInputBlur = (e: any) => {
    setTimeout(() => {
      // console.log('blur', e, e.currentTarget());
      setListVisible(false)
    }, 150)
  };

  const onInputClear = () => {
    setValue('');
  };

  const onListItemClick = (item: IListItem) => {
    const {value} = item;
    setValue(value);
    setActiveItemId(null);
    setListVisible(false);
    if (onChange) {
      onChange(value)
    }
    if (onSelectItem) {
      onSelectItem(item)
    }
  };

  const onKeyDown = (e: any) => {
    const { keyCode } = e;
    if (Object.values(ArrowKeyCodes).includes(keyCode)) {
      e.preventDefault();
      e.stopPropagation();

      const itemsCount = items.length;
      if (itemsCount > 0) {
        // const refs = items.reduce((acc: any, value: IListItem) => {
        //   acc[value.id] = React.createRef();
        //   return acc;
        // }, {});
        if (keyCode === ArrowKeyCodes.down) {
          if (isListVisible) {
            setActiveItemId(SearchListUtils.getNextItemId(items, activeItemId))
          } else {
            setListVisible(true);
          }
        } else if (keyCode === ArrowKeyCodes.up) {
          setActiveItemId(SearchListUtils.getPrevItemId(items, activeItemId))
        }
      }
    }
  };

  const inputProps = {
    label,
    hideClearButton: isLoading,
    spinning: isLoading,
    placeholder,
    value,
    onChange: onInputChange,
    onFocus: onInputFocus,
    onPressEnter: onInputPressEnter,
    onBlur: onInputBlur,
    onClear: onInputClear,
    onKeyDown
  };

  const listProps = {
    items,
    activeItemId,
    onItemClick: onListItemClick,
    setActiveItemId
  };

  return <div className={styles.SearchInput}>
    <Input {...inputProps} />
    {(isListVisible && items.length > 0) &&
      <OptionsList {...listProps} />
    }
    {(notFoundText && items.length === 0) &&
      <OptionsList items={[{id: notFoundText, value: notFoundText}]} />
    }
  </div>
};

