import { action, observable } from 'mobx';
import { Router } from 'router5';
import { Params } from 'router5/types/types/base';
import { RouteNameChoices, RouteNames } from '../../core/routing';
import { getModalNameByRoute } from '../modals.utils';

export enum ModalNameChoices {
  createCompany = 'createCompany',
  updateCompany = 'updateCompany',
  companyDetails = 'companyDetails',
  licenseIssue = 'licenseIssue',
  licenseDetails = 'licenseDetails',
  licenseActivate = 'licenseActivate',
  licenseCancel = 'licenseCancel',
  userDetails = 'userDetails',
  createNewUser = 'createNewUser'
}

export interface IModalsStore {
  getCurrentModal (): ModalNameChoices | null;

  onCancel (routeName: string, routeParams?: Params): () => void;

  getSavedRoute (): {
    routeName: string | null;
    routeParams: object | null;
  }

  showModal (
    modalName: ModalNameChoices,
    routeParams?: Params,
    saveState?: boolean
  ): void;

  dropState (): void
}

export class ModalStore implements IModalsStore {
  constructor (router: Router) {
    this._savedRouteName = null;
    this._savedRouteParams = null;
    this._currentModal = null;
    this.router = router;
  }

  private readonly router: Router;

  @observable
  private _currentModal: ModalNameChoices | null;

  @observable
  private _savedRouteName: string | null;

  private _savedRouteParams: object | null;

  @observable
  private _modalsHistory = [];

  private _saveRouterState (name: string, params: Params) {
    this._savedRouteName = name;
    this._savedRouteParams = params;
  }

  private _dropRouterState () {
    this._savedRouteName = null;
    this._savedRouteParams = null;
  }

  public getCurrentModal (): ModalNameChoices | null {
    return this._currentModal;
  }

  public getSavedRoute () {
    return {
      routeName: this._savedRouteName,
      routeParams: this._savedRouteParams,
    };
  };

  @action
  public showModal = (
    modalName: ModalNameChoices,
    routeParams?: Params,
    saveState: boolean = true
  ) => {
    this._currentModal = modalName;

    let modalRoute = '';
    switch (modalName) {
      case ModalNameChoices.createCompany:
        modalRoute = RouteNameChoices.createCompany;
        break;
      case ModalNameChoices.updateCompany:
        modalRoute = RouteNames[RouteNameChoices.updateCompany];
        break;
      case ModalNameChoices.companyDetails:
        modalRoute = RouteNameChoices.companyDetails;
        break;
      case ModalNameChoices.licenseIssue:
        modalRoute = RouteNames[RouteNameChoices.licenseIssue];
        break;
      case ModalNameChoices.licenseDetails:
        modalRoute = RouteNames[RouteNameChoices.licenseDetails];
        break;
      case ModalNameChoices.licenseActivate:
        modalRoute = RouteNames[RouteNameChoices.licenseActivate];
        break;
      case ModalNameChoices.licenseCancel:
        modalRoute = RouteNames[RouteNameChoices.licenseCancel];
        break;
      case ModalNameChoices.userDetails:
        modalRoute = RouteNames[RouteNameChoices.userDetails];
        break;
      case ModalNameChoices.createNewUser:
        modalRoute = RouteNames[RouteNameChoices.createNewUser];
        break;
    }
    if (modalRoute) {
      if (saveState) {
        const { name, params } = this.router.getState();
        this._saveRouterState(name, params);
      }
      this.router.navigate(modalRoute, routeParams as Params);
    }
  };

  public onCancel = (
    routeName: string,
    routeParams?: Params,
  ) =>
    action(() => {
      /*
      this._currentModal = null;
      if (this._savedRouteName && this._savedRouteParams) {
        this.router.navigate(this._savedRouteName, {
          ...this._savedRouteParams
        });
        this._dropRouterState();
      } else {
        this.router.navigate(RouteNameChoices.companies);
      }
       */
      let modalByRoute = getModalNameByRoute(routeName);
      routeParams = routeParams || {};
      this._currentModal = modalByRoute;
      this.router.navigate(routeName, routeParams);
    });

  @action
  public dropState = () => {
    this._currentModal = null;
    this._savedRouteName = null;
    this._savedRouteParams = null;
  }
}
