import React, { Component } from 'react';
import { api } from '../../../index';
import { Api } from './index';

interface IProps {
  api: Api;
}

export const withApi = (WrappedComponent: any): any => {
  return class extends Component {
    render() {
      return <WrappedComponent api={api} {...this.props} />;
    }
  };
};
