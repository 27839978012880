import { Router } from 'router5';
import { Api } from '../../modules/core/api';
import { IAuthStore, AuthStore } from '../../modules/core/authorization/stores/AuthStore';
import { IConfigStore, ConfigStore } from "../../modules/core/config/stores/ConfigStore";
import { IModalsStore, ModalStore } from '../../modules/modals/stores/ModalStore';
import { ICompaniesStore, CompaniesStore } from '../../modules/companies/stores/CompaniesStore';
import { IUsersStore, UsersStore } from '../../modules/users/stores/UsersStore';

export interface IStore {
  modalsStore: IModalsStore;
  authStore: IAuthStore;
  companiesStore: ICompaniesStore;
  configStore: IConfigStore;
  usersStore: IUsersStore;
}

interface IDependencies {
  api: Api;
  router: Router;
}

export const createStore = (dependencies: IDependencies): IStore => {
  const { api, router } = dependencies;
  return {
    modalsStore: new ModalStore(router),
    authStore: new AuthStore({ router, api }),
    configStore: new ConfigStore({ api }),
    companiesStore: new CompaniesStore({ router, api }),
    usersStore: new UsersStore({ router, api }),
  };
};
