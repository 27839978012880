import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Form, Input, Button, NotificationAPI, Spin } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { IAuthStore } from '../stores/AuthStore';
import { RouteNameChoices, RouteNames } from "../../routing";
import { withRoute } from "react-router5";
import commonStyles from "../Authorization.module.scss";
import styles from './RestoreScreen.module.scss';
import { AuthError } from "../../api/error-codes";
import { action, observable } from "mobx";
import { validatePasswordByRules } from '../utils/password-validator'
import { Router, State } from "router5";
import { SupportLink } from "../../../common/constants";

interface IProps {
  t: i18n.TFunction;
  router: Router;
  route: State;
  authStore?: IAuthStore;
  api?: IApi;
  form: any; // todo we does not have type yet
}

const FormItem = Form.Item;

enum FormFields {
  password = 'password',
  confirm = 'confirm'
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _TypeNewPassword extends Component<IProps> {
  @observable inProgress = false;

  @action
  setInProgress = (inProgress: boolean) => {
    this.inProgress = inProgress
  };

  validatorPassword = async (rule: any, value: string, cb: Function) => {
    const errorMessage = validatePasswordByRules(value);
    if (errorMessage) {
      cb(errorMessage)
    } else {
      cb()
    }
  };

  validateToNextPassword = (rule: any, value: string, cb: Function) => {
    this.props.form.validateFields([FormFields.confirm], { force: true });
    cb()
  };

  compareToFirstPassword = (rule: any, value: string, cb: Function) => {
    const {form: {getFieldValue}} = this.props;
    if (value && value !== getFieldValue(FormFields.password)) {
      cb(true)
    } else {
      cb()
    }
  };

  onSubmit = async () => {
    const {t, api, form: {validateFields}, router} = this.props;
    const {params: {token}} = router.getState();
    const { password } = await validateFields({force: true});
    if (password) {
      this.setInProgress(true);
      try {
        await api!.resetPassword({token, password});
        NotificationAPI.success({
          message: t('auth.restore.type_new_password.password_change_success')
        });
        router.navigate(RouteNames[RouteNameChoices.login])
      } catch (e) {
        const errors = e?.response?.data?.errors;
        if (errors && errors.includes(AuthError.TokenAlreadyUsed)) {
          NotificationAPI.error({
            message: t('auth.restore.type_new_password.token_already_used')
          });
        } else {
          NotificationAPI.error({
            message: t('auth.restore.type_new_password.cannot_change_password')
          });
        }
      } finally {
        this.setInProgress(false)
      }
    }
  };

  render() {
    const {
      t,
      router,
      form: { getFieldDecorator }
    } = this.props;
    const {params: {token, email}} = router.getState();
    const onSupportClick = () => window.open(SupportLink, '_blank');
    return (
      <div className={styles.RestoreScreen}>
        <div className={commonStyles.title}>{t('auth.title')}</div>
        {token &&
          <div>
            {email &&
            <div>
                <span className={styles.bold}>{t('auth.restore.type_new_password.your_login')}{' '}{email}</span>
            </div>
            }
              <div className={styles.block16}>
                  <span>{t('auth.restore.type_new_password.title')}</span>
              </div>
              <div className={commonStyles.block32}>
                  <Form>
                      <FormItem>
                        {getFieldDecorator(FormFields.password, {
                          validateFirst: true,
                          // initialValue: '',
                          rules: [{
                            required: true,
                            message: t('required_field')
                          }, {
                            validator: this.validatorPassword
                          }, {
                            validator: this.validateToNextPassword
                          }],
                          validateTrigger: 'onBlur'
                        })(<Input
                          type={'password'}
                          placeholder={t('auth.restore.type_new_password.password_placeholder')}
                          onPressEnter={this.onSubmit} />)}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator(FormFields.confirm, {
                          validateFirst: true,
                          // initialValue: '',
                          rules: [{
                            required: true,
                            message: t('required_field')
                          }, {
                            validator: this.validatorPassword
                          }, {
                            validator: this.compareToFirstPassword,
                            message: t('auth.password_confirmation_error')
                          }],
                          validateTrigger: 'onBlur'
                        })(<Input
                          type={'password'}
                          placeholder={t('auth.restore.type_new_password.confirm_placeholder')}
                          onPressEnter={this.onSubmit} />)}
                      </FormItem>
                      <FormItem>
                          <Button disabled={this.inProgress} onClick={this.onSubmit} type="primary">
                            {t('buttons.continue')}
                          </Button>
                        {(this.inProgress) &&
                        <Spin size={'medium'} className={commonStyles.inline}/>
                        }
                      </FormItem>
                  </Form>
              </div>
          </div>
        }
        {!token &&
          <div>
            <span>{t('auth.restore.type_new_password.restore_error')}</span>
            <div className={styles.block32}>
                <span className={commonStyles.link} onClick={onSupportClick}>
                  {t('auth.restore.type_new_password.support_link')}
                </span>
            </div>
          </div>
        }
      </div>
    );
  }
}

const TranslatedForm = withTranslation()(Form.create()(_TypeNewPassword));
// @ts-ignore
export const TypeNewPassword = withRoute(({ route, router }) => <TranslatedForm route={route} router={router} />);
