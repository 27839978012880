import React from 'react';
import i18n from 'i18next';
import { Icon, Select } from '@wavesenterprise/uikit';
import { getAvailableLocales, set3rdPartyLocale } from '../../core/i18n';

const changeLanguage = (lang: string) => {
  i18n
    .changeLanguage(lang, err => {
      if (err) {
        throw new Error(err);
      }
      set3rdPartyLocale(lang);
    })
    .catch(err => {
      console.error('Error on change locale', err);
    });
};

export default function LanguageSelect() {
  const availableLocales = getAvailableLocales();
  return (
    <div className={'v-language-select'}>
      <Select value={i18n.language} onChange={changeLanguage}>
        {availableLocales.map(item => {
          const { lang, icon, alias } = item;
          return (
            <Select.Option value={lang} key={lang}>
              <Icon type={icon} />
              <span>{alias}</span>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
