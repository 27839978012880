import { Button, ContextMenuRadio, Icon, Input } from '@wavesenterprise/uikit';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRoute } from 'react-router5';
import { Router, State, Subscription } from 'router5';
import { Unsubscribe } from 'router5/types/types/base';
import { IStore } from '../../app/stores';
import { LicenseStatus } from '../core/api/interfaces';
import { RouteNameChoices, RouteNames } from '../core/routing';
import { ICompaniesStore, LicenseStatusChoice } from './stores/CompaniesStore';
import { CompaniesList } from './CompaniesList';
import styles from './CompaniesPage.module.scss';
import { IModalsStore, ModalNameChoices } from '../modals/stores/ModalStore';
import { IAuthStore } from "../core/authorization/stores/AuthStore";
import i18next from "i18next";
import { DocsLink, SupportEmail } from "../common/constants";

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  companiesStore?: ICompaniesStore;
  modalsStore?: IModalsStore;
  route: State;
  router: Router;
}

interface ICompaniesCountText {
  t: i18next.TFunction;
  count: number;
  isLicenseAdmin: boolean;
  isFilterApplied: boolean;
}

interface IWelcomeScreenProps {
  t: i18next.TFunction;
}

function WelcomeScreen (props: IWelcomeScreenProps) {
  const {t} = props;
  const locale = i18next.language;
  return <div className={styles.CompaniesPage}>
    <div className={styles.top}>
      <div className={styles.welcomeInfo}>
        <div>{t('welcome_info.description')}</div>
        <div className={styles.block32}>
          <a className={styles.link} href={`${DocsLink}/${locale}/1.2.0/how-to-use/getting-license.html`} target={'_blank'} rel="noopener noreferrer">{t('welcome_info.how_license_works')}</a>
        </div>
        <div className={styles.block32}>{t('welcome_info.info')}{' '}
          <a className={styles.link} href={`mailto:${SupportEmail}`}>{SupportEmail}</a>
        </div>
      </div>
    </div>
  </div>
}

function CompaniesCountText (props: ICompaniesCountText) {
  const {t, count, isLicenseAdmin, isFilterApplied} = props;
  let text = '';
  if (isLicenseAdmin) {
    if (count === 0) {
      if (isFilterApplied) {
        text = t('companies.found_nothing')
      } else {
        text = t('companies.no_companies')
      }
    } else {
      text = t('companies.found_count', { count })
    }
  } else {
    if (count === 0) {
      if (isFilterApplied) {
        text = t('companies.found_nothing')
      } else {
        text = t('companies.user_no_companies')
      }
    } else {
      if (isFilterApplied) {
        text = t('companies.found_count', { count })
      } else {
        text = t('companies.user_found_count', { count })
      }
    }
  }
  return <span>{text}</span>
}

@inject(({ companiesStore, modalsStore, authStore }: IStore) => ({ companiesStore, modalsStore, authStore }))
@observer
class _CompaniesPage extends Component<IProps> {
  @observable scrollShadow = false;
  unsubscribe: Unsubscribe | Subscription | null = null;

  tagsContainerRef = React.createRef<HTMLDivElement>()
  filtersContainerRef = React.createRef<HTMLDivElement>()

  constructor (props: IProps) {
    super(props);
    this.props.companiesStore!.syncWithRouteParams(this.props.route.params).then(_ => {
      const companiesCount = this.props.companiesStore!.getCompaniesCount();
      if (!props.authStore!.isCompanyMember() && companiesCount > 0) {
        this.props.authStore!.setIsCompanyMember(true)
      }
    });
    this.unsubscribe = this.props.router.subscribe(state => {
      const { name: prevName } = state.previousRoute;

      if (state.route.name === RouteNames.companies
        && prevName.startsWith(RouteNames[RouteNameChoices.companyDetails])) {
        this.props.companiesStore!.updateTags()
      }

      if (
        state.route.name !== RouteNames.companies ||
        prevName.startsWith(RouteNames[RouteNameChoices.companyDetails])
      ) {
        return;
      }
      this.props.companiesStore!.resetCompaniesList();
      this.props.companiesStore!.syncWithRouteParams(state.route.params);
    });
  }

  componentDidMount (): void {
    window.addEventListener('scroll', this.scrollListener);
    this.props.companiesStore!.updateTags()
  }

  componentWillUnmount (): void {
    window.removeEventListener('scroll', this.scrollListener);
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
    this.props.companiesStore!.resetCompaniesList();
  }

  @action
  scrollListener = () => {
    if (window.scrollY > 0 && !this.scrollShadow) {
      this.scrollShadow = true;
    } else if (window.scrollY === 0 && this.scrollShadow) {
      this.scrollShadow = false;
    }
  };

  getStatusFilterOptions (statusFilter: LicenseStatusChoice) {
    const { t } = this.props;
    const options = [
      { value: 'all', text: t('license_statuses_all') },
      { value: LicenseStatus.expiring, text: t('license_statuses_list.expiring') },
    ];
    return {
      value: statusFilter,
      lists: [
        {
          options,
        },
      ],
    };
  }

  getTagFilterOptions (tagFilter: string) {
    const { t, companiesStore } = this.props;
    const tags = companiesStore!.getTags()
    const options = [{
      value: 'all',
      text: t('tags_all')
    },
      ...tags.map(tag => ({value: tag, text: tag}))
    ];
    return {
      value: tagFilter,
      lists: [{
          options,
        }],
    };
  }

  getStatusButtonText (statusFilter: LicenseStatusChoice) {
    const { t } = this.props;
    if (statusFilter === 'all') {
      return t('license_statuses_all');
    } else {
      return t(`license_statuses_list.${statusFilter}`);
    }
  }

  getTagButtonText (tagFilter: string) {
    const { t } = this.props;
    if (tagFilter === 'all') {
      return t('tags_all');
    } else {
      return tagFilter;
    }
  }

  onAddCompanyClicked = () => {
    const { modalsStore } = this.props;
    if (modalsStore) {
      modalsStore.showModal(ModalNameChoices.createCompany);
    }
  };

  render () {
    const { t, authStore } = this.props;
    const companiesStore = this.props.companiesStore!;
    const statusFilter = companiesStore.getStatusesFilter();
    const searchFilter = companiesStore.getSearchFilter();
    const tagFilter = companiesStore.getTagFilter();
    const count = companiesStore.getCompaniesCount();
    const isLoading = companiesStore.isLoading();
    const isLicenseAdmin = authStore!.isLicenseAdmin();
    const isCompanyMember = authStore!.isCompanyMember();

    if (!isCompanyMember) {
      return <WelcomeScreen t={t} />
    }

    const companiesCountProps = {
      t,
      count,
      isLicenseAdmin,
      isFilterApplied: searchFilter !== '' || statusFilter !== 'all'
    };
    return (
      <div className={styles.CompaniesPage}>
        <div className={this.scrollShadow ? styles.topShadow : styles.top}>
          <div className={styles.filters} ref={this.filtersContainerRef}>
            {isLicenseAdmin &&
              <div>
                  <Button icon="plus" onClick={this.onAddCompanyClicked}>
                    {t('companies.create_company')}
                  </Button>
              </div>
            }
            <div>
              <ContextMenuRadio
                getPopupContainer={() => this.filtersContainerRef.current}
                placement="bottomLeft"
                data={this.getStatusFilterOptions(statusFilter)}
                onChange={(value: LicenseStatusChoice) => companiesStore.setStatusesFilter(value)}>
                <Button icon="filter">
                  {this.getStatusButtonText(statusFilter)}
                </Button>
              </ContextMenuRadio>
            </div>
            <div className={'tags-filter-container'} ref={this.tagsContainerRef}>
              <ContextMenuRadio
                getPopupContainer={() => this.tagsContainerRef.current}
                placement="bottomLeft"
                data={this.getTagFilterOptions(tagFilter)}
                onChange={(value: string) => companiesStore.setTagFilter(value)}>
                <Button icon="filter">
                  {this.getTagButtonText(tagFilter)}
                </Button>
              </ContextMenuRadio>
            </div>
          </div>
          <div className={styles.SearchContainer}>
            <Input
              placeholder={t('companies.search_placeholder')}
              addonBefore={<Icon type="search"/>}
              value={searchFilter}
              onChange={companiesStore.setSearchFilter} />
          </div>
          <div className={styles.countText}>
            {!isLoading &&
              <CompaniesCountText {...companiesCountProps} />
            }
          </div>
        </div>
        <CompaniesList/>
      </div>
    );
  }
}

const TranslatedPage = withTranslation()(_CompaniesPage);
export const CompaniesPage = withRoute(({ router, route }) => (
  <TranslatedPage router={router} route={route}/>
));
