import { NotificationAPI } from '@wavesenterprise/uikit';
import axios from 'axios';
import { action, observable } from 'mobx';
import { actionAsync, task } from 'mobx-utils';
import { Router } from 'router5';
import { IApi, ICompanyUserExtended, LicenseStatus } from '../../core/api/interfaces';
import { RouteNames } from '../../core/routing';
import i18next from 'i18next';

const DEFAULT_LIMIT = 50;

export type LicenseStatusChoice = LicenseStatus | 'all';

export interface IRouteParams {
  search?: string;
  companyId?: string;
  limit?: number;
  offset?: number;
}

export interface IUsersStore {
  syncWithRouteParams (routeParams: IRouteParams): Promise<void>;

  loadMoreUsers (): void;

  isLoading (): boolean;

  getSearchFilter (): string;

  setSearchFilter (value: string): void;

  getCompanyFilter (): string;

  setCompanyFilter (companyId: string): void;

  getUsers (): ICompanyUserExtended[];

  getUsersCount (): number;

  hasMoreToLoad (): boolean;

  resetUsersList (): void;
}

export class UsersStore implements IUsersStore {
  constructor (deps: { api: IApi; router: Router }) {
    const { api, router } = deps;
    this._api = api;
    this._router = router;
    this._users = [];
    this._usersCount = 0;
    this._hasMore = true;
    this._loading = false;
    this._limit = DEFAULT_LIMIT;
    this._offset = 0;

    this._searchFilter = '';
    this._companyFilter = '';
  }

  private readonly _api: IApi;

  private readonly _router: Router;

  private _typingTimerId: number | null = null;

  private readonly _limit: number;

  private _offset: number;

  @observable
  private _loading: boolean;

  @observable
  private _searchFilter: string;

  @observable
  private _companyFilter: string;

  @observable
  private _users: ICompanyUserExtended[];

  @observable
  private _usersCount: number;

  @observable
  private _hasMore: boolean;

  @action
  public resetUsersList = () => {
    this._offset = 0;
    this._users = [];
    this._usersCount = 0;
  };


  private _mapClassPropertiesToRouteParams = (): IRouteParams => {
    return {
      companyId: !this._companyFilter ? undefined : this._companyFilter,
      search: this._searchFilter === '' ? undefined : this._searchFilter,
      limit: this._limit,
      offset: this._offset,
    };
  };

  private _showLoadingErrorNotification () {
    const t = i18next.getFixedT(i18next.language, 'common');
    NotificationAPI.error({
      key: 'companies_loading_error',
      message: t('loading_errors.companies'),
    });
  }

  @actionAsync
  private _fetchUsers = async () => {
    this._loading = true;
    try {
      const { users, count, hasMore } = await task(
        this._api.getUsers({
          ...this._mapClassPropertiesToRouteParams(),
        }),
      );
      this._loading = false;
      this._usersCount = count;
      this._users.push(...users);
      this._hasMore = hasMore;
      // this._offset = this._offset + this._limit;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled');
      } else {
        this._loading = false;
        this._users = [];
        this._showLoadingErrorNotification();
      }
    }
  };

  @actionAsync
  public syncWithRouteParams = async (routeParams: IRouteParams) => {
    const {
      companyId = '',
      search = ''
    } = routeParams;
    this._companyFilter = companyId;
    this._searchFilter = search;
    this._users = [];
    await task(this._fetchUsers());
  };

  public loadMoreUsers(): void {
    this._offset = this._offset + this._limit;
    this._fetchUsers()
  }

  public isLoading = () => {
    return this._loading;
  };

  public getSearchFilter = (): string => {
    return this._searchFilter;
  };

  @action
  public setSearchFilter = async (value: string): Promise<void> => {
    this._searchFilter = value;
    const timeout = value ? 800 : 0;
    if (this._typingTimerId !== null) {
      clearTimeout(this._typingTimerId);
    }
    this._typingTimerId = window.setTimeout(() => {
      this.resetUsersList();
      this._router.navigate(RouteNames.users, {
        ...this._mapClassPropertiesToRouteParams()
      });
    }, timeout);
  };

  public getCompanyFilter = (): string => {
    return this._companyFilter;
  };

  @action
  public setCompanyFilter = async (
    companyId: string,
  ): Promise<void> => {
    this._companyFilter = companyId;
    this.resetUsersList();
    this._router.navigate(RouteNames.users, {
      ...this._mapClassPropertiesToRouteParams()
    });
  };

  public getUsers = (): ICompanyUserExtended[] => {
    return this._users;
  };

  public getUsersCount = (): number => {
    return this._usersCount;
  };

  public hasMoreToLoad = (): boolean => {
    return this._hasMore;
  };
}
