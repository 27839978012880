import { configure as configureMobx } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import '@wavesenterprise/uikit/styles.css';
import './app/global-styles/index.scss';
import { RouterProvider } from 'react-router5';
import App from './app/App';
import { createStore } from './app/stores';
import { Api } from './modules/core/api';
import initI18n from './modules/core/i18n';
import { router } from './modules/core/routing';
import * as serviceWorker from './serviceWorker';

configureMobx({
  enforceActions: 'observed'
});

initI18n();

export const api = new Api({ router });

const store = createStore({ api, router });

ReactDOM.render(
  <Provider {...store}>
    <RouterProvider router={router}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </RouterProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
