import { DataList, SpinContainer } from '@wavesenterprise/uikit';
import { inject, observer } from 'mobx-react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withRoute } from 'react-router5';
import { State } from 'router5';
import { IStore } from '../../app/stores';
import { ICompaniesStore } from './stores/CompaniesStore';
import styles from './CompaniesList.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IModalsStore, ModalNameChoices } from '../modals/stores/ModalStore';

const dataConfig = {
  companies: {
    columns: [
      {
        key: '1',
        dimensions: 6,
      },
      {
        key: '2',
        dimensions: 2,
      },
      {
        key: '3',
        dimensions: 2,
      },
      {
        key: '4',
        dimensions: 2,
      },
    ],
  },
};

interface IProps extends WithTranslation {
  companiesStore?: ICompaniesStore;
  modalsStore?: IModalsStore;
  route: State;
}

const _CompaniesList = inject(({ companiesStore, modalsStore }: IStore) => ({
  companiesStore,
  modalsStore,
}))(
  observer((props: IProps) => {
    const { t, modalsStore } = props;
    const companiesStore = props.companiesStore!;
    const companiesData = companiesStore.getCompanies().map(company => {
      const { id, inn, licenses: { activated, expiring, available } } = company;
      const emptyCaption = '—';
      return {
        key: id,
        type: 'companies',
        columns: [
          {
            value: company.shortName,
            caption: t('company.inn', { inn }),
          },
          {
            value: available || emptyCaption,
            caption: t('company.licenses_available'),
          },
          {
            value: activated || emptyCaption,
            caption: t('company.licenses_activated'),
          },
          {
            value: expiring || emptyCaption,
            caption: t('company.licenses_expiring'),
          },
        ],
      };
    });

    const onItemClick = (id: string) => {
      if (modalsStore) {
        modalsStore.showModal(ModalNameChoices.companyDetails, { id });
      }
    };
    return (
      <div className={styles.CompaniesList}>
        <InfiniteScroll
          pageStart={0}
          useWindow={true}
          hasMore={companiesStore.hasMoreToLoad()}
          loadMore={() => {
            if (!companiesStore.isLoading()) {
              companiesStore.loadMoreCompanies();
            }
          }}
          initialLoad={false}
          loader={<div key={'stub'}/>}
        >
          <div>
            <DataList
              className={styles.DataList}
              data={companiesData}
              dataConfig={dataConfig}
              onItemClick={onItemClick}
            />
            {companiesStore.isLoading() && (
              <SpinContainer key="spinner" size="large"/>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }),
);

const TranslatedCompaniesList = withTranslation()(_CompaniesList);
export const CompaniesList = withRoute(({ route }) => (
  <TranslatedCompaniesList route={route}/>
));
