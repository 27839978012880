import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IStore } from '../../../app/stores';
import { IApi } from '../api/interfaces';
import { withApi } from '../api/withApi';
import { IAuthStore } from './stores/AuthStore';
import LanguageSelect from '../../common/language-select/LanguageSelect';
import { Router, State } from "router5";
import { withRoute } from "react-router5";
import { RouteNameChoices, RouteNames } from "../routing";
import WelcomeScreen from "./welcome";
import LoginScreen from "./login";
import RegisterScreen from "./register";
import RestoreScreen from "./restore";
import ActivateScreen from "./activate";
import SetPassword from "./set-password";
import styles from './Authorization.module.scss';

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  api?: IApi;
  route: State;
  router: Router;
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
class _Authorization extends Component<IProps> {
  getContentByRoute = () => {
    const {name} = this.props.route;
    if (name.startsWith(RouteNames[RouteNameChoices.login])) {
      return <LoginScreen />
    } else if (name.startsWith(RouteNames[RouteNameChoices.register])) {
      return <RegisterScreen />
    } else if (name.startsWith(RouteNames[RouteNameChoices.restore])) {
      return <RestoreScreen />
    } else if (name.startsWith(RouteNames[RouteNameChoices.activate])) {
      return <ActivateScreen />
    } else if (name.startsWith(RouteNames[RouteNameChoices.setPassword])) {
      return <SetPassword />
    }
    return <WelcomeScreen />;
  };

  render() {
    const content = this.getContentByRoute();
    return (
      <div className={styles.Authorization}>
        <div className={styles.languageSelect}>
          <LanguageSelect />
        </div>
        {content}
      </div>
    );
  }
}

const TranslatedAuth = withTranslation()(_Authorization);
export const Authorization = withRoute(({ route, router }) => <TranslatedAuth route={route} router={router} />);
