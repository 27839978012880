import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { RegisterForm } from "./RegisterForm";
import { RegisterCompletion } from "./RegisterCompletion";

enum RegisterSteps {
  fillForm = 'fillForm',
  completion = 'completion'
}

interface IProps extends WithTranslation {
}

@observer
class _RegisterScreen extends Component<IProps> {
  @observable currentStep = RegisterSteps.fillForm;
  @observable userEmail: string = '';

  @action
  onRegisterSuccess = (email: string) => {
    this.userEmail = email;
    this.currentStep = RegisterSteps.completion
  };

  render () {
    const formProps = {
      onRegisterSuccess: this.onRegisterSuccess
    };
    switch (this.currentStep) {
      case RegisterSteps.fillForm:
        return <RegisterForm {...formProps} />;
      case RegisterSteps.completion:
        return <RegisterCompletion email={this.userEmail} />;
    }
    return null
  }
}

export const RegisterScreen = withTranslation()(_RegisterScreen);