import * as countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export default function(lang: string) {
  const raw = countries.getNames(lang);
  const keys = Object.keys(raw);
  return keys.map((key) => ({ value: key.toLocaleLowerCase(), text: raw[key] }))
    .sort((a) => {
      if (a.value === 'ru') {
        return -1
      }
      return 0
    });
}

export const getCountryCodeByName = (lang: string, countryName: string) => {
  const raw = countries.getNames(lang);
  const [entry] = Object.entries(raw)
    .filter(([_, name]) => name.toLowerCase() === countryName.toLowerCase());
  if (entry) {
    const [code] = entry;
    return code.toLowerCase()
  }
  return null;
};

export const getNameByCountryCode = (lang: string, countryCode: string) => {
  const raw = countries.getNames(lang);
  return raw[countryCode.toUpperCase()]
};

