import { Row } from '@wavesenterprise/uikit';
import spriteSvgPublicPath from '@wavesenterprise/uikit/sprite.svg';
import { inject, observer } from 'mobx-react';
import React, { Component, createRef, Fragment } from 'react';
import { withRoute } from 'react-router5';
import { Router } from 'router5';
import { Content } from '../modules/common/content/Content';
import { Sidebar } from '../modules/common/sidebar/Sidebar';
import { withApi } from '../modules/core/api/withApi';
import { IAuthStore } from '../modules/core/authorization/stores/AuthStore';
import { RouteNameChoices, RouteNames } from '../modules/core/routing';
import { ModalContainer } from '../modules/modals/ModalContainer';
import styles from './App.module.scss';
import { IStore } from './stores';

// additional window properties must be described here
declare global {
  interface Window {}
}

interface IProps {
  router: Router;
  authStore?: IAuthStore;
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class App extends Component<IProps> {
  spriteBlockRef = createRef<HTMLDivElement>();

  loadSprite = async () => {
    const r = await fetch(spriteSvgPublicPath);
    const sprite = await r.text();
    const spriteBlock = this.spriteBlockRef.current;
    if (spriteBlock === null) {
      return;
    }
    spriteBlock.innerHTML = sprite;
  };

  componentDidMount() {
    const { router, authStore } = this.props;
    this.loadSprite();
    const { name } = router.getState();
    if (!authStore!.isLoggedIn() && !name.startsWith(RouteNameChoices.auth)) {
      router.navigate(RouteNames[RouteNameChoices.auth]);
    }
  }

  render() {
    const { authStore } = this.props;
    const isLoggedIn = authStore!.isLoggedIn();
    return (
      <Fragment>
        <div className={styles.App}>
          <div ref={this.spriteBlockRef} />
          <Row className={styles.row}>
            <Fragment>
              <Sidebar />
              <Content />
            </Fragment>
          </Row>
        </div>
        {isLoggedIn &&
          <ModalContainer />
        }
      </Fragment>
    );
  }
}

export default withRoute(({ router }) => <App router={router} />);
