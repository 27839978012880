import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NotificationAPI } from '@wavesenterprise/uikit';
import { Link } from "react-router5";
import { RouteNameChoices, RouteNames } from "../../routing";
import { withApi } from "../../api/withApi";
import { IApi } from "../../api/interfaces";
import commonStyles from "../Authorization.module.scss";
import styles from './RegisterScreen.module.scss';

interface IProps extends WithTranslation {
  api?: IApi;
  email: string;
}

const _RegisterCompletion = (props: IProps) => {
  const {t, email} = props;
  const resendEmail = async () => {
    try {
      NotificationAPI.success({
        message: t('auth.resend_email_success')
      })
    } catch (e) {

    }
  };

  return <div className={styles.RegisterScreen}>
    <div className={commonStyles.title}>{t('auth.title')}</div>
    <span>{t('auth.register_completion.description', {email})}</span>
    <div className={commonStyles.block32}>
      <Link routeName={RouteNames[RouteNameChoices.login]}>
        <span className={commonStyles.link}>{t('auth.login_to_account')}</span>
      </Link>
    </div>
    <div className={commonStyles.block32}>
      <span className={commonStyles.link} onClick={resendEmail}>{t('auth.resend_email')}</span>
    </div>
  </div>
};

export const RegisterCompletion = withApi(withTranslation()(_RegisterCompletion));
