import React, { useState } from "react";
import { Input, NotificationAPI } from "@wavesenterprise/uikit";
import { withTranslation, WithTranslation } from "react-i18next";
import { ICompanyUser } from "../../../../core/api/interfaces";
import styles from './UserEdit.module.scss'
import { withApi } from "../../../../core/api/withApi";
import { Api } from "../../../../core/api";

interface IUserEditProps extends WithTranslation {
  api: Api;
  id: string;
  user: ICompanyUser;
}

enum Field {
  firstName = 'firstName',
  lastName = 'lastName',
  position = 'position',
  phone = 'phone',
  comment = 'comment'
}

function UserEdit (props: IUserEditProps) {
  const {
    t, api,
    id,
    user: { login, lastName, firstName, position, phone, comment }
  } = props

  const initialProfileState = {
    login,
    lastName,
    firstName,
    position,
    phone,
    comment
  }

  const [syncedProfile, setSyncedProfile] = useState(initialProfileState)

  const updateUserField = async (field: Field, value: string) => {
    try {
      await api.updateCompanyUser(id, {
        [field]: value
      })
      NotificationAPI.success({
        message: t('users.user_edit.update_success_message')
      })
    } catch (e) {
      NotificationAPI.success({
        message: t('users.user_edit.update_failed_message')
      })
    }
  }

  const onBlur = (e: any, field: Field) => {
    const value = e.target.value
    if (value !== syncedProfile[field]) {
      setSyncedProfile({
        ...syncedProfile,
        [field]: value
      })
      updateUserField(field, value)
    }
  }

  return <div>
    <div className={styles.FormItem}>
      <Input
        disabled
        label={t('user_form.login')}
        defaultValue={initialProfileState.login}
      />
    </div>
    <div className={styles.FormItem}>
      <Input
        label={t('user_form.last_name')}
        defaultValue={initialProfileState.lastName}
        onBlur={(e: any) => onBlur(e, Field.lastName)}
      />
    </div>
    <div className={styles.FormItem}>
      <Input
        label={t('user_form.first_name')}
        defaultValue={initialProfileState.firstName}
        onBlur={(e: any) => onBlur(e, Field.firstName)}
      />
    </div>
    <div className={styles.FormItem}>
      <Input
        label={t('user_form.job_title')}
        defaultValue={initialProfileState.position}
        onBlur={(e: any) => onBlur(e, Field.position)}
      />
    </div>
    <div className={styles.FormItem}>
      <Input
        label={t('user_form.phone_number')}
        defaultValue={initialProfileState.phone}
        onBlur={(e: any) => onBlur(e, Field.phone)}
      />
    </div>
    <div className={styles.FormItem}>
      <Input
        label={t('user_form.comment')}
        defaultValue={initialProfileState.comment}
        onBlur={(e: any) => onBlur(e, Field.comment)}
      />
    </div>
  </div>
}

export default withApi(withTranslation()(UserEdit))

