import createRouter, { Route } from 'router5';
import browserPlugin from 'router5-plugin-browser';

export const buildRouteName = (...routeNames: string[]) => {
  return routeNames.join('.');
};

export enum RouteNameChoices {
  auth = 'auth',
  login = 'login',
  register = 'register',
  restore = 'restore',
  resetPassword = 'resetPassword',
  setPassword = 'setPassword',
  activate = 'activate',
  users = 'users',
  companies = 'companies',
  createCompany = 'companies.create',
  updateCompany = 'update',
  companyDetails = 'companies.details',
  companyLicenses = 'licenses',
  companyUsers = 'companyUsers',
  companyInfo = 'companyInfo',
  licenseDetails = 'licenseDetails',
  licenseActivate = 'licenseActivate',
  licenseCancel = 'licenseCancel',
  licenseIssue = 'issue',
  userDetails = 'userDetails',
  settings = 'settings',
  settingsMain = 'main',
  settingsInfo = 'info',
  createNewUser = 'createNewUser'
}

export const RouteNames = {
  [RouteNameChoices.auth]: RouteNameChoices.auth,
  [RouteNameChoices.login]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.login),
  [RouteNameChoices.register]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.register),
  [RouteNameChoices.restore]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.restore),
  [RouteNameChoices.resetPassword]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.restore, RouteNameChoices.resetPassword),
  [RouteNameChoices.setPassword]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.setPassword),
  [RouteNameChoices.activate]: buildRouteName(RouteNameChoices.auth, RouteNameChoices.activate),
  [RouteNameChoices.users]: RouteNameChoices.users,
  [RouteNameChoices.companies]: RouteNameChoices.companies,
  [RouteNameChoices.createCompany]: RouteNameChoices.createCompany,
  [RouteNameChoices.updateCompany]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyInfo, RouteNameChoices.updateCompany),
  [RouteNameChoices.companyDetails]: RouteNameChoices.companyDetails,
  [RouteNameChoices.companyLicenses]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyLicenses),
  [RouteNameChoices.companyUsers]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyUsers),
  [RouteNameChoices.companyInfo]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyInfo),
  [RouteNameChoices.licenseIssue]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyLicenses, RouteNameChoices.licenseIssue),
  [RouteNameChoices.licenseDetails]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyLicenses, RouteNameChoices.licenseDetails),
  [RouteNameChoices.licenseActivate]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyLicenses, RouteNameChoices.licenseActivate),
  [RouteNameChoices.licenseCancel]: buildRouteName(RouteNameChoices.companyDetails, RouteNameChoices.companyLicenses, RouteNameChoices.licenseCancel),
  [RouteNameChoices.settings]: RouteNameChoices.settings,
  [RouteNameChoices.settingsMain]: buildRouteName(RouteNameChoices.settings, RouteNameChoices.settingsMain),
  [RouteNameChoices.settingsInfo]: buildRouteName(RouteNameChoices.settings, RouteNameChoices.settingsInfo),
  [RouteNameChoices.userDetails]: buildRouteName(RouteNameChoices.users, RouteNameChoices.userDetails),
  [RouteNameChoices.createNewUser]: buildRouteName(RouteNameChoices.users, RouteNameChoices.createNewUser)

};

const routes: Route[] = [
  {
    name: RouteNameChoices.auth,
    path: '/auth',
    children: [{
      name: RouteNameChoices.login,
      path: '/login?email',
    }, {
      name: RouteNameChoices.register,
      path: '/register',
    }, {
      name: RouteNameChoices.setPassword,
      path: '/set-password&token&email',
    }, {
      name: RouteNameChoices.activate,
      path: '/activate?token',
    }, {
      name: RouteNameChoices.restore,
      path: '/restore',
      children: [{
        name: RouteNameChoices.resetPassword,
        path: '/reset-password&token&email'
      }]
    }],
  },
  {
    name: RouteNameChoices.users,
    path: '/users?search&companyId',
    children: [{
      name: RouteNameChoices.userDetails,
      path: '/:userId',
    }, {
      name: RouteNameChoices.createNewUser,
      path: '/create',
    }],
  },
  {
    name: RouteNameChoices.companies,
    path: '/companies?search&status&tag',
    children: [],
  },
  {
    name: RouteNameChoices.createCompany,
    path: '/create',
    children: [],
  },
  {
    name: RouteNameChoices.companyDetails,
    path: '/:id',
    forwardTo: RouteNames[RouteNameChoices.companyLicenses],
    children: [{
      name: RouteNameChoices.companyLicenses,
      path: '/licenses?search&status&type&period&nodeOwnerAddress&tag',
      children: [{
        name: RouteNameChoices.licenseDetails,
        path: '/:licenseId',
      }, {
        name: RouteNameChoices.licenseIssue,
        path: '/create',
      }, {
        name: RouteNameChoices.licenseActivate,
        path: '/activate/:licenseActivateId',
      }, {
        name: RouteNameChoices.licenseCancel,
        path: '/cancel/:licenseId',
      }],
    }, {
      name: RouteNameChoices.companyUsers,
      path: '/users',
      children: [],
    }, {
      name: RouteNameChoices.companyInfo,
      path: '/info',
      children: [
        {
          name: RouteNameChoices.updateCompany,
          path: '/update',
          children: [],
        },
      ],
    }],
  },
  {
    name: RouteNameChoices.settings,
    path: '/settings',
    children: [{
      name: RouteNameChoices.settingsMain,
      path: '/main'
    }, {
      name: RouteNameChoices.settingsInfo,
      path: '/info'
    }],
  },
];

export const router = createRouter(routes, {
  defaultRoute: RouteNames[RouteNameChoices.auth],
});

router.usePlugin(browserPlugin({
    base: '/admin-license',
  },
));

router.start();
