import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { action, observable } from "mobx";
import { Link } from "react-router5";
import { actionAsync } from "mobx-utils";
import { Button, Form, Input, NotificationAPI } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { ClientLocale, IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { IAuthStore } from '../stores/AuthStore';
import { RouteNameChoices, RouteNames } from "../../routing";
import { validatePasswordByRules } from '../utils/password-validator'
import commonStyles from "../Authorization.module.scss";
import styles from './RegisterScreen.module.scss';
import { AuthError } from "../../api/error-codes";

const FormItem = Form.Item;

interface IProps extends WithTranslation {
  authStore?: IAuthStore;
  api?: IApi;
  form: any; // todo we does not have type yet
  onRegisterSuccess: (email: string) => void;
}

enum FormFields {
  email = 'email',
  password = 'password',
  confirmPassword = 'confirmPassword'
}

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _RegisterForm extends Component<IProps> {
  @observable inProgress = false;

  @action
  setInProgress = (inProgress: boolean) => {
    this.inProgress = inProgress
  };

  validatorPassword = async (rule: any, value: string, cb: Function) => {
    const errorMessage = validatePasswordByRules(value);
    if (errorMessage) {
      cb(errorMessage)
    } else {
      cb()
    }
  };

  validateToNextPassword = (rule: any, value: string, cb: Function) => {
    this.props.form.validateFields([FormFields.confirmPassword], { force: true });
    cb()
  };

  compareToFirstPassword = (rule: any, value: string, cb: Function) => {
    const {form: {getFieldValue}} = this.props;
    if (value && value !== getFieldValue(FormFields.password)) {
      cb(true)
    } else {
      cb()
    }
  };

  @actionAsync
  onSubmit = async () => {
    const {api, t, form: {validateFields}, onRegisterSuccess} = this.props;
    try {
      this.setInProgress(true);
      const {email: username, password} = await validateFields({force: true});
      const locale = i18next.language === ClientLocale.ru
        ? ClientLocale.ru
        : ClientLocale.en;
      await api!.createAccount({username, password, locale});
      if (onRegisterSuccess) {
        onRegisterSuccess(username)
      }
    } catch (e) {
      const {response} = e;
      if (response) {
        const {data: {errors}} = response;
        const key = 'register';
        if (errors === AuthError.UserAlreadyExists) {
          NotificationAPI.error({
            key,
            message: t('auth.register.user_already_exists')
          })
        } else {
          NotificationAPI.error({
            key,
            message: t('auth.register.common_error')
          })
        }
      }
    } finally {
      this.setInProgress(false)
    }
  };

  render() {
    const { t, form: { getFieldDecorator }} = this.props;
    return (
      <div className={styles.RegisterScreen}>
        <div className={commonStyles.title}>{t('auth.title')}</div>
        <span>{t('auth.register.description')}</span>
        <div className={commonStyles.block32}>
          <Form>
            <FormItem>
              {getFieldDecorator(FormFields.email, {
                validateFirst: true,
                // initialValue: '',
                rules: [{
                    required: true,
                    message: t('required_field')
                  }, {
                    type: 'email',
                    message: t('auth.email_incorrect')
                  }],
                validateTrigger: 'onBlur'
              })(<Input placeholder={t('auth.email_placeholder')} onPressEnter={this.onSubmit} />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator(FormFields.password, {
                // initialValue: '',
                validateFirst: true,
                validateTrigger: 'onBlur',
                rules: [{
                    required: true,
                    message: t('required_field')
                  }, {
                    validator: this.validatorPassword
                  }, {
                    validator: this.validateToNextPassword
                  }]
              })(
                <Input
                  placeholder={t('auth.password')}
                  type="password"
                  onPressEnter={this.onSubmit}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator(FormFields.confirmPassword, {
                // initialValue: '',
                validateFirst: true,
                validateTrigger: 'onBlur',
                rules: [{
                    required: true,
                    message: t('required_field')
                  }, {
                    validator: this.validatorPassword
                  }, {
                    validator: this.compareToFirstPassword,
                    message: t('auth.password_confirmation_error')
                  }]
              })(
                <Input
                  placeholder={t('auth.password_confirmation')}
                  type="password"
                  onPressEnter={this.onSubmit}
                />
              )}
            </FormItem>
            <FormItem>
              <Button type="primary" onClick={this.onSubmit} disabled={this.inProgress}>
                {t('auth.register.continue')}
              </Button>
            </FormItem>
          </Form>
          <div className={commonStyles.block32}>
            <Link routeName={RouteNames[RouteNameChoices.restore]}>
              <span className={commonStyles.link}>{t('auth.restore_account')}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const RegisterForm = withTranslation()(Form.create()(_RegisterForm));
