import { IUserProps } from "./interfaces";
import React, { useState } from "react";
import { Button, NotificationAPI, SidePanelDetailsList, SpinContainer } from "@wavesenterprise/uikit";
import styles from "./Users.module.scss";
import CountdownCircle from "../../../../../common/countdown-circle/CountdownCircle";

function UserInfo(props: IUserProps) {
  const {
    t, api, data: { user }, users, companyId,
    setIsUpdating, addUser, removeUser
  } = props;

  const userIndex = users.findIndex(userData => userData.user.login === user.login);

  const [inProgress, setInProgress] = useState(false);
  const [timeoutId, setTimeoutId] = useState();

  const notificationKey = `remove`;
  const duration = 10;

  const cancelRemoveUser = async (userIndex: number) => {
    const companyUser = await api!.addCompanyUser(companyId, user);
    addUser(companyUser, userIndex);
    NotificationAPI.close(notificationKey)
  };

  const handleRemoveUser = async () => {
    try {
      setInProgress(true);
      await api!.removeCompanyUser(companyId, user.login);
      removeUser(user.id);
      NotificationAPI.destroy();
      NotificationAPI.success({
        duration,
        key: notificationKey,
        message: t('company_card.users.remove_success'),
        btn: (<div>
          <div className={styles.inline}>
            <Button type={'primary'} onClick={() => cancelRemoveUser(userIndex)}>
              {t('company_card.users.cancel_remove')}
            </Button>
          </div>
          <div className={styles.inline}>
            <CountdownCircle duration={duration} />
          </div>
        </div>)
      });

      // TODO fix error with close notification in UI-kit
      clearTimeout(timeoutId);
      const id = setTimeout(() => NotificationAPI.close(notificationKey), duration * 1000);
      setTimeoutId(id)
    } catch (e) {
    } finally {
      setInProgress(false);
    }
  };

  const {firstName, lastName, position, phone, comment} = user;
  const items = [{
    key: t('user_form.first_name'),
    value: firstName,
    viewType: 'multiple'
  }, {
    key: t('user_form.last_name'),
    value: lastName,
    viewType: 'multiple'
  },{
    key: t('user_form.job_title'),
    value: position,
    viewType: 'multiple'
  }, {
    key: t('user_form.phone_number'),
    value: phone,
    viewType: 'multiple'
  }, {
    key: t('user_form.comment'),
    value: comment,
    viewType: 'multiple'
  }];

  return <div>
    <div className={styles.UserDetails}>
      <SidePanelDetailsList items={items} />
    </div>
    <div className={styles.Controls}>
      <div>
        {inProgress &&
        <div className={styles.ControlDisabled}>
            <SpinContainer size={'small'} />
        </div>
        }
        <div
          className={inProgress ? styles.ControlDisabled : styles.Control}
          onClick={inProgress ? () => {} : handleRemoveUser}
        >
          {t('company_card.users.remove')}
        </div>
        <div
          className={inProgress ? styles.ControlDisabled : styles.Control}
          onClick={inProgress ? () => {} : () => setIsUpdating(true)}
        >
          {t('company_card.users.edit_info')}
        </div>
      </div>
    </div>
  </div>
}

export default UserInfo
