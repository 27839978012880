import fileDownload from "js-file-download";
import React, { Component } from 'react';
import { action, observable, computed, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  SidePanel,
  SidePanelDetailsList,
  SpinContainer,
  Steps,
  Button,
  Form, Input, NotificationAPI, SelectTags
} from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IAuthStore } from '../../../core/authorization/stores/AuthStore';
import { IModalsStore } from '../../stores/ModalStore';
import {
  IActivateLicenseResponse,
  IApi, ICompanyBasic, ILicense, LicenseStatus
} from '../../../core/api/interfaces';
import { withApi } from '../../../core/api/withApi';
import { Router } from "router5";
import moment from "moment";
import styles from './LicenseActivate.module.scss'
import InfoMessage from "../../../common/info-message";
import { getDatesDiff } from "../../../common/dateUtils";

interface IProps extends WithTranslation {
  router: Router;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  api?: IApi;
  visible: boolean;
  onCancel: () => void;
  form: any;
}

enum StepChoices {
  'check' = 0,
  'activation' = 1
}


@withApi
@inject(({ modalsStore, authStore }: IStore) => ({ modalsStore, authStore }))
@observer
class _LicenseActivate extends Component<IProps> {
  @observable isLoading = false;
  @observable isActivating = false;
  @observable license: ILicense | null = null;
  @observable company: ICompanyBasic | null = null;
  @observable currentStep = StepChoices.check;
  @observable nodeOwnerAddress = null;
  @observable tags: string[] = [];
  @observable companyTags: string[] = [];
  @observable activatedLicense: IActivateLicenseResponse | null = null;

  commonSidePanelProps = {
    visible: true,
    theme: 'black',
    icon: 'node-add-32',
    onCancel: this.props.onCancel
  };

  @computed get sidePanelProps () {
    const {t} = this.props;
    const mainTitle = t(`license_activate.title`);
    const subTitle = this.company ? this.company.shortName : t('license_activate.sub_title');
    return {
      ...this.commonSidePanelProps,
      mainTitle,
      subTitle
    };
  }

  componentDidMount() {
    this.getInitialData()
  }

  getInitialData = async () => {
    this.setIsLoading(true);
    const {api, router} = this.props;
    const {params: {id: companyId, licenseActivateId}} = router.getState();
    try {
      const license = await api!.getLicenseById(companyId, licenseActivateId);
      const company = await api!.getCompanyById(companyId);
      const companyTags = await api!.getTags({companyId});
      runInAction(() => {
        this.license = license
        this.company = company
        this.companyTags = companyTags
        this.tags = license.tags
      })
    } catch (e) {
      console.error('Init error:', e)
    } finally {
      this.setIsLoading(false);
    }
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setCurrentStep = (step: StepChoices) => {
    this.currentStep = step;
  };

  @action
  onChangeTags = (tags: string[]) => {
    this.tags = tags
  }

  onContinueClicked = async () => {
    const {form: {validateFields}, api, router, t} = this.props;
    const { params: { id, licenseActivateId } } = router.getState();
    // if (this.tags.length === 0) {
    //   NotificationAPI.info({
    //     message: 'Введите теги'
    //   })
    //   return
    // }
    try {
      const {nodeOwnerAddress} = await validateFields({force: true});
      this.setIsLoading(true);
      try {
        const activatedLicense = await api!.activateLicense(id, {
          nodeOwnerAddress,
          id: licenseActivateId,
          tags: this.tags
        });
        runInAction(() => {
          this.activatedLicense = activatedLicense;
          this.nodeOwnerAddress = nodeOwnerAddress;
          this.setCurrentStep(StepChoices.activation);
        });
      } catch (e) {
        if (e.response?.status !== 200) {
          NotificationAPI.error({
            message: t('license_activate.activation_error_message'),
            description: t('license_activate.activation_error_description'),
          });
        }
      }
      this.setIsLoading(false);
    } catch (e) {
      console.log('Validation error', e)
    }
  };

  onDownloadClick = async () => {
    const { router } = this.props;
    const { params: { id, licenseActivateId } } = router.getState();
    const file = await this.props.api!.downloadLicense({
      licenseId: licenseActivateId,
      companyId: id
    });
    fileDownload(JSON.stringify(file), 'node.license');
  };

  onCloseClicked = () => this.props.onCancel();

  getStepsConfig = () => {
    const {t} = this.props;
    return [{
      key: StepChoices.check,
      title: t('steps.check'),
      icon: 'eye'
    }, {
      key: StepChoices.activation,
      title: t('steps.activation'),
      icon: 'eye'
    }]
  };

  getCheckContent = () => {
    const {t, form: { getFieldDecorator }} = this.props;
    if (this.license) {
      const {type, validTo, product, status } = this.license;
      let validToCaption = validTo
        ? moment(validTo).format(t('date_format'))
        : t('license_expiration.unlimited');
      if (validTo) {
        const diff = getDatesDiff(validTo);
        if (status !== LicenseStatus.expired && diff < 30) {
          validToCaption += '. ' +
            t("company_card.licenses.expire_days_left", { count: diff }) +
            '.';
        }
      }
      const items = [
        {
          key: t('license_details.company'),
          value: this.company?.shortName,
          viewType: 'multiple'
        },
        {
          key: t('license_details.product'),
          value: t(`products.${product}`),
          viewType: 'multiple'
        },
        {
          key: t('license_details.license_type'),
          value: t(`license_types.${type}`)
        },{
          key: t('license_details.expiration'),
          value: validToCaption,
          viewType: 'multiple'
        }
      ];
      const { tags } = this.license
      const maxTagCount = 4
      return <Form>
        <div className={styles.Buttons}>
          <Button type={'primary'} disabled={this.isLoading} onClick={this.onContinueClicked}>{t('buttons.continue')}</Button>
          <Button onClick={this.onCloseClicked}>{t('buttons.cancel')}</Button>
        </div>
        <div className={styles.InfoMessage}>
          <InfoMessage iconType={'warning'} text={t('license_activate.check_message')} />
        </div>
        <div>
          <Form.Item>
            <div className={styles.TagsSelectorContainer} id={'activate-tags-container'}>
              <span className={styles.Bold}>
                {t('tags_label', { count: maxTagCount })}
              </span>
              <SelectTags
                getPopupContainer={() => document.getElementById('activate-tags-container')}
                placeholder={t('tags_placeholder')}
                defaultValue={tags}
                defaultOptions={this.companyTags}
                maxTagCount={maxTagCount}
                onChange={this.onChangeTags}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <div className={styles.Block32}>
            <span className={styles.Bold}>
              {t('license_activate.node_owner_address')}
            </span>
              {getFieldDecorator('nodeOwnerAddress', {
                validateFirst: true,
                // initialValue: '',
                rules: [{
                  required: true,
                  message: t('license_activate.node_owner_address_placeholder')
                }],
                validateTrigger: 'onBlur'
              })(<Input
                placeholder={t('license_activate.node_owner_address_placeholder')} />)}
            </div>
          </Form.Item>
        </div>
        <div className={styles.CompanyInfo}>
          <SidePanelDetailsList items={items} />
        </div>
      </Form>
    }
    return null;
  };

  getActivationContent = () => {
    const {t} = this.props;
    const items = [
      {
        key: t('license_activate.activation_date'),
        value: moment(this.activatedLicense?.activated).format(t('date_format'))
      }, {
        key: t('license_activate.user'),
        value: this.activatedLicense?.activatedBy
      }
    ];
    return <div>
      <div className={styles.Buttons}>
        <Button type={'primary'} onClick={this.onDownloadClick}>{t('license_activate.download_license')}</Button>
        <Button onClick={this.onCloseClicked}>{t('buttons.close')}</Button>
      </div>
      <div className={styles.InfoMessage}>
        <InfoMessage iconType={'done'} text={t('license_activate.activation_success_message')} />
      </div>
      <div className={styles.CompanyInfo}>
        <SidePanelDetailsList items={items} />
      </div>
      <div className={styles.helpMessage}>
        <div>
          {t('license_activate.help_message_part_1')}{' '}<span className={styles.bold}>{'license-file'}</span>{' '}
          {t('license_activate.help_message_part_2')}
        </div>
        <div className={styles.block16}>
          {t('license_activate.help_message_part_3')}{' '}<span className={styles.bold}>{'POST /licenses/upload'}</span>{'.'}
        </div>
      </div>
    </div>
  };

  getContent = () => {
    const {t} = this.props;
    if (this.license) {
      if (this.currentStep === StepChoices.check) {
        return this.getCheckContent();
      } else if (this.currentStep === StepChoices.activation) {
        return this.getActivationContent();
      }
      return null;
    } else if (this.isLoading) {
      return <SpinContainer size={'medium'} />
    } else {
      return <div className={styles.Bold}>{t('license_details.error_on_loading')}</div>
    }
  };

  render() {
    return (
      <SidePanel {...this.sidePanelProps}>
        <div className={styles.Content}>
          <div className={styles.Info}>
            {this.getContent()}
          </div>
          <div className={styles.Steps}>
            <Steps current={this.currentStep}>
              {this.getStepsConfig()}
            </Steps>
          </div>
        </div>
      </SidePanel>
    );
  }
}

export const LicenseActivate = Form.create()(withTranslation()(_LicenseActivate));
