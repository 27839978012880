import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IApi, ICompany, UserRole } from '../../../../../core/api/interfaces';
import { withApi } from '../../../../../core/api/withApi';
import { Button, SidePanelDetailsList } from '@wavesenterprise/uikit';
import styles from './CompanyInfo.module.scss';
import CountriesList from '../../../../../common/countriesList';
import { IModalsStore, ModalNameChoices } from '../../../../stores/ModalStore';
import { IStore } from '../../../../../../app/stores';
import { IAuthStore } from '../../../../../core/authorization/stores/AuthStore';

interface ICompanyInfoProps extends WithTranslation {
  api?: IApi;
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  company: ICompany | null;
}

@withApi
@inject(({ modalsStore, authStore }: IStore) => ({
  modalsStore,
  authStore,
}))

@observer
class _CompanyInfo extends Component<ICompanyInfoProps> {

  editCompany = (id: string) => this.props.modalsStore!.showModal(ModalNameChoices.updateCompany, { id });

  render () {
    const { t, i18n: { language }, company, authStore } = this.props;
    if (!company) {
      return null;
    }
    const { id, fullName, shortName, inn, countryCode } = company;
    const userRoles = authStore!.getUserRoles();
    const countries = CountriesList(language);
    const country = countries.find(({ value }) => value === countryCode);
    const items = [{
      key: t('company.inn_short'),
      value: inn,
    }, {
      key: t('company.short_name'),
      value: shortName,
      viewType: 'multiple',
    }, {
      key: t('company.full_name'),
      value: fullName,
      viewType: 'multiple',
    }, {
      key: t('company.country'),
      value: country ? country.text : '',
      viewType: 'multiple',
    }];
    return (<React.Fragment>
      {userRoles.includes(UserRole.licenseAdmin) && (
        <div>
          <Button
            onClick={() => this.editCompany(id)}>
            {t('company_card.update_company')}
          </Button>
        </div>
      )}
      <div className={styles.Info}>
        <SidePanelDetailsList items={items}/>
      </div>
    </React.Fragment>);
  }
}

export const CompanyInfo = withTranslation()(_CompanyInfo);
