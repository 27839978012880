import React, { useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from "react-i18next";
import { SpinContainer, NotificationAPI } from "@wavesenterprise/uikit";
import { ICompany, ICompanyUser } from "../../../../core/api/interfaces";
import { Api } from "../../../../core/api";
import { withApi } from "../../../../core/api/withApi";
import UserCompaniesList from "./CompaniesList";
import styles from './UserCompanies.module.scss'
import SelectUserCompany from "./SelectCompany";

interface UserCompaniesProps extends WithTranslation {
  api: Api;
  user: ICompanyUser;
}

function UserCompanies (props: UserCompaniesProps) {
  const { t, api, user } = props

  const [isLoading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])

  const onRemoveFromCompany = (companyId: string) => {
    const targetCompany = companies.find(company => company.id === companyId)
    setCompanies(companies.filter(company => company.id !== companyId))
    if (targetCompany) {
      NotificationAPI.success({
        message: t('users.user_removed_success_message', {
          companyName: targetCompany.shortName
        })
      })
    }
  }

  const onAppendToCompany = (company: ICompany) => {
    setCompanies([...companies, company])
    NotificationAPI.success({
      message: t('users.user_add_success_message', {
        companyName: company.shortName
      })
    })
  }

  useEffect(() => {
    const getUserCompanies = async () => {
      try {
        setLoading(true)
        const data = await api.getUserCompanies(user.id)
        setCompanies(data)
      } catch (e) {

      } finally {
        setLoading(false)
      }
    }
    getUserCompanies()
  }, [])

  return <div>
    {isLoading &&
      <SpinContainer size={'large'} />
    }
    {!isLoading &&
      <div>
        <div className={styles.CountInfo}>
          { companies.length > 0
            ? t('users.user_companies_count', { count: companies.length })
            : t('users.user_no_companies')
          }
        </div>
        <div className={styles.Block16}>
          <UserCompaniesList
              companies={companies}
              login={user.login}
              onRemoveFromCompany={onRemoveFromCompany}
          />
        </div>
        <div className={styles.Block24}>
            <SelectUserCompany
              login={user.login}
              existedCompanyIds={companies.map(company => company.id)}
              onAppendToCompany={onAppendToCompany}
            />
        </div>
      </div>
    }
  </div>
}

export default withApi(withTranslation()(UserCompanies))
