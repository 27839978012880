export enum FilterType {
  licenseType = 'licenseType',
  activationStatus = 'activationStatus',
  period = 'period',
  search = 'search',
  tag = 'tag'
}

export enum LicenseType {
  all = 'all',
  commercial = 'commercial',
  noncommercial = 'noncommercial',
  mainnet = 'mainnet',
  trial = 'trial'
}

export const AllLicenseTypes = Object.values(LicenseType);

export enum LicenseActivationStatus {
  all = 'all',
  available = 'available',
  activated = 'activated',
  expired = 'expired',
  expiring = 'expiring'
}

export const LicenseTagsAll = 'all';

export const AllLicenseActivationStatuses = Object.values(LicenseActivationStatus);

export enum LicensePeriod {
  all = 'all',
  m1minus = 'less.than.one.month',
  m1to6 = 'one.to.three.month',
  m6to12 = 'six.to.twelve.month',
  m12plus = 'more.than.twelve'
}

export const AllLicensePeriod = Object.keys(LicensePeriod);
