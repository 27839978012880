import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable, runInAction } from "mobx";
import { Router, State } from "router5";
import { Link, withRoute } from "react-router5";
import { actionAsync } from "mobx-utils";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, SpinContainer } from '@wavesenterprise/uikit';
import { IStore } from '../../../../app/stores';
import { IApi } from '../../api/interfaces';
import { withApi } from '../../api/withApi';
import { IAuthStore } from '../stores/AuthStore';
import commonStyles from "../Authorization.module.scss";
import styles from './ActivateScreen.module.scss';
import i18next from "i18next";
import { RouteNameChoices, RouteNames } from "../../routing";
import { AuthError } from "../../api/error-codes";

interface IProps extends WithTranslation {
  route: State;
  router: Router;
  authStore?: IAuthStore;
  api?: IApi;
}

interface AxiosError {
  response: {
    data: {
      status: number;
      errors: AuthError[];
    }
  }
}

interface IContentProps {
  t: i18next.TFunction;
  error?: AxiosError | null
}

const ActivationInProgress = (props: IContentProps) => {
  const {t} = props;
  return <div>
    <div className={styles.inline}>{t('auth.activate.description_in_progress')}</div>
    <div className={styles.inline}>
      <SpinContainer size={'medium'} />
    </div>
  </div>
};

const ActivationError = (props: IContentProps) => {
  const {t, error} = props;
  let message = t('auth.activate.description_error');
  if (error?.response) {
    const {data: { errors }} = error.response;
    if (errors?.includes(AuthError.TokenAlreadyUsed)) {
      message = t('auth.activate.already_verified')
    }
  }
  return <div>
    <span>{message}</span>
  </div>
};

const ActivationSuccess = (props: IContentProps) => {
  const {t} = props;
  return <div>
    {t('auth.activate.description_success')}
  </div>
};

@withApi
@inject(({ authStore }: IStore) => ({ authStore }))
@observer
class _ActivateScreen extends Component<IProps> {
  @observable inProgress = false;
  @observable error = null;

  componentDidMount() {
    const {route: {params: { token }}} = this.props;
    this.activateUser(token)
  }

  @actionAsync
  activateUser = async (code: string) => {
    const {api} = this.props;
    this.inProgress = true;
    try {
      await api!.activateUser({code});
    } catch (e) {
      runInAction(() => {
        this.error = e
      });
    } finally {
      runInAction(() => {
        this.inProgress = false
      });
    }
  };


  render() {
    const { t } = this.props;
    const contentProps = { t };
    const content = this.inProgress
      ? <ActivationInProgress {...contentProps} />
      : this.error
        ? <ActivationError {...contentProps} error={this.error} />
        : <ActivationSuccess {...contentProps} />;
    const buttons = this.inProgress
      ? null
      : <div className={commonStyles.block32}>
      <Link routeName={RouteNames[RouteNameChoices.login]}>
        <Button type={'primary'}>
          {t('auth.login_to_account')}
        </Button>
      </Link>
    </div>;
    return (
      <div className={styles.ActivateScreen}>
        <div className={commonStyles.title}>{t('auth.title')}</div>
        <div className={commonStyles.block32}>
          <div>{content}</div>
          {buttons}
        </div>
      </div>
    );
  }
}

const Translated = withTranslation()(_ActivateScreen);
export const ActivateScreen = withRoute(({ route, router }) => (
  <Translated route={route} router={router} />
));
