import React from 'react'
import { WithTranslation, withTranslation } from "react-i18next";
import { SidePanel } from "@wavesenterprise/uikit";
import { withApi } from "../../../../core/api/withApi";
import { IApi } from "../../../../core/api/interfaces";
import CreateUserForm from "./CreateUserForm";

interface CreateNewUserProps extends WithTranslation {
  onCancel: Function;
  api: IApi
}

function _CreateNewUser (props: CreateNewUserProps) {
  const { t, onCancel } = props

  const sidePanelProps = {
    visible: true,
    icon: 'user-add-32',
    mainTitle: t('users.create_user.title'),
    subTitle: t('users.create_user.subtitle'),
    onCancel
  };

  return <SidePanel {...sidePanelProps}>
    <div style={{ width: '400px' }}>
      <CreateUserForm />
    </div>
  </SidePanel>
}

export const CreateNewUser = withApi(withTranslation()(_CreateNewUser))
