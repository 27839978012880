import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  NotificationAPI,
  SelectTags,
  SidePanel,
  SelectInput,
  Icon,
  SpinContainer
} from "@wavesenterprise/uikit";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { Moment } from "moment";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteContext } from "react-router5/types/types";
import { IStore } from "../../../../app/stores";
import {
  IApi, ICompany,
  ILicenseIssueParams,
  ILicenseType,
  IProduct
} from "../../../core/api/interfaces";
import { withApi } from "../../../core/api/withApi";
import { IAuthStore } from "../../../core/authorization/stores/AuthStore";
import { IConfigStore } from "../../../core/config/stores/ConfigStore";
import { LicenseType } from "../../constants";
import { IModalsStore, ModalNameChoices } from "../../stores/ModalStore";
import styles from "./LicenseIssue.module.scss";
import EnvSelect, { DefaultEnvType } from "../../../common/env-select/EnvSelect";
import FixedButtonsContainer from "../../../common/fixed-buttons-container/FixedButtonsContainer";
import SelectCompany from "../users/createNewUser/SelectCompany";

interface IProps extends WithTranslation, RouteContext {
  modalsStore?: IModalsStore;
  authStore?: IAuthStore;
  configStore?: IConfigStore;
  api?: IApi;
  visible: boolean;
  onCancel: () => void;
  form: any;
}

const initialLicenseValue = LicenseType.commercial;

@withApi
@inject(({ modalsStore, authStore, configStore }: IStore) => ({
  modalsStore,
  authStore,
  configStore
}))
@observer
class _LicenseIssue extends Component<IProps> {
  @observable licenseType = initialLicenseValue;
  @observable isLoading = true;
  @observable companyName = "";
  @observable companyTags: string[] = [];
  @observable.ref licenseTypes: ILicenseType[] = [];
  @observable.ref products: IProduct[] = [];
  @observable.ref companies: ICompany[] = [];
  @observable isSplitToGroupsChecked = false;

  getInitialGroup = () => {
    const companyId = this.props.router.getState().params.id;
    return {
      index: 1,
      count: '0',
      companyId,
      dateFrom: null,
      dateTo: null,
      tags: [],
      projectName: '',
      envType: DefaultEnvType,
      comment: '',
      isUnlimitedChecked: false
    }
  }

  @observable groups = [this.getInitialGroup()]

  commonSidePanelProps = {
    visible: true,
    theme: "black",
    icon: "node-add-32",
    onCancel: this.props.onCancel
  };

  @computed get sidePanelProps() {
    const { t } = this.props;
    return {
      ...this.commonSidePanelProps,
      mainTitle: t("issue_license.title"),
      subTitle: this.companyName || t("issue_license.sub_title")
    };
  }

  periodicDateToDefaultValue (index: number): Moment | void {
    const licenseTypeValue = this.licenseType;
    const group = this.groups.find((_, i) => i === index)
    if (group) {
      const { dateFrom } = group
      if (licenseTypeValue !== undefined && this.licenseTypes.length) {
        const currentLicense = this.licenseTypes.find(
          license => license.type === licenseTypeValue
        );
        if (
          currentLicense !== undefined &&
          currentLicense.period !== null &&
          dateFrom !== null
        ) {
          // @ts-ignore
          const temp = dateFrom.clone();
          return temp.date(temp.date() + currentLicense.period);
        }
      }
      return;
    }
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = async () => {
    const { api, router } = this.props;
    const {
      params: { id }
    } = router.getState();
    try {
      this.setIsLoading(true);
      const company = await api!.getCompanyById(id);
      this.setCompanyName(company.shortName);
      await this.fetchLicenseTypes();
      await this.fetchProducts();
      await this.fetchCompanies();
      await this.fetchCompanyTags();
    } catch (e) {
      console.log('Fetch initial data error:', e)
    } finally {
      this.setIsLoading(false);
    }
  };

  async fetchLicenseTypes() {
    try {
      const licenseTypes = await this.props.api!.getLicenseTypes();
      runInAction(() => {
        this.licenseTypes = licenseTypes;
        console.log('licenseTypes', licenseTypes)
      });
    } catch (e) {}
  }

  async fetchProducts() {
    try {
      const products = await this.props.api!.getProducts();
      runInAction(() => {
        this.products = products;
      });
    } catch (e) {}
  }

  async fetchCompanyTags() {
    const { api, router } = this.props;
    const {
      params: { id: companyId }
    } = router.getState();
    try {
      const companyTags = await api!.getTags({companyId});
      runInAction(() => {
        this.companyTags = companyTags;
      });
    } catch (e) {}
  }

  async fetchCompanies () {
    const { api } = this.props
    try {
      const data = await api?.getCompanies({ limit: 100, offset: 0 })
      if (data) {
        runInAction(() => {
          this.companies = data.companies
        })
      }
    } catch (e) {
      console.log('Cannot get companies list', e)
    }
  }

  @action
  setCompanyName = (companyName: string) => {
    this.companyName = companyName;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onIssueClicked = async () => {
    const { t, router, api, modalsStore } = this.props;

    let totalCount = 0

    try {
      let { licenseCount } = await this.props.form.validateFields()
      totalCount = licenseCount
    } catch (e) {
      console.log('Validation error: ', e)
      return false
    }

    const distributedCount = this.groups.reduce((acc, nextItem) => {
      const count = +nextItem.count
      return acc + count
    }, 0)

    if (this.isSplitToGroupsChecked) {
      if (distributedCount < totalCount) {
        NotificationAPI.error({
          message: t('issue_license.error_licenses_left_title'),
          description: <div>
            <div><span>{t('issue_license.error_licenses_left_0')}</span><span className={styles.Bold}>{totalCount}</span></div>
            <div><span>{t('issue_license.error_licenses_left_1')}</span><span className={styles.Bold}>{distributedCount}</span></div>
            <div>
              <span>{t('issue_license.error_licenses_left_2')}</span>
              <span className={styles.Bold}>{(totalCount - distributedCount)}</span>
              <span>{t('issue_license.error_licenses_left_3')}</span>
            </div>
          </div>
        })
        return false
      } else if (distributedCount > totalCount) {
        NotificationAPI.error({
          message: t('issue_license.error_licenses_over_title'),
          description: <div>
            <div><span>{t('issue_license.error_licenses_left_0')}</span><span className={styles.Bold}>{totalCount}</span></div>
            <div><span>{t('issue_license.error_licenses_left_1')}</span><span className={styles.Bold}>{distributedCount}</span></div>
          </div>
        })
        return false
      }
    }

    const groupsWithoutDateFrom = this.groups.filter(group => !group.dateFrom)
    if (groupsWithoutDateFrom.length > 0) {
      let description = null
      if (this.isSplitToGroupsChecked) {
        description = <div>
          <div>
            <span>{t('issue_license.check_groups')}</span>
            <span className={styles.Bold}>{groupsWithoutDateFrom.map(group => t('issue_license.group_title_quoted', { name: group.index })).join(', ')}</span>
          </div>
        </div>
      }
      NotificationAPI.error({
        message: t('issue_license.date_from_required_error_message'),
        description
      })
      return
    }

    const groupsWithoutDateTo = this.groups.filter(group => !group.dateTo && !group.isUnlimitedChecked && this.periodicDateToDefaultValue(group.index) === undefined)
    if (groupsWithoutDateTo.length > 0) {
      let description = null
      if (this.isSplitToGroupsChecked) {
        description = <div>
          <div>
            <span>{t('issue_license.check_groups')}</span>
            <span className={styles.Bold}>{groupsWithoutDateTo.map(group => t('issue_license.group_title_quoted', { name: group.index })).join(', ')}</span>
          </div>
        </div>
      }
      NotificationAPI.error({
        message: t('issue_license.date_to_required_error_message'),
        description
      })
      return
    }

    try {
      const productId = this.products[0]!.id
      const routeCompanyId = router.getState().params.id;

      let failedIndexes = await Promise.all(this.groups.map(async (group): Promise<any> => {
        const {count, dateFrom, dateTo, tags, companyId, projectName, envType, comment} = group
        const quantity = this.isSplitToGroupsChecked ? parseInt(count) : totalCount
        const data: ILicenseIssueParams = {
            productId,
            quantity,
            // @ts-ignore
            validFrom: dateFrom.startOf('day').toISOString(),
            type: this.licenseType,
            tags,
            projectName,
            envType,
            comment,
          };
          if (dateTo && this.periodicDateToDefaultValue(group.index) === undefined) {
            // @ts-ignore
            data.validTo = dateTo.endOf('day').toISOString();
          }
          try {
            await api!.licenseIssue(companyId, data)
            console.log('Issue group success', group)
          } catch (e) {
            console.log('Issue error', e, 'for group', group)
            return group.index
          }
      })
      )
      failedIndexes = failedIndexes.filter(index => index !== undefined)
      console.log('failedIndexes:', failedIndexes)

      // Success message
      if (failedIndexes.length < this.groups.length) {
        const successGroups = this.groups.filter(group => !failedIndexes.includes(group.index))
        const uniqueCompaniesLicenses = successGroups.reduce((acc: any, nextItem: { companyId: string, count: string }) => {
          if (acc[nextItem.companyId]) {
            acc[nextItem.companyId] = acc[nextItem.companyId] + +nextItem.count
          } else {
            acc[nextItem.companyId] = this.isSplitToGroupsChecked ? +nextItem.count : totalCount
          }
          return acc
        }, {})
        NotificationAPI.success({
          key: 'issue_result',
          duration: 0,
          message: t('issue_license.success_issue_title'),
          description: <div>
            {Object.entries(uniqueCompaniesLicenses).map(([companyId, count]: any) => {
              const company = this.companies.find(company => company.id === companyId)
              return <div>{company!.shortName} {':'} <span className={styles.Bold}>{count}</span></div>
            })}
          </div>
        })
      }

      if (failedIndexes.length > 0) {
        runInAction(() => {
          this.groups = this.groups.filter(group => failedIndexes.includes(group.index))
        })
        let description = null
        if (this.isSplitToGroupsChecked) {
          description = <div>
            <div>
              <span>{t('issue_license.check_groups')}</span>
              <span className={styles.Bold}>{failedIndexes.map(index => t('issue_license.group_title_quoted', { name: index })).join(', ')}</span>
            </div>
          </div>
        }
        NotificationAPI.error({
          key: 'issue_error',
          duration: 0,
          message: t('issue_license.error_issue_licenses'),
          description
        })
      } else {
        modalsStore!.showModal(ModalNameChoices.companyDetails, { id: routeCompanyId })
      }
    } catch (e) {
      console.log('Error on issue licenses:', e)
    }
  };

  @action
  onChangeLicenseType = (id: LicenseType) => {
    this.licenseType = id;
    if (id === LicenseType.trial) {
      this.groups = this.groups.map(group => {
        return {
          ...group,
          isUnlimitedChecked: false
        }
      })
    }
  };

  @action
  onSplitToGroupsChanged = (isChecked: boolean) => {
    this.isSplitToGroupsChecked = isChecked;

    if (!isChecked) {
      this.groups = this.groups.filter((_, index) => index === 0)
    }
  };

  getPickerButtonText = (date: any) => {
    const { t } = this.props;
    return date
      ? date.format(t("date_format"))
      : t("issue_license.select_date");
  };

  onDateFromApply = (index: number, date: Moment | null) => {
    this.updateGroup(index, 'dateFrom', date)
  };

  onDateToApply = (index: number, date: Moment | null) => {
    this.updateGroup(index, 'dateTo', date)
  };

  onChangeTags = (index: number, tags: string[]) => {
    this.updateGroup(index, 'tags', tags)
  }

  onSelectEnvType = (index: number, id: string) => {
    this.updateGroup(index, 'envType', id)
  }

  onChangeProjectName = (index: number, value: string) => {
    this.updateGroup(index, 'projectName', value)
  }

  onChangeGroupLicensesCount = (index: number, value: string) => {
    this.updateGroup(index, 'count', value)
  }

  onChangeComment = (index: number, value: string) => {
    this.updateGroup(index, 'comment', value)
  }

  onExpirationCheckboxChanged = (index: number, isChecked: boolean) => {
    this.updateGroup(index, 'isUnlimitedChecked', isChecked)
  };

  onChangeSelectedCompanyId = (index: number, id: string) => {
    this.updateGroup(index, 'companyId', id)
  }

  @action
  updateGroup = (index: number, property: any, value: any) => {
    this.groups = this.groups.map(group => {
      if (group.index !== index) {
        return group
      }
      return {
        ...group,
        [property]: value
      }
    })
  }

  @action
  addNewGroup = () => {
    const sortedByIndex = this.groups.sort((a, b) => b.index - a.index)
    this.groups.push({
      ...this.getInitialGroup(),
      index: sortedByIndex[0].index + 1
    })
  }

  @action
  onDeleteGroupClicked = (index: number) => {
    if (this.groups.length > 1) {
      this.groups = this.groups.filter(group => group.index !== index)
    } else {
      this.groups = [this.getInitialGroup()]
      this.onSplitToGroupsChanged(false)
    }
  }

  render() {
    const {
      t,
      form: { getFieldDecorator },
      onCancel
    } = this.props;
    const datePickerLabels = {
      apply: t("common:date_picker.apply"),
      cancel: t("common:date_picker.cancel"),
      reset: t("common:date_picker.reset")
    };
    const maxTagCount = 4

    if (this.isLoading) {
      return <SidePanel {...this.sidePanelProps}>
        <div className={styles.IssueLicense}>
          <SpinContainer size={'large'} />
        </div>
      </SidePanel>
    }

    return (
      <SidePanel {...this.sidePanelProps}>
        <div className={styles.IssueLicense}>
          <FixedButtonsContainer>
            <Button onClick={onCancel}>{t("buttons.cancel")}</Button>
            <Button type={"primary"} onClick={this.onIssueClicked}>
              {t("buttons.issue")}
            </Button>
          </FixedButtonsContainer>
          <Form>
            <div className={styles.Content}>
              <div className={styles.ItemsContainer}>
                <div className={styles.BasicItem}>
                  <SelectInput
                    label={t('issue_license.license_type')}
                    items={this.licenseTypes.map(license => {
                      return {
                        id: license.type,
                        value: t(`license_types.${license.type}`)
                      }
                    })}
                    selectedItemId={this.licenseTypes[0].type}
                    onChangeItemId={this.onChangeLicenseType}
                  />
                </div>
              </div>
              <div className={styles.ItemsContainer}>
                <div className={styles.BasicItem}>
                  <Form.Item>
                      {getFieldDecorator("licenseCount", {
                        validateFirst: true,
                        initialValue: '',
                        validateTrigger: null,
                        rules: [{
                            required: true,
                            message: t("issue_license.license_count_required_error")
                          }, {
                            validator: (rule: any, value: any, cb: any) => {
                              if (value > 0) {
                                cb();
                                return;
                              }
                              cb(t("issue_license.min_license_count_error"));
                              return;
                            }
                          },
                          {
                            validator: (rule: any, value: any, cb: any) => {
                              const maxLicenseCount = this.props.configStore!.getConfig()!
                                .maxLicenseCount;
                              if (value <= maxLicenseCount) {
                                cb();
                                return;
                              }
                              cb(
                                t("issue_license.max_license_count_error", {
                                  count: maxLicenseCount
                                })
                              );
                              return;
                            }
                          }
                        ]
                      })(<InputNumber label={t('issue_license.license_count')} />)}
                  </Form.Item>
                </div>
                <div className={styles.ItemInRow}>
                  <Checkbox
                    checked={this.isSplitToGroupsChecked}
                    onChange={(data: any) =>
                      this.onSplitToGroupsChanged(data.target.checked)
                    }>{t("issue_license.split_to_groups_checked")}</Checkbox>
                </div>
              </div>
              {this.groups.map((group, _, arr) => {
                const { index, isUnlimitedChecked, dateFrom, dateTo, companyId } = group
                const datePickerFromProps = {
                  labels: datePickerLabels,
                  onApply: (date: Moment | null) => this.onDateFromApply(index, date),
                  disableDateFrom: dateTo !== null ? dateTo : undefined
                };
                const datePickerToProps: any = {
                  labels: datePickerLabels,
                  onApply: (date: Moment | null) => this.onDateToApply(index, date),
                  disableDateTo: dateFrom !== null ? dateFrom : undefined
                };
                return <div>
                  {this.isSplitToGroupsChecked &&
                    <div className={styles.GroupHeader}>
                      <div className={styles.Bold}>{t('issue_license.group_title', { name: index })}</div>
                      <div className={styles.DeleteGroup} onClick={() => this.onDeleteGroupClicked(index)}>
                        <span>{t('buttons.delete')}</span><Icon type={'delete'} />
                      </div>
                    </div>
                  }
                  {this.isSplitToGroupsChecked &&
                    <div className={styles.ItemsContainer}>
                      <div className={styles.FullSizeItem}>
                        <InputNumber
                          label={t('issue_license.license_count')}
                          onChange={(value: string) => this.onChangeGroupLicensesCount(index, value)}
                        />
                      </div>
                    </div>
                  }
                  <div className={styles.ItemsContainer}>
                    <div className={styles.FullSizeItem}>
                      <SelectCompany
                        selectedItemId={companyId}
                        onChangeItemId={(id: string) => this.onChangeSelectedCompanyId(index, id)}
                      />
                    </div>
                  </div>
                  <div className={styles.ItemsContainer}>
                    <div className={styles.FullSizeItem}>
                      <Input label={t('project')} onChange={(value: string) => this.onChangeProjectName(index, value)} />
                    </div>
                  </div>
                  <div className={styles.ItemsContainer}>
                    <div className={styles.FullSizeItem}>
                      <EnvSelect onChangeItemId={(id: string) => this.onSelectEnvType(index, id)} />
                    </div>
                  </div>
                  <div className={styles.ItemsContainer}>
                    <div className={styles.FullSizeItem} id={'issue-tags-container'}>
                      <SelectTags
                        getPopupContainer={() => document.getElementById('issue-tags-container')}
                        placeholder={t('tags_label', { count: maxTagCount })}
                        // label={t('tags_label', { count: maxTagCount })}
                        defaultValue={[]}
                        defaultOptions={this.companyTags}
                        maxTagCount={maxTagCount}
                        onChange={(tags: string[]) => this.onChangeTags(index, tags)}
                      />
                    </div>
                  </div>
                  <div className={styles.ItemsContainer}>
                    <div className={styles.Item}>
                      <div className={styles.Bold}>
                        {t("issue_license.expiration")}
                      </div>
                      <div>
                        <div className={styles.ItemInRow}>
                          <Form.Item>
                            {getFieldDecorator("dateFrom", {
                              validateFirst: true,
                              initialValue: null,
                              validateTrigger: "onBlur",
                              rules: []
                            })(
                              <DatePicker {...datePickerFromProps}>
                                {({ selectedDate, switchIsOpened }: any) => (
                                  <Button onClick={switchIsOpened}>
                                    {this.getPickerButtonText(selectedDate)}
                                  </Button>
                                )}
                              </DatePicker>
                            )}
                          </Form.Item>
                        </div>
                        {!isUnlimitedChecked && (
                          <div className={styles.ItemInRow}>—</div>
                        )}
                        {!isUnlimitedChecked && (
                          <div className={styles.ItemInRow}>
                            <Form.Item>
                              {getFieldDecorator("dateTo", {
                                validateFirst: true,
                                initialValue: null,
                                validateTrigger: "onBlur",
                                rules: []
                              })(
                                <DatePicker {...datePickerToProps}>
                                  {({ selectedDate, switchIsOpened }: any) => (
                                    <Button
                                      onClick={switchIsOpened}
                                      disabled={
                                        this.periodicDateToDefaultValue(index) !== undefined
                                      }
                                    >
                                      {this.getPickerButtonText(
                                        this.periodicDateToDefaultValue(index) !== undefined
                                          ? this.periodicDateToDefaultValue(index)
                                          : selectedDate
                                      )}
                                    </Button>
                                  )}
                                </DatePicker>
                              )}
                            </Form.Item>
                          </div>
                        )}
                        {!(this.licenseType === LicenseType.trial) && (
                          <div className={styles.ItemInRow}>
                            <Checkbox
                              checked={isUnlimitedChecked}
                              onChange={(e: any) =>
                                this.onExpirationCheckboxChanged(index, e.target.checked)
                              }
                            >
                              {t("issue_license.checkbox_unlimited")}
                            </Checkbox>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.ItemsContainer}>
                    <div className={styles.Item}>
                      <Form.Item>
                        <div className={styles.Bold}>
                          {t("issue_license.comment")}
                        </div>
                        <div className={styles.Comment}>
                          <Input.TextArea
                            className="v-input"
                            placeholder={t("issue_license.placeholder_comment")}
                            autosize={{ minRows: 4 }}
                            onChange={(e: any) => this.onChangeComment(index, e.target.value)}
                          />
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  {(this.isSplitToGroupsChecked && index === arr[arr.length - 1].index) &&
                    <div className={styles.ItemsContainer}>
                        <Button type={'primary'} onClick={this.addNewGroup}>
                          {t('issue_license.add_new_group')}
                        </Button>
                    </div>
                  }
                </div>
              })}
            </div>
          </Form>
        </div>
      </SidePanel>
    );
  }
}

export const LicenseIssue = Form.create()(withTranslation()(_LicenseIssue));
